import React from 'react';
// Dependencies
import Carousel from 'react-elastic-carousel';
import './about-carousel.component.styles.scss';

// Icons
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import ArrowForwardSharpIcon from '@material-ui/icons/ArrowForwardSharp';

// Components
import AboutCarouselImage from '../about-carousel-image/about-carousel-image.component';

class AboutCarousel extends React.Component {
  render() {
    return (
      <div>
        <div className='about-carousel-container'>
          <Carousel
            ref={(ref) => (this.carousel = ref)}
            itemsToShow={1}
            width={1}
          >
            <AboutCarouselImage title='goals' />
            <AboutCarouselImage title='summary' />
            <AboutCarouselImage title='nb1' />
            <AboutCarouselImage title='nb2' />
            <AboutCarouselImage title='nb3' />
          </Carousel>
        </div>
        <div className='carousel-partner-buttons-container marginBottom40'>
          <div
            onClick={() => this.carousel.slidePrev()}
            className='carousel-partner-arrow-container'
          >
            <ArrowBackSharpIcon fontSize='large' style={{ color: '#b0b0b0' }} />
          </div>
          <div
            onClick={() => this.carousel.slideNext()}
            className='carousel-partner-arrow-container'
          >
            <ArrowForwardSharpIcon
              style={{ color: '#b0b0b0' }}
              fontSize='large'
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AboutCarousel;
