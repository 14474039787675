// Dependencies
import { firestore } from './firebase';
import firebase from './firebase';
import uuid from 'react-uuid';

import { createDonationReceipt } from './pdf';

/**
 * ! Get receipt donations
 */

export const generateAllDonationsReceipts = async () => {
  const donations = await firestore.collection('donations').get();
  const donationsDocs = donations.docs;

  donationsDocs.forEach(async (donation) => {
    const donationNumber = donation.id;

    const {
      civility,
      lastname,
      firstname,
      address,
      paymentMethod,
      amount,
      donationCurrency,
      date,
    } = donation.data();
    const donationAmount = amount;

    if (donationNumber > 7 && donationNumber < 9) {
      await createDonationReceipt(
        donationNumber,
        civility,
        lastname,
        firstname,
        address,
        paymentMethod,
        donationAmount,
        donationCurrency,
        date
      );
    }

    // Create receipt with donationData
  });
};

// Generate Id
export const generateUid = () => {
  const id = uuid();
  return id;
};

/**
 * ! NAVBAR
 */
//  Navbar toggle function
export const toggleNavbar = () => {
  // get elements
  const sidebarMenu =
    document.getElementById('menu-section') ||
    document.querySelector('.header-admin-sidebar');

  // Get the spans from html
  const spans = document.querySelectorAll('span');

  // Toggle the classes for animation
  spans[0].classList.toggle('rotate-1');
  spans[1].classList.toggle('rotate-2');
  spans[2].classList.toggle('rotate-3');

  // Show the sidebar
  sidebarMenu.classList.toggle('show');
};
export const toggleNavOnClick = () => {
  const sidebarMenu = document.getElementById('menu-section');
  // Events Listeners
  sidebarMenu.addEventListener('click', (e) => {
    if (e.target.id === 'nav-sub-toggle') {
      return;
    } else {
      toggleNavbar();
    }
  });
};
let result;
// ! SEND EMAIL WITH SENDGRID
export const sendEmailOnNewDonation = async (form) => {
  const sendMail = firebase.functions().httpsCallable('sendEmailOnNewDonation');
  await sendMail(form).then((res) => {
    return (result = res.data);
  });

  return result;
};
export const sendEmailOnNewMember = async (form) => {
  const sendMail = firebase.functions().httpsCallable('sendEmailOnNewMember');
  await sendMail(form).then((res) => {
    return (result = res.data);
  });
  return result;
};
export const sendEmailOnNewPartner = async (form) => {
  const sendMail = firebase.functions().httpsCallable('sendEmailOnNewPartner');
  await sendMail(form).then((res) => {
    return (result = res.data);
  });
  return result;
};
// Send email contactForm
export const sendEmailWithSendgrid = async (form) => {
  let result;
  const sendMail = firebase.functions().httpsCallable('sendEmailContact');
  await sendMail(form).then((res) => {
    return (result = res.data);
  });
  return result;
};
/**
 * ! Firebase
 */

export const addTemporaryDonationToFirestore = async (
  form,
  token,
  isPartner = false
) => {
  const date = createNewDate();
  console.log(form);

  const {
    civility = '',
    address = '',
    paymentMethod = '',
    donationAmount,
    country = '',
    email = '',
    donationType = '',
    firstname = '',
    lastname = '',
    donationCurrency = 'XOF',
    phone = '',
    activity = '',
    logoUrl = '',
    presentation = '',
    typeOfCompany = '',
    websiteUrl = '',
  } = form;

  const temporaryDataRef = firestore
    .collection('temporaryDonationsData')
    .doc(token);

  const batch = firestore.batch();
  if (isPartner) {
    batch.set(temporaryDataRef, {
      amount: donationAmount,
      logoUrl,
      presentation,
      typeOfCompany,
      websiteUrl,
      civility,
      country,
      email,
      address,
      paymentMethod,
      firstname: firstname,
      lastname: lastname,
      donationCurrency: donationCurrency,
      date,
      donationType,
      phone,
      activity,
    });
  } else {
    batch.set(temporaryDataRef, {
      amount: donationAmount,
      civility,
      country,
      email,
      address,
      paymentMethod,
      firstname: firstname,
      lastname: lastname,
      donationCurrency: donationCurrency,
      date,
      donationType,
      phone,
      activity,
    });
  }
  await batch.commit();
};

export const getIsPaymentSuccess = async (token) => {
  let isPaymentSuccess;
  let isPaymentFailure;
  await firestore
    .collection('temporaryDonationsData')
    .doc(token)
    .get()
    .then((doc) => {
      if (doc.exists) {
        const { isSuccess, isFailure } = doc?.data();
        if (isSuccess) {
          return (isPaymentSuccess = {
            data: doc?.data(),
            isPaymentSuccess: true,
          });
        }
        if (isFailure) {
          return (isPaymentFailure = {
            data: doc?.data(),
            isPaymentFailure: true,
          });
        }
      }
    });
  return { isPaymentSuccess, isPaymentFailure };
};
export const deleteTemporaryDonationFromFirestore = async (token) => {
  await firestore.collection('temporaryDonationsData').doc(token).delete();
};

// Create array for graph data
export const arrayOfDaysForPartners = (
  startDate,
  endDate,
  partnersData,
  country = ''
) => {
  const lastDate = endDate ? new Date(endDate) : new Date();
  let days = [];
  const formatedDays = [];
  if (startDate) {
    for (
      let d = new Date(startDate);
      d <= lastDate;
      d.setDate(d.getDate() + 1)
    ) {
      const day = new Date(d).toLocaleDateString('en-GB');
      days.push(day);
    }
  } else {
    for (
      let d = new Date('01/01/2021');
      d <= lastDate;
      d.setDate(d.getDate() + 1)
    ) {
      const day = new Date(d).toLocaleDateString('en-GB');
      days.push(day);
    }
  }
  // days map
  days.map((day) => {
    partnersData.map((partner) => {
      if (partner.country && country) {
        if (partner.country.toLowerCase() === country.toLowerCase()) {
          if (partner.createdAt === day) {
            formatedDays.push({ date: day, partner: 1 });
          } else {
            formatedDays.push({ date: day });
          }
        }
      } else {
        if (partner.createdAt === day) {
          formatedDays.push({ date: day, partner: 1 });
        } else {
          formatedDays.push({ date: day });
        }
      }
    });
  });
  return formatedDays;
};

export const arrayOfDays = (
  donationsData,
  expensesData,
  startDate,
  endDate
) => {
  const lastDate = endDate ? new Date(endDate) : new Date();
  let days = [];
  const formatedDays = [];

  if (startDate) {
    for (
      let d = new Date(startDate);
      d <= lastDate;
      d.setDate(d.getDate() + 1)
    ) {
      const day = new Date(d).toLocaleDateString('en-GB');
      days.push(day);
    }
  } else {
    for (
      let d = new Date('01/01/2021');
      d <= lastDate;
      d.setDate(d.getDate() + 1)
    ) {
      const day = new Date(d).toLocaleDateString('en-GB');
      days.push(day);
    }
  }
  days.map((day) => {
    donationsData.map((donation) => {
      if (donation.date === day) {
        formatedDays.push({ date: day, donationAmount: donation.amount });
      } else {
        formatedDays.push({ date: day });
      }
    });
    expensesData.map((expense) => {
      if (expense.date === day) {
        formatedDays.push({ date: day, expenseAmount: expense.amount });
      } else {
        formatedDays.push({ date: day });
      }
    });
  });
  return formatedDays;
};
export const arrayOfDaysWithFilter = (
  donationsData,
  expensesData,
  startDate,
  endDate,
  country,
  email,
  donationType,
  activity,
  name,
  phone
) => {
  const lastDate = endDate ? new Date(endDate) : new Date();
  let days = [];
  const formatedDays = [];

  if (startDate) {
    for (
      let d = new Date(startDate);
      d <= lastDate;
      d.setDate(d.getDate() + 1)
    ) {
      const day = new Date(d).toLocaleDateString('en-GB');
      days.push(day);
    }
  } else {
    for (
      let d = new Date('01/01/2021');
      d <= lastDate;
      d.setDate(d.getDate() + 1)
    ) {
      const day = new Date(d).toLocaleDateString('en-GB');
      days.push(day);
    }
  }
  days.map((day) => {
    donationsData.map((donation) => {
      if (donation.country && country) {
        if (donation.country.toLowerCase().includes(country.toLowerCase())) {
          // HERE
          if (donation.date === day) {
            formatedDays.push({ date: day, donationAmount: donation.amount });
          } else {
            formatedDays.push({ date: day });
          }
        }
      } else if (email) {
        if (donation.email) {
          if (donation.email.toLowerCase().includes(email.toLowerCase())) {
            // HERE
            if (donation.date === day) {
              formatedDays.push({ date: day, donationAmount: donation.amount });
            } else {
              formatedDays.push({ date: day });
            }
          }
        }
      } else if (donationType) {
        if (donation.donationType) {
          if (
            donation.donationType
              .toLowerCase()
              .includes(donationType.toLowerCase())
          ) {
            // HERE
            if (donation.date === day) {
              formatedDays.push({ date: day, donationAmount: donation.amount });
            } else {
              formatedDays.push({ date: day });
            }
          }
        }
      } else if (name) {
        if (donation.lastname) {
          if (phone) {
            if (
              donation.lastname.toLowerCase().includes(name.toLowerCase()) &&
              donation.phone.toLowerCase().includes(phone.toLowerCase())
            ) {
              // HERE
              if (donation.date === day) {
                formatedDays.push({
                  date: day,
                  donationAmount: donation.amount,
                });
              } else {
                formatedDays.push({ date: day });
              }
            }
          } else {
            if (donation.lastname.toLowerCase().includes(name.toLowerCase())) {
              // HERE
              if (donation.date === day) {
                formatedDays.push({
                  date: day,
                  donationAmount: donation.amount,
                });
              } else {
                formatedDays.push({ date: day });
              }
            }
          }
        }
      } else if (phone) {
        if (donation.phone) {
          if (name) {
            if (
              donation.phone.toLowerCase().includes(phone.toLowerCase()) &&
              donation.name.toLowerCase().includes(name)
            ) {
              // HERE
              if (donation.date === day) {
                formatedDays.push({
                  date: day,
                  donationAmount: donation.amount,
                });
              } else {
                formatedDays.push({ date: day });
              }
            }
          } else {
            if (donation.phone.toLowerCase().includes(phone.toLowerCase())) {
              // HERE
              if (donation.date === day) {
                formatedDays.push({
                  date: day,
                  donationAmount: donation.amount,
                });
              } else {
                formatedDays.push({ date: day });
              }
            }
          }
        }
      } else if (activity) {
        if (donation.activity) {
          if (
            donation.activity.toLowerCase().includes(activity.toLowerCase())
          ) {
            // HERE
            if (donation.date === day) {
              formatedDays.push({ date: day, donationAmount: donation.amount });
            } else {
              formatedDays.push({ date: day });
            }
          }
        }
      } else {
        if (donation.date === day) {
          formatedDays.push({ date: day, donationAmount: donation.amount });
        } else {
          formatedDays.push({ date: day });
        }
      }
    });
    expensesData.map((expense) => {
      if (expense.date === day) {
        formatedDays.push({ date: day, expenseAmount: expense.amount });
      } else {
        formatedDays.push({ date: day });
      }
    });
  });
  return formatedDays;
};
// Create Partner profile in firestore
export const createPartnerProfile = async (form, logoUrl) => {
  const {
    lastname,
    civility,
    typeOfCompany,
    email,
    phone,
    address,
    country,
    presentation,
    websiteUrl,
    paymentMethod,
    amount,
    donationCurrency,
  } = form;

  // Format date
  function pad2(n) {
    return (n < 10 ? '0' : '') + n;
  }

  var date = new Date();
  var month = pad2(date.getMonth() + 1);
  var day = pad2(date.getDate());
  var year = date.getFullYear();
  var formattedDate = day + '/' + month + '/' + year;

  const partnersRef = firestore.collection('partners');
  const partnerRef = partnersRef.doc(email);

  const partnersShowableRef = firestore.collection('partners-showable');
  const partnerShowableRef = partnersShowableRef.doc(email);

  partnerShowableRef.set({
    lastname,
    email,
    logoUrl,
    websiteUrl,
  });

  partnerRef
    .set({
      lastname,
      civility,
      typeOfCompany,
      email,
      phone,
      address,
      country,
      presentation,
      logoUrl: logoUrl,
      websiteUrl,
      paymentMethod,
      donationAmount: amount,
      donationCurrency,
      createdAt: formattedDate,
    })
    .then(() => console.log('partner Added ! '));
};

export const storeDonationInFirestore = async (form, token = '') => {
  const dateObj = new Date();
  const monthPlusOne = dateObj.getMonth() + 1;
  const month = String(monthPlusOne).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();
  const date = day + '/' + month + '/' + year;

  const statsRef = firestore.collection('donations-stats').doc('--stats--');
  let lastDonationNum = await (await statsRef.get()).data().donationCount;
  lastDonationNum += 1;

  const {
    civility = '',
    address = '',
    paymentMethod = '',
    amount,
    country = '',
    email = '',
    donationType = '',
    firstname = '',
    lastname = '',
    donationCurrency = 'XOF',
    phone = '',
    activity = '',
  } = form;

  const donationRef = firestore
    .collection('donations')
    .doc(`${lastDonationNum}`);
  const increment = firebase.firestore.FieldValue.increment(1);

  const batch = firestore.batch();

  batch.set(donationRef, {
    amount,
    civility,
    country,
    email,
    address,
    paymentMethod,
    firstname: firstname,
    lastname: lastname,
    donationCurrency: donationCurrency,
    date,
    donationType,
    phone,
    activity,
    token,
  });

  batch.set(statsRef, { donationCount: increment }, { merge: true });
  await batch.commit();

  // Create and save PDF
  createDonationReceipt(
    lastDonationNum,
    civility,
    form.lastname,
    form.firstname,
    address,
    paymentMethod,
    amount,
    donationCurrency,
    date
  );

  // ! DEV TO UNCOM
  const sendMailToClient = firebase
    .functions()
    .httpsCallable('sendEmailToClientOnNewDonation');
  const formWithDonationNumber = {
    ...form,
    donationNumber: lastDonationNum,
    donationAmount: amount,
  };
  await sendMailToClient(formWithDonationNumber).then((res) => {
    return (result = res.data);
  });
};
export const formateDateFromInputToWithName = (dateFromInput) => {
  const monthNames = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ];
  const dateObj = new Date(dateFromInput);
  const month = monthNames[dateObj.getMonth()];
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();
  const date = day + ' ' + month + ' ' + year;
  return date;
};
export const searchAmountOfDonationByActivity = async (activityToSearch) => {
  let amount = 0;
  const searches = await firestore.collection('donations').get();
  const results = searches.docs;
  results.map((doc) => {
    const { activity } = doc.data();
    if (activity) {
      if (activityToSearch.toLowerCase() === activity.toLowerCase()) {
        amount = amount + 1;
      }
    }
  });
  return amount;
};

// Create Member profile in firestore
export const createMemberProfile = async (form) => {
  const {
    activity,
    address,
    civility,
    country,
    dateOfBirth,
    email,
    memberType,
    firstname,
    lastname,
    placeOfBirth,
    presentation,
    phone,
  } = form;

  // Check if user is already member is specific database
  const membersShowableRef = firestore.collection('members-showable');
  const memberShowableRef = membersShowableRef.doc(email);
  const memberShowableDocs = await memberShowableRef.get();
  if (memberShowableDocs.exists) return 'Partner already exists';

  memberShowableRef.set({
    email,
  });

  const membersRef = firestore.collection('members');
  const memberRef = membersRef.doc(email);
  // Format date
  function pad2(n) {
    return (n < 10 ? '0' : '') + n;
  }

  var date = new Date();
  var month = pad2(date.getMonth() + 1);
  var day = pad2(date.getDate());
  var year = date.getFullYear();
  var formattedDate = day + '/' + month + '/' + year;

  await memberRef.set({
    activity,
    address,
    civility,
    country,
    dateOfBirth,
    email,
    memberType,
    firstname,
    lastname,
    placeOfBirth,
    presentation,
    phone,
    createdAt: formattedDate,
  });
};

// convertCollectionsSnapshotToMap
export const convertCollectionsSnapshotToMap = (collection) => {
  const transformedCollection = collection.docs.map((doc) => {
    const { lastname, logoUrl, websiteUrl, email } = doc.data();

    return {
      id: email,
      name: lastname,
      logoUrl,
      websiteUrl,
    };
  });
  return transformedCollection;
};

// Add Email to newsletter
export const addEmailToNewsletter = (email) => {
  try {
    firestore.collection('newsletter').doc(email).set({ email });
  } catch (err) {
    throw new Error(err);
  }
};

// ! Data formatting
export const formatDateFromInt = (date) => {
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const day = date.slice(6, 8);
  const formatedDate = `${day}/${month}/${year}`;
  return formatedDate;
};
export const formatDateFromSlashesToInt = (date) => {
  // DD/MM/YYYY
  const day = date.slice(0, 2);
  const month = date.slice(3, 5);
  const year = date.slice(6, 10);
  const formattedDate = parseInt(`${year}${month}${day}`);
  return formattedDate;
};
export const formatDateFromInput = (date) => {
  // YYYY-MM-DD
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 12);
  const formatedDate = `${day}/${month}/${year}`;
  return formatedDate;
};
export const formatDateWithMonthName = (date) => {
  // Date format = YYYYMMDD
  const day = date.slice(6, 8);
  const month = date.slice(4, 6);
  const year = date.slice(0, 4);
  const formatedMonth = monthNames[parseInt(month) - 1];
  const formatedDate = day + ' ' + formatedMonth + ' ' + year;
  return formatedDate;
};
export const formatDateFromTimestamp = () => {};

const monthNames = [
  'janvier',
  'février',
  'mars',
  'avril',
  'mai',
  'juin',
  'juillet',
  'août',
  'septembre',
  'octobre',
  'novembre',
  'décembre',
];
export const formateDateFromTimestamp = (timestamp) => {
  const originalTime = new Date(timestamp.seconds * 1000);
  const month = monthNames[originalTime.getMonth()];
  const day = String(originalTime.getDate()).padStart(2, '0');
  const year = originalTime.getFullYear();
  const date = day + ' ' + month + ' ' + year;
  return date;
};
// Date
export const createNewDate = () => {
  function pad2(n) {
    return (n < 10 ? '0' : '') + n;
  }

  var date = new Date();
  var month = pad2(date.getMonth() + 1);
  var day = pad2(date.getDate());
  var year = date.getFullYear();
  var formattedDate = day + '/' + month + '/' + year;
  return formattedDate;
};

/**
 * ! Translations
 */

export const translateTitle = (title, language = 'french') => {
  let translatedTitle = '';

  if (language === 'french') {
    switch (title) {
      case 'membre bienfaiteur':
        return (translatedTitle = 'membre bienfaiteur de A.I.D.S.E.A.');
      case "membre d'honneur":
        return (translatedTitle = "membre d'honneur de A.I.D.S.E.A.");
      case 'bénévole':
        return (translatedTitle = 'bénévole de A.I.D.S.E.A.');
      case 'membre':
        return (translatedTitle = 'membre de A.I.D.S.E.A.');
      default:
        return '';
    }
  }
  if (language === 'english') {
    switch (title) {
      case 'membre bienfaiteur':
        return (translatedTitle = 'A.I.D.S.E.A. benefactor member');
      case "membre d'honneur":
        return (translatedTitle = 'A.I.D.S.E.A. honorary member');
      case 'bénévole':
        return (translatedTitle = 'A.I.D.S.E.A. volunteer');
      case 'membre':
        return (translatedTitle = 'A.I.D.S.E.A. member');
      default:
        return '';
    }
  }
  return translatedTitle;
};

/**
 * ! Others
 */
export const countryList = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  "Côte d'Ivoire",
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
];
