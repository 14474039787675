import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import MaterialTable from 'material-table';

import {
  deleteDonationStartAsync,
  deleteExpenseStartAsync,
} from '../../redux/admin-data/admin-data.actions';
import { formatDateFromSlashesToInt } from '../../js/utils';

const MaterialTable1 = ({
  title,
  data,
  deleteDonation,
  deleteExpense,
  typeOfTable,
}) => {
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    if (title === 'Entrées') {
      setColumns([
        {
          title: 'Date',
          field: 'date',
          customSort: (a, b) => {
            const newDateA = formatDateFromSlashesToInt(a.date);
            const newDateB = formatDateFromSlashesToInt(b.date);
            return newDateA - newDateB;
          },
        },
        { title: 'Noms', field: 'lastname' },
        {
          title: 'Montant',
          field: 'amount',
          type: 'numeric',
        },
        {
          title: 'Type de don',
          field: 'donationType',
        },
        {
          title: 'Méthode de paiement',
          field: 'paymentMethod',
        },
        {
          title: 'Pays',
          field: 'country',
        },
      ]);
    } else if (title === 'Sorties') {
      setColumns([
        {
          title: 'Date',
          field: 'date',
          customSort: (a, b) => {
            const newDateA = formatDateFromSlashesToInt(a.date);
            const newDateB = formatDateFromSlashesToInt(b.date);
            return newDateA - newDateB;
          },
        },
        { title: 'Noms', field: 'lastname' },
        {
          title: 'Montant',
          field: 'amount',
          type: 'numeric',
        },
        {
          title: 'Type de dépense',
          field: 'typeOfExpense',
        },
      ]);
    } else if (title === 'Membres') {
      setColumns([
        {
          title: 'Date',
          field: 'createdAt',
          customSort: (a, b) => {
            const newDateA = formatDateFromSlashesToInt(a.createdAt);
            const newDateB = formatDateFromSlashesToInt(b.createdAt);
            return newDateA - newDateB;
          },
        },
        { title: 'Type de membre', field: 'memberType' },
        {
          title: 'Prénoms',
          field: 'firstname',
        },
        {
          title: 'Noms',
          field: 'lastname',
        },

        {
          title: 'Email',
          field: 'email',
        },
        {
          title: 'Téléphone',
          field: 'phone',
        },
        {
          title: 'Adresse',
          field: 'address',
        },
        {
          title: 'Pays',
          field: 'country',
        },
        {
          title: 'Activité',
          field: 'activity',
        },
        {
          title: 'Présentation',
          field: 'presentation',
        },
      ]);
    } else if (title === 'Partenaires') {
      setColumns([
        {
          title: 'Date',
          field: 'createdAt',
          customSort: (a, b) => {
            const newDateA = formatDateFromSlashesToInt(a.createdAt);
            const newDateB = formatDateFromSlashesToInt(b.createdAt);
            return newDateA - newDateB;
          },
        },
        {
          title: 'Noms',
          field: 'lastname',
        },
        {
          title: 'Email',
          field: 'email',
        },
        {
          title: 'Téléphone',
          field: 'phone',
        },
        {
          title: 'Adresse',
          field: 'address',
        },
        {
          title: 'Pays',
          field: 'country',
        },
        {
          title: 'Présentation',
          field: 'presentation',
        },
        {
          title: "Type d'entreprise",
          field: 'typeOfCompany',
        },
        {
          title: 'Site web',
          field: 'websiteUrl',
        },
      ]);
    } else if (title === 'Dons') {
      setColumns([
        {
          title: 'Date',
          field: 'date',
          customSort: (a, b) => {
            const newDateA = formatDateFromSlashesToInt(a.date);
            const newDateB = formatDateFromSlashesToInt(b.date);
            return newDateA - newDateB;
          },
        },
        { title: 'Noms', field: 'lastname' },
        { title: 'Prénoms', field: 'firstname' },
        { title: 'Email', field: 'email' },
        { title: 'Profession', field: 'activity' },
        {
          title: 'Montant',
          field: 'amount',
          type: 'numeric',
        },
        {
          title: 'Type de don',
          field: 'donationType',
        },
        {
          title: 'Méthode de paiement',
          field: 'paymentMethod',
        },
        {
          title: 'Pays',
          field: 'country',
        },
      ]);
    } else if (title === 'Solde') {
      setColumns([
        {
          title: 'Date',
          field: 'date',
          customSort: (a, b) => {
            const newDateA = formatDateFromSlashesToInt(a.date);
            const newDateB = formatDateFromSlashesToInt(b.date);
            return newDateA - newDateB;
          },
        },
        { title: 'Noms', field: 'lastname' },
        {
          title: 'Montant',
          field: 'amount',
          type: 'numeric',
        },
        {
          title: 'Type de don / dépense',
          field: 'donationType',
        },
      ]);
    }
  }, [title]);

  return (
    <MaterialTable
      title={title}
      columns={columns}
      data={data}
      options={{
        headerStyle: {
          fontWeight: 'bold',
          fontSize: 16,
        },
        rowStyle: {
          fontSize: 16,
        },
      }}
      editable={{
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              if (typeOfTable === 'donations') {
                deleteDonation(dataDelete[index].id);
              } else if (typeOfTable === 'expenses') {
                deleteExpense(dataDelete[index].id);
              }

              resolve();
            }, 1000);
          }),
      }}
    />
  );
};
const mapDispatchToProps = dispatch => ({
  deleteDonation: id => dispatch(deleteDonationStartAsync(id)),
  deleteExpense: id => dispatch(deleteExpenseStartAsync(id)),
});
export default connect(null, mapDispatchToProps)(MaterialTable1);
