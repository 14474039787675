import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectCardTextText,
  selectCardTitle,
} from '../../redux/card-text/card-text.selectors';
import { setCardIsVisible } from '../../redux/card-text/card-text.actions';
import './card-text.component.styles.scss';

const CardWithText = ({ title, text, setCardIsVisible }) => {
  return (
    <div className='card-text-container'>
      <div className='card-text-content'>
        <span
          className='card-text-close cursorPointer'
          onClick={() => setCardIsVisible(false)}
        >
          &#x2715;
        </span>
        <h3 className='small-title-green'>{title}</h3>
        <p className='card-text-text'>{text}</p>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  title: selectCardTitle,
  text: selectCardTextText,
});
const mapDispatchToProps = (dispatch) => ({
  setCardIsVisible: (isVisible) => dispatch(setCardIsVisible(isVisible)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CardWithText);
