import React from 'react';

import './admin-dashboard-small-card.component.styles.scss';

const AdminDashboardSmallCard = ({ isPartners, title, number }) => {
  return (
    <div className='admin-dashboard-small-card'>
      <p
        className={`admin-dashboard-small-card-number ${
          isPartners ? 'color-blue' : 'color-green'
        }`}
      >
        {number}
      </p>
      <p className='admin-dashboard-small-card-title'>{title}</p>
    </div>
  );
};

export default AdminDashboardSmallCard;
