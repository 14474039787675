import React from 'react';

// Dependencies
import Carousel from 'react-elastic-carousel';
import './carousel-partner.component.styles.scss';

// Partners Logos
import logo from '../../assets/logo.png';

// Icons
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import ArrowForwardSharpIcon from '@material-ui/icons/ArrowForwardSharp';

class CarouselPartner extends React.Component {
  constructor(props) {
    super(props);
    this.breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 3, itemsToScroll: 1 },
      { width: 850, itemsToShow: 3 },
    ];
  }

  render() {
    const partnersArrayForCarousel = [];
    for (let i = 0; i < 10; i++) {
      this.props.partners.map((partner) => {
        return partnersArrayForCarousel.push(partner);
      });
    }
    return (
      <div className='carousel-partner-container'>
        <p className='carousel-partner-title'>{this.props.title}</p>
        <h3 className='carousel-partner-title-2'>
          {this.props.description1}{' '}
          <span className='carousel-title-2-span'>
            {this.props.description2}
          </span>{' '}
          {this.props.description3}
        </h3>
        <div className='carousel-partner-carousel'>
          <Carousel
            breakPoints={this.breakPoints}
            ref={(ref) => (this.carousel = ref)}
            itemsToShow={3}
            enableAutoPlay={true}
            autoPlaySpeed={2000}
            onNextEnd={({ index }) => {
              if (index + 1 === partnersArrayForCarousel.length) {
                setTimeout(() => {
                  this.carousel.goTo(0);
                }, 1500);
              }
            }}
          >
            {partnersArrayForCarousel.map((partner, idx) => {
              const { id, name, logoUrl, websiteUrl = '' } = partner;
              return (
                <a
                  className='partner-logo'
                  key={idx}
                  href={`https://${websiteUrl}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={logoUrl} width='130px' alt={`Logo ${name}`} />
                  <p className='partner-name'>{name}</p>
                </a>
              );
            })}
          </Carousel>
          <div className='carousel-partner-buttons-container'>
            <div
              onClick={() => this.carousel.slidePrev()}
              className='carousel-partner-arrow-container'
            >
              <ArrowBackSharpIcon
                fontSize='large'
                style={{ color: '#b0b0b0' }}
              />
            </div>
            <div
              onClick={() => this.carousel.slideNext()}
              className='carousel-partner-arrow-container'
            >
              <ArrowForwardSharpIcon
                style={{ color: '#b0b0b0' }}
                fontSize='large'
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CarouselPartner;
