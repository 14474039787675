import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';
import { selectIsLoaderVisible } from '../../redux/loader/loader.selectors';

import './footer.component.styles.scss';

// Icons
import logo from '../../assets/logo-big.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import facebookIcon from '../../assets/icons/facebook.png';
import instagramIcon from '../../assets/icons/instagram.png';
import twitterIcon from '../../assets/icons/twitter.png';
import linkedinIcon from '../../assets/icons/linkedin.png';
import reglementInterieurPDF from '../../assets/official-docs/reglement_interieur.pdf';
import statutsPDF from '../../assets/official-docs/statuts.pdf';

// Newsletter Function
import { addEmailToNewsletter } from '../../js/utils';
import {
  setIsLoaderVisible,
  setIsLoaderLoading,
  setTextInsideLoader,
  setIsLoaderSuccess,
} from '../../redux/loader/loader.actions';

// Components
import StateCard from '../state-card/state-card.component';

import { FR, EN } from '../../js/languagesData';

const Footer = ({
  setIsLoaderVisible,
  setIsLoaderLoading,
  setTextInsideLoader,
  setIsLoaderSuccess,
  isLoaderVisible,
  currentLanguage,
}) => {
  const languageData = currentLanguage === 'english' ? EN : FR;
  const [email, setEmail] = useState('');

  const handleSubmitNewsletter = async (e) => {
    e.preventDefault();
    if (email) {
      setIsLoaderVisible(true);
      addEmailToNewsletter(email);
      setIsLoaderLoading(false);
      setIsLoaderSuccess(true);
      setTextInsideLoader(
        'Votre inscription a bien été prise en compte, surveillez votre boite mail pour suivre nos actions sur internet !'
      );
      setEmail('');
    }
  };

  return (
    <div className='footer'>
      {isLoaderVisible && <StateCard />}
      <div className='footer-logo-newsletter-container'>
        <div className='footer-logo-container'>
          <img src={logo} alt='AIDSEA Logo' />
          <p>{languageData.footer.logoSentence}</p>
        </div>
        <div className='footer-newsletter-container'>
          <h4>{languageData.footer.newsletter}</h4>
          <p>{languageData.footer.joinNewsletter}</p>
          <form onSubmit={handleSubmitNewsletter}>
            <input
              className='newsletter-input'
              placeholder='Email'
              type='email'
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className='newsletter-button' type='submit'>
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </form>
        </div>
      </div>
      <div className='footer-links-socials-container'>
        <div className='footer-links-container'>
          <h4>{languageData.footer.association}</h4>
          <ul className='footer-links-ul'>
            <li>
              <Link to='/contact'>{languageData.footer.contactUs}</Link>
            </li>
            <li>
              <a href={reglementInterieurPDF} target='_blank' rel='noreferrer'>
                {languageData.footer.rulesOfProcedure}
              </a>
            </li>
            <li>
              <a
                href={statutsPDF}
                target='_blank'
                rel='noreferrer'
                alt='Status'
              >
                {languageData.footer.statutes}
              </a>
            </li>
          </ul>
        </div>
        <div className='footer-socials-container'>
          <h4>{languageData.footer.social}</h4>
          <ul className='footer-socials-ul'>
            <li>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.facebook.com/Aidsea-Association-116495990192054'
              >
                <img
                  className='footer-socials-image'
                  src={facebookIcon}
                  alt='facebook'
                />
              </a>
            </li>
            <li>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.instagram.com/aidsea_association/?hl=fr'
              >
                <img
                  className='footer-socials-image'
                  src={instagramIcon}
                  alt='instagram'
                />
              </a>
            </li>
            <li>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.linkedin.com/company/aidsea-association/?viewAsMember=true'
              >
                <img
                  className='footer-socials-image'
                  src={linkedinIcon}
                  alt='linkedin'
                />
              </a>
            </li>
            <li>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://twitter.com/AAidsea'
              >
                <img
                  className='footer-socials-image'
                  src={twitterIcon}
                  alt='twitter'
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  isLoaderVisible: selectIsLoaderVisible,
  currentLanguage: selectCurrentLanguage,
});
const mapDispatchToProps = (dispatch) => ({
  setIsLoaderVisible: (isVisible) => dispatch(setIsLoaderVisible(isVisible)),
  setIsLoaderLoading: (isLoading) => dispatch(setIsLoaderLoading(isLoading)),
  setTextInsideLoader: (text) => dispatch(setTextInsideLoader(text)),
  setIsLoaderSuccess: (isLoaderSuccess) =>
    dispatch(setIsLoaderSuccess(isLoaderSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
