import React from 'react';
import './buttons.component.styles.scss';

// Components
import ArrowForwardSharpIcon from '@material-ui/icons/ArrowForwardSharp';

export const GreenButton1 = ({ title }) => {
  return (
    <button className='green-button-1'>
      {title}
      <span>
        <ArrowForwardSharpIcon style={{ color: '#FFF' }} fontSize='large' />
      </span>
    </button>
  );
};
