import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';
import { FR, EN } from '../../js/languagesData';

import './official-documents-card.component.styles.scss';

const OfficialDocumentsCardContainer = ({
  title,
  description,
  description2,
  img,
  link,
  currentLanguage,
}) => {
  const languageData = currentLanguage === 'english' ? EN : FR;
  return (
    <div className='official-documents-card'>
      <div className='official-documents-card-icon-container'>
        <img alt='icon' src={img} />
      </div>
      <div className='official-documents-card-text-container'>
        <h4>{title}</h4>
        <p>{description}</p>
        <p>{description2}</p>
      </div>
      <div className='official-documents-card-button-container'>
        <a className='fontSize20' href={link} target='_blank' rel='noreferrer'>
          {languageData.officialDocuments.buttonTitle} <span>&rarr;</span>
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentLanguage: selectCurrentLanguage,
});

export default connect(mapStateToProps)(OfficialDocumentsCardContainer);
