import AdminDataActionTypes from './admin-data.types';

const INITIAL_STATE = {
  donations: [],
  expenses: [],
  partners: [],
  members: [],
  admins: [],
  isUserUpdateVisible: false,
  userUpdateProfil: {},
  media: [],
  articleText: null
};

const AdminDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AdminDataActionTypes.FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case AdminDataActionTypes.FETCH_DONATIONS_SUCCESS:
      return {
        ...state,
        donations: action.payload,
      };
    case AdminDataActionTypes.FETCH_EXPENSES_SUCCESS:
      return {
        ...state,
        expenses: action.payload,
      };
    case AdminDataActionTypes.FETCH_PARTNERS_SUCCESS:
      return {
        ...state,
        partners: action.payload,
      };
    case AdminDataActionTypes.FETCH_MEMBERS_SUCCESS:
      return {
        ...state,
        members: action.payload,
      };
    case AdminDataActionTypes.FETCH_ADMIN_SUCCESS:
      return {
        ...state,
        admins: action.payload,
      };
    case AdminDataActionTypes.SET_USER_UPDATE_IS_VISIBLE:
      return {
        ...state,
        isUserUpdateVisible: action.payload,
      };
    case AdminDataActionTypes.SET_USER_UPDATE_PROFIL:
      return {
        ...state,
        userUpdateProfil: action.payload,
      };
    case AdminDataActionTypes.FETCH_MEDIA_SUCCESS:
      return {
        ...state,
        media: action.payload,
      };
    case AdminDataActionTypes.SET_MEDIA_TEXT:
      return {
        ...state,
        articleText: action.payload.text,
        id: action.payload.id
      };
    default:
      return state;
  }
};

export default AdminDataReducer;
