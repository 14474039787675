import React, { useState } from 'react';

import './contact-form.component.styles.scss';

// Components & dependencies
import Form from 'react-bootstrap/Form';
import { GreenButton1 } from '../buttons/buttons.component';
import Feedback from 'react-bootstrap/esm/Feedback';

import { sendEmailWithSendgrid } from '../../js/utils';

// Redux
import {
  setIsLoaderVisible,
  setIsLoaderLoading,
  setTextInsideLoader,
  setIsLoaderSuccess,
} from '../../redux/loader/loader.actions';
import { connect } from 'react-redux';

const ContactForm = ({
  setIsLoaderVisible,
  setIsLoaderLoading,
  setTextInsideLoader,
  setIsLoaderSuccess,
  languageData,
}) => {
  const [validated, setValidated] = useState(false);
  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleSubmit = async (e) => {
    const formJsx = e.currentTarget;
    e.preventDefault();
    setValidated(true);
    if (formJsx.checkValidity() === false) return e.stopPropagation();
    setIsLoaderVisible(true);
    const sendEmailRes = await sendEmailWithSendgrid(form);
    if (sendEmailRes.success) {
      setIsLoaderSuccess(true);
      setIsLoaderLoading(false);
      setTextInsideLoader(
        'Votre demande à bien été envoyée, nous vous reviendrons vers vous rapidement'
      );
    }
  };
  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setForm({ ...form, [name]: value });
  };

  return (
    <Form
      method='POST'
      validated={validated}
      noValidate
      className='bootstrap-form'
      onSubmit={handleSubmit}
    >
      <div className='contact-form-row-container'>
        <Form.Group>
          <Form.Label>{languageData.contactUs.form.lastname}</Form.Label>
          <Form.Control
            type='text'
            name='lastname'
            defaultValue={form.lastname}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{languageData.contactUs.form.firstname}</Form.Label>
          <Form.Control
            type='text'
            name='firstname'
            defaultValue={form.firstname}
            onChange={handleChange}
            required
          />
          <Feedback type='invalid'>Veuillez entrer votre prénom</Feedback>
        </Form.Group>
      </div>
      <div className='contact-form-row-container'>
        <Form.Group>
          <Form.Label>{languageData.contactUs.form.email}</Form.Label>
          <Form.Control
            type='email'
            name='email'
            defaultValue={form.email}
            onChange={handleChange}
            required
          />
          <Feedback type='invalid'>Veuillez entrer votre email</Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>{languageData.contactUs.form.phone}</Form.Label>
          <Form.Control
            type='tel'
            name='phone'
            defaultValue={form.phone}
            onChange={handleChange}
          />
        </Form.Group>
      </div>
      <div className='basic-container-90 margin0Center'>
        <Form.Group style={{ width: '90%' }}>
          <Form.Label>{languageData.contactUs.form.message}</Form.Label>
          <Form.Control
            as='textarea'
            rows={5}
            name='message'
            defaultValue={form.message}
            onChange={handleChange}
            required
          />

          <Feedback type='invalid'>Veuillez entrer votre message</Feedback>
        </Form.Group>
        <GreenButton1 title={languageData.contactUs.form.send} />
      </div>
    </Form>
  );
};
const mapDispatchToProps = (dispatch) => ({
  setIsLoaderVisible: (isVisible) => dispatch(setIsLoaderVisible(isVisible)),
  setIsLoaderLoading: (isLoading) => dispatch(setIsLoaderLoading(isLoading)),
  setTextInsideLoader: (text) => dispatch(setTextInsideLoader(text)),
  setIsLoaderSuccess: (isLoaderSuccess) =>
    dispatch(setIsLoaderSuccess(isLoaderSuccess)),
});

export default connect(null, mapDispatchToProps)(ContactForm);
