import React from 'react';
import { connect } from 'react-redux';
import { FR, EN } from '../../js/languagesData';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';
import { createStructuredSelector } from 'reselect';

import './about-carousel-text.component.styles.scss';

const AboutCarouselText = ({ title, currentLanguage }) => {
  const languageData = currentLanguage === 'english' ? EN : FR;
  const {
    aboutPage: { carousel },
  } = languageData;

  let sectionTitle;
  switch (title) {
    case 'goals':
      sectionTitle = carousel.title1;
      break;
    case 'summary':
      sectionTitle = carousel.title2;
      break;
    case 'nb1':
      sectionTitle = carousel.title3;
      break;
    case 'nb2':
      sectionTitle = carousel.title4;
      break;
    case 'nb3':
      sectionTitle = carousel.title5;
      break;
    default:
  }

  const { summary } = carousel;
  return (
    <div className='about-carousel-text-container'>
      <div className='about-carousel-text-title'>
        <h3>{sectionTitle}</h3>
      </div>
      <div className='about-carousel-text-ul'>
        {title === 'goals' && (
          <div>
            <h3>{carousel.subTitle1}</h3>
            <p>{carousel.text1}</p>
          </div>
        )}
        {title === 'summary' && (
          <>
            <p>{summary.intro}</p>
            <ul className='align-justify'>
              <li>{summary.t1}</li>
              <li>{summary.t2}</li>
              <li>{summary.t3}</li>
              <li>{summary.t4}</li>
              <li>{summary.t5}</li>
              <li>{summary.t6}</li>
            </ul>
          </>
        )}
        {title === 'nb1' && <p>{carousel.text3}</p>}
        {title === 'nb2' && <p>{carousel.text4}</p>}
        {title === 'nb3' && <p>{carousel.text5}</p>}
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  currentLanguage: selectCurrentLanguage,
});
export default connect(mapStateToProps)(AboutCarouselText);
