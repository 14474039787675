export const FR = {
  header: {
    homepage: 'Accueil',
    about: 'A propos',
    officialDocuments: 'Documents Officiels',
    becomePartner: 'Devenir Partenaire',
    news: 'Actualité',
    join: 'Adhérer',
    joinMember: 'Devenir membre',
    joinHonoraryMember: "Devenir membre d'honneur",
    joinBenefactor: 'Devenir membre bienfaiteur',
    joinVolunteer: 'Devenir bénévole',
    donate: 'Faire un don',
  },
  footer: {
    association: "L'association",
    contactUs: 'Nous contacter',
    rulesOfProcedure: 'Règlement intérieur',
    statutes: 'Voir les statuts',
    social: 'Réseaux sociaux',
    logoSentence: 'Nous pensons Afrik & Haiti',
    newsletter: 'Newsletter',
    joinNewsletter:
      'Inscrivez-vous à notre newsletter et suivez nos actions sur internet',
  },
  heroCarousel: {
    phoneTitle1: 'NOTRE GENERATION MERITE LE BONHEUR.',
    title1: "INDUSTRIALISONS L'AFRIQUE.",
    description1:
      'Car cette nouvelle génération mérite le bien-être social & économique.',
    title2: 'NOUS AVONS DES CAPACITES CREATRICES, VALORISONS-LES.',
    description2:
      'Car cette nouvelle génération mérite le bien-être social & économique.',
    title3: 'RENDONS NOTRE MERE PATRIE HEUREUSE.',
    description3:
      'Car cette nouvelle génération mérite le bien-être social & économique.',
  },
  homepage: {
    ourThought: 'Notre pensée',
    mainText: {
      first:
        'L’Association pour l’Industrialisation et le Développement Social et Economique de l’Afrique : A.I.D.S.E.A. a le plaisir de mettre en ligne ce site qui, nous l’espérons, apportera davantage d’informations et d’interaction avec elle.',
      second:
        "Notre association se voudrait active grâce à ses adhérents et à ceux qui parmi ses membres et bénévoles, décideront de lui consacrer du temps et de s’y investir pleinement pour la faire grandir et offrir ainsi du sens à ses objectifs et ambitions. Nous serons donc ravis d’accueillir dans nos équipes tous ceux qui le désirent, sachant que la force d’une association ce sont son équipe, ses valeurs et le sens qu'elle décide d’accorder à ses actions.",
      third:
        'La naissance de notre association traduit donc la convergence et la réunion de différentes attentes et ambitions individuelles isolées en vue de construire une Afrique industrialisée porteuse d’espoir et où il fait bon vivre. En résumé, A.I.D.S.E.A. est une traduction de l’ambition de ses fondateurs pour un projet et un modèle favorisant la défense des intérêts de l’Afrique en partageant et en attirant l’adhésion du plus grand nombre d’intéressés, les afro-descendants en premier.',
      fourth:
        'Il s’agit de ce fait de la reconnaissance, non seulement d’une mission essentielle mais aussi d’une passion au cœur des problématiques de développement de l’Afrique et son épanouissement économique et social, du bonheur et de l’estime de soi des afro-descendants. En d’autres termes, il s’agit donc de confirmer la volonté de valoriser les richesses et la capacité créatrice de l’Afrique.',
      fifth:
        'Pour finir, l’Association A.I.D.S.E.A. se veut comme l’ambassadrice de la promotion du processus industriel en Afrique. Elle remplira son objectif, satisfera son Ambition grâce à l’implication étroite de tous ses membres adhérents. A nous, donc, de l’investir, de nous y investir, d’en prendre possession, de l’animer, d’être force de propositions pour accompagner son développement, son efficacité et son succès, c’est-à-dire aboutir à l’industrialisation et au développement social et économique de l’Afrique et l’Haïti mais et surtout aussi de faire la fierté de tous les afro-descendants partout dans le monde.',
    },
    presidentName: 'Prince-Henry DJOKOURY',
    presidentOf: 'Président de A.I.D.S.E.A.',
  },
  aboutPage: {
    title: 'Qui sommes-nous ?',
    subTitle1: 'A.I.D.S.E.A.',
    description10: 'ASSOCIATION POUR L’INDUSTRIALISATION ET ',
    description11: 'LE DEVELOPPEMENT SOCIAL ET ECONOMIQUE DE L’AFRIQUE',
    description12: 'LE DEVELOPPEMENT SOCIAL',
    description13: 'ET ECONOMIQUE DE L’AFRIQUE',
    description2:
      'Est une association de droit français soumise à la loi du 1er juillet 1901 et au décret du 16 août 1901.',
    marqueeTitle: 'UNIS, NOUS POUVONS VERDIR NOTRE DESERT INDUSTRIEL !',
    carousel: {
      title1: 'Objectifs',
      subTitle1: 'L’objet de l’association est le suivant :',
      text1:
        'A.I.D.S.E.A. est une association française (loi 1901) de solidarité internationale. Elle est dédiée à l’industrialisation de l’AFRIQUE (Mère Patrie) et d’HAÏTI que nous considérons comme un pays faisant partie de l’Afrique malgré la discontinuité territoriale. De façon explicite, elle a pour objet et vocation de promouvoir l’industrie en Afrique (HAÏTI comprise) et contribuer à l’industrialisation de ce continent et à son essor économique et social, en mobilisant des capitaux financiers par des levers populaires de fonds auprès des personnes physiques et morales en France, en Europe et à l’international.',

      title2: 'En résumé',
      summary: {
        intro: "Il s'agira: ",
        t1: "D'identifier les porteurs de projets ou entrepreneurs dans le besoin, et susceptibles de remplir les conditions qui sous-tendent nos interventions",
        t2: "De faire appel à aux projets dans des secteurs d'activités bien précis, comme l'agro-industrie pour ne citer que cet exemple ;",
        t3: ' D’évaluer les projets et en sélectionner les plus fiables et les plus réalistes et les plus utiles à la communauté ;',
        t4: 'De contribuer à rédiger les projets, d’en étudier la faisabilité, d’en effectuer les études de terrain et de la concurrence ;',
        t5: 'D’effectuer les accompagnements, administratif et juridique à l’endroit des entreprises et porteurs de projets;',
        t6: 'A.I.D.S.E.A., n’exclut pas tout aussi d’accompagner financièrement tout afro-descendant porteur de projet (s), et qui rempliraient nos conditions de sélection de projets à financer. ',
      },
      title3: 'NB 1',
      text3:
        "Le but de l'association A.I.D.S.E.A. ne sera pas (Il en n'est pas question) de prendre possession des entreprises ou prendre des participations dans les entreprises. Le but est d'aider les centres de recherche, les entrepreneurs, les entreprises et les porteurs de projets de création d'entreprises en mettant à leur disposition des fonds financiers sous forme d’aide ou de prêts à taux zéro et avec une certaine flexibilité temporelle pour le remboursement en cas de prêts. Mais aussi pour leur apporter un soutien plus que nécessaire en termes de soutiens techniques, de formations, de conseils en management et stratégie, etc, par le biais de structures deformations et d'accompagnement que nous nous devrons de solliciter par des partenariats avec des contreparties. Il ne s'agira pas, non plus, de distribuer des chèques cadeaux à nos usagers sans exiger d’eux des résultats s’inscrivant dans nos objectifs et salvateurs pour l’Afrique.",
      title4: 'NB 2',
      text4: `Après remboursement des fonds mis à disposition, l'entreprise aidée
      est libre de tout engagement vis-à-vis de l'association
      A.I.D.S.E.A., si ce n'est la seule obligation morale pour cette
      dernière de figurer parmi les adhérents ou donateurs de
      l'association et de renforcer la capacité de l’association à aider
      bien d'autres entrepreneurs et/ou porteurs de projets de création.
      Il s’agira donc ainsi, pour les usagers déjà aidés et en succès de
      renforcer l'action solidaire. Pour ce qui est des centres de
      recherches, ils se devront d’axer leurs recherches sur la production
      de solutions à grande utilité publique car à cette étape précise de
      notre développement, l’on ne nous demande d’organiser des voyages
      sur la lune ni sur mars. Par contre, l'association se réservera un
      droit de visite de ses usagers, afin, de constater à des périodes
      régulières, l'évolution et les performances des entreprises déjà
      aidées. Les centres de recherche, les entrepreneurs, les entreprises
      et porteurs de projets à aider se devront de ce fait pour certains,
      de rembourser les ressources obtenues ou mis à disposition. Et, pour
      ce faire, l'association A.I.D.S.E.A. sur une certaine période se
      devra de participer à la gestion des entreprises financées jusqu'à
      remboursement des fonds mis à disposition.`,
      title5: 'NB 3',
      text5: ` Ces visites auront pour objectif principal d'apprécier les résultats
      des actions de l'association dans la durée et de voir si nécessaire
      comment les réorienter et/ou les adapter au mieux. Autre précision,
      dans le but d'éviter ou de limiter les gabegies, l'association
      A.I.D.S.E.A. se réserve le droit de ne pas reverser des sommes
      d’argent en espèce à ses usagers mais plutôt d’effectuer ses apports
      en financement d'achats de services, biens et outils d'équipements.
      Pour ce qui est des biens et outils d’équipement, ils resteront la
      propriété de l’association A.I.D.S.E.A. sur une certaine période
      suffisamment longue avant d’être définitivement cédés aux usagers de
      sorte à éviter la revente de ses biens d’équipement mis à
      disposition. A ce propos, toute revente des biens et outils,
      engagerait la responsabilité pénale de l’usager malveillant. Pour
      les cas exceptionnels de renforcement de besoins en fonds de
      roulement les mises à disposition de fonds financiers seront étudier
      au cas par cas. Toutes relations d’actions, devant exister entre
      l’association A.I.D.S.E.A. et ses usagers se fera obligatoirement
      sur la base de contrats en bonne et due forme rédigés par un service
      juridique assermenté et compétent.
      `,
    },

    fundsTitle:
      "CES FONDS AINSI MOBILISÉS SERONT DESTINÉS À L'AFRIQUE ET À L'HAÏTI :",
    fundsText1:
      "Investis dans les entreprises en phase de création pour lesquelles l'obtention de crédits n'est pas une solution ou est impossible vu que les porteurs de projets en Afrique ne disposent pas dans leur très grande majorité de fonds nécessaires à la création et non plus de garanties à proposer aux instituions et/ou établissements financiers pour l’obtention de crédits;",
    fundsText2:
      'Injectés dans les entreprises traversant des périodes difficiles afin de les maintenir en vie et de garantir ainsi les emplois;',
    fundsText3:
      "Utilisés pour soutenir les projets de développement de certaines entreprises en soutenant ainsi l'activité    économique du continent;",
    fundsText4:
      "Utilisés de façon exceptionnelle pour participer au financement de certains projets d'intérêt général et national, par exemple investir dans les créations d’usines de désalinisation d'eau de mer ou de production d’eau potable afin d'apporter de l'eau potable à certaines populations pour satisfaire leurs besoins domestiques et besoins d’eau d’irrigation pour l’agriculture, ce qui reviendrait à contribuer au développement de l’agriculture et à favoriser l’autosuffisance alimentaire du continent;",
    fundsText5:
      'Utilisés pour financer l’apport d’assistance technique aux entreprises, aux entrepreneurs et porteurs de projets afin de renforcer leurs compétences et leur compétitivité;',
    fundsText6:
      'Utilisés afin de développer des partenariats avec les grandes écoles, les universités, et centres de recherche du continent dans le but de favoriser par ces investissements l’éclosion des incubateurs dans leur environnement dans le double but de contribuer à la création de liens entre le monde industriel et ces structures, et d’inciter à l’industrialisation de leurs résultats de recherches. Nous nous devrons, dans une certaine mesure d’accompagner et de fiancer gracieusement ces différents centres de recherches qui devront à notre égard avoir une obligation de résultats et accepter des audits périodiques de notre part afin de vérifier que le processus d’usage des moyens mis à disposition ne souffre d’aucune gabegie.',
    fundsText7:
      'Utilisés aussi, pour servir de fonds de garantie auprès des institutions et établissements financiers pour les entreprises, les entrepreneurs et porteurs de projets dans le but d’inciter ces établissements à les accompagner financièrement. Dans ce sens, des partenariats d’exclusivité seront envisagés avec certains établissements financiers',
  },
  officialDocuments: {
    title: 'Documents officiels',
    buttonTitle: 'Consulter',
    container1: {
      title: 'Journal Officiel',
      description: 'Document officiel de notre association délivré par le',
    },
    container2: {
      title: 'Recepisse',
      description: "Récépissé de déclaration de l'association,",
      description2: 'N° W802017800',
    },
    container3: {
      title: 'Siret',
      description: 'Association active au répertoire Siren,',
      description2: 'N° Siret: 884 063 660 00010',
    },
    links: {
      title1: "Statuts et Règlements intérieur de l'association ",
      link1: 'Voir les statuts',
      link2: 'Voir le règlement intérieur',
      title2: 'Références',
      link3: 'Journal Officiel',
      link4: 'Information légale, juridique et de propriété intellectuelle',
      link5: "Annuaire des associations d'Amiens métropole",
    },
  },
  partners: {
    title: 'Nos partenaires',
    description1: 'Plus de',
    description2: '10',
    description3: 'partenaires',
    buttonTitle: 'Tous les partenaires',
  },
  joinPage: {
    become: 'Devenir',
    benefactors: {
      mainText:
        "Les membres bienfaiteurs de l’association sont, les personnes physiques ou morales qui apportent une contribution financière exceptionnelle à l'association ou qui acceptent de payer régulièrement une cotisation mensuelle ou annuelle sans tenir compte du montant minimum fixé chaque année par le conseil. Le montant choisi par les membres bienfaiteurs est libre et confidentiel. Pour être membre bienfaiteur pour les personnes physiques, il faut être majeur peu importe l’âge, le sexe, le lieu de résidence, et épouser la cause de l’association. Tout en vous remerciant donc de l’intérêt que vous accordez à l’association A.I.D.S.E.A. et donc à l’Afrique et à Haïti, nous vous invitons à démarrer votre adhésion.",
    },
    member: {
      mainText:
        "Les membres adhérents peuvent être des personnes physiques et morales intéressées par l'objet de l'association et adhérant aux statuts et à son règlement intérieur. Les membres adhérents s'acquittent d'une cotisation annuelle fixée par l'Assemblée Générale (le versement de cette somme peut se faire en plusieurs fois sur l’année). Les membres adhérents sont, de droit, membres de l'Assemblée Générale avec voix délibérative. Tout en vous remerciant de l’intérêt que vous accordez à l’association A.I.D.S.E.A. à l’Afrique et à Haïti, nous vous prions de contacter l’équipe dirigeante pour entretien avant d’entamer votre adhésion en ligne.",
    },
    honoraryMember: {
      mainText:
        "Les membres d’honneur peuvent être des personnes physiques ou morales, peu importe leur pays de résidence ou de domiciliation. Aussi, l’on acquiert la qualité de membre d’honneur de l’association, en raison de ses qualités, compétences, son autorité, ses réseaux ou en raison de ses actions favorables à l’association. Le membre d’honneur peut donc être dispensé de verser une cotisation, sauf s’il décide de s'en acquitter de sa propre volonté, ce qui serait à son honneur. Le montant choisi par les membres d’honneur qui souhaitent s’acquitter d’une cotisation est confidentiel. Tout en vous remerciant donc de l’intérêt que vous accordez à l’association A.I.D.S.E.A. et donc à l’Afrique et à Haïti; nous vous invitons à démarrer votre adhésion.",
    },
    volunteer: {
      mainText:
        "Les membres d’honneur peuvent être des personnes physiques ou morales, peu importe leur pays de résidence ou de domiciliation. Aussi, l’on acquiert la qualité de membre d’honneur de l’association, en raison de ses qualités, compétences, son autorité, ses réseaux ou en raison de ses actions favorables à l’association. Le membre d’honneur peut donc être dispensé de verser une cotisation, sauf s’il décide de s'en acquitter de sa propre volonté, ce qui serait à son honneur. Le montant choisi par les membres d’honneur qui souhaitent s’acquitter d’une cotisation est confidentiel. Tout en vous remerciant donc de l’intérêt que vous accordez à l’association A.I.D.S.E.A. et donc à l’Afrique et à Haïti; nous vous invitons à démarrer votre adhésion.",
    },
  },
  donationPage: {
    title: 'Payez en toute sécurité.',
    subTitle1: "POUR L'INDUSTRIALISATION DE L'AFRIQUE ET L'HAÏTI,",
    subTitle2: 'VOTRE CENTIME COMPTE:',
    description:
      "Car cela reviendrait à être solidaire et à faire partie d'un mouvement plus que humanitaire dédié à l'Afrique et à l'Haïti et consistant à apporter votre soutien financier à nos projets, à nos actions et à nos manifestations...",
    form: {
      title1: 'Ma contribution',
      input1: 'Je donne depuis',
      input2: 'Type de don',
      input3: 'Je contribue',
      input31: 'Ponctuellement',
      input32: 'Mensuellement',
      input4: 'Montant',
      input401: 'Autre montant',
      input402: 'Si autre montant, à préciser',
      input403: 'Monnaie',
      input5: 'Mode de paiement',
      input501: 'Carte bancaire',
      input502: 'Mobile Money',
      title2: 'Mes coordonées',
      input6: 'Civility',
      input601: 'Monsieur',
      input602: 'Madame',
      input603: 'Autre',
      input7: 'Nom',
      input8: 'Prénoms',
      input9: 'Email',
      input10: 'Confirmer votre email',
      input10Error: 'Les emails ne correspondent pas, merci de vérifier.',
      input11: 'Téléphone',
      input12: 'Profession ou activité',
      input13: 'En savoir plus',
      otherDonationTitle: 'VOUS POUVEZ AUSSI FAIRE UN DON PAR :',
      inputAddress: 'Adresse',
      country: 'Veuillez entrer votre pays',
    },
    otherPaymentMethods: {
      sentence1:
        'Pour faire un don par virement bancaire, adresser le virement au compte suivant :',
      sentence2:
        "Pour faire un don par le biais de WeternUnion - Ria - MoneyGram, etc... il suffit d'adresser votre paiement à la personne suivante :",

      lastname: 'Nom (madame): GNAMA',
      firstname: 'Prénoms: Joviane',
      forTheAssociation: "Pour l'association",
      emailMessage:
        ' Il vous faudra impérativement nous envoyer par courriel à l’adresse email :',
      whatsapp: 'ou par Whatsapp au numéro',
      confirmation:
        'la copie ou la photo du reçu de transfert. Avec ces éléments, nous pourrons percevoir votre paiement.',
      thanks: 'Nous vous remercions d’avance pour votre contribution.',
    },
  },
  partnerPage: {
    title: 'Devenir partenaire',
    subtitle: 'PARTENARIAT AVEC A.I.D.S.E.A.',
    description:
      "Pour avoir le droit de devenir PARTENAIRE, vous devez être une entreprise, une ONG, une Association, une fondation, un centre de recherche, une université, une Grande école, un Etat ou une Institution Privée ou publique... Si devenir partenaire de l'association A.I.D.S.E.A. vous intéresse, nous vous invitons à remplir le formulaire ci-dessous:",
    form: {
      successMessage:
        "Votre profil à bien été enregistré et votre paiement à bien été pris en compte. Votre reçu fiscal vient d'être téléchargé.",

      name: 'Nom ou raison sociale du partenaire',
      institutionType: 'Type of institution',
      countryOfResidence: 'Pays de résidence',
      address: 'Adresse',
      phone: 'Téléphone',
      presentationOverview: 'Présentation sommaire',
      presentationOverviewDescription:
        'En quelques phrases, présentez votre chaîne (ou poste), votre audience et le territoire concerné',
      logoInput: 'Choisir votre logo',
      logoAcceptedFormats: 'Formats acceptés: JPG, JPEG, PNG ou SVG',
      pleaseInsertYourLogo: 'Veuillez insérer votre logo',
      websiteUrl: 'URL de votre site web',
      donationMinimum: 'Payer ( un minimum de 25€/$ est nécessaire )',
      otherAmount: 'Autre montant',
      ifOtherAmount: 'si autre montant, à préciser',
      minimumRequired:
        'Veuillez entrer un minimum de 25€/$ pour devenir partenaire',
      paymentMethod: 'Mode de paiement',
      card: 'Carte bancaire',
      rulesOfRegulation: '',
      learnMore: 'En savoir plus',
      termsAndConditions:
        'Accepter les Conditions générales d’utilisation d’A.I.D.S.E.A',
      validate: 'Valider',
      correct: 'Corriger',
    },
  },
  mediaPage: {
    title: 'A.I.D.S.E.A. médias',
    searchbox: 'Cherchez par titre, auteur, mots-clés ou date',
    by: 'Par',
    read: "Lire l'article",
    watch: 'Voir la vidéo',
  },
  contactUs: {
    title: 'Contactez-nous',
    subTitle1: "Pour l'afrique et l'haiti,",
    subTitle2: 'nous restons à votre disposition.',
    description:
      "Car cela reviendrait à être solidaire et à faire partie d'un mouvement plus que humanitaire dédié à l'Afrique et à l'Haïti et consistant à apporter votre soutien financier à nos projets, à nos actions et à nos manifestations...",
    form: {
      lastname: 'Nom',
      firstname: 'Prénoms',
      email: 'Email',
      phone: 'Téléphone',
      message: 'Message',
      send: 'Envoyer',
    },
  },
  form: {
    fillInForm: 'Remplir le formulaire',
    partnerEmailAlreadyUsed:
      "L'adresse email utilisée est déjà liée à un de nos partenaires.",
    thanksMembers:
      'Merci de votre inscription, vous allez être recontacté prochainement par notre équipe.',
    dateOfBirth: 'Date de naissance',
    placeOfBirth: 'Lieu de naissance',
    country: 'Pays',
    address: 'Adresse postale',
    reasonsApplication: "Motivation de votre demande d'adhésion",
    reasonsApplicationDescription:
      'En quelques phrases, quelles sont les motivations de votre demande',
    rulesOfProcedureAcknowledgment:
      'Je reconnais avoir lu et approuvé les statuts, règlement de l’association et objet de l’association A.I.D.S.E.A. ; Et en adhérant à cette association, je m’engage à me conformer de façon stricte à ses statuts et règlement intérieur et à ses valeurs et éthique.',
    rulesOfProcedureAcceptation:
      "Accepter le règlement intérieur de l'association",
    errors: {
      fillInField: 'Veuillez remplir ce champ',
      email: 'Veuillez entrer votre adresse email',
      phone: 'Veuillez entrer un numéro de téléphone valide',
      typeOfIns: "Veuillez entrer le type d'institution",
      companyName:
        'Veuillez entrer votre nom ou la raison sociale de votre structure',
      fullMailingAddress: 'Veuillez entrer votre adresse complète',
      activity: 'Veuillez entrer votre profession ou activité',
      dateOfBirth: 'Veuillez entrer votre date de naissance',
      emailConfirm: 'Veuillez confirmer votre email',
      placeOfBirth: 'Veuillez entrer votre lieu de naissance',
      lastname: 'Veuillez entrer votre nom',
      firstname: 'Veuillez entrer vos prénoms',
    },
    redirection: 'Vous allez être redirigé dans ',
  },
  general: {
    thanks: 'Merci',
    industrializeAfrica:
      "Industrialiser l'Afrique et l'Haïti, ensemble nous le pouvons !",
    problemWithPayment:
      'Nous avons rencontré un problème avec votre moyen de paiement, merci de vérifier et réessayer',
    pageError: "La page que vous recherchez n'est pas disponible...",
  },
};

// ------------ ENGLISH ------------- //
export const EN = {
  header: {
    homepage: 'Home',
    about: 'About us',
    officialDocuments: 'Official documents',
    becomePartner: 'Becoming partner',
    news: 'News',
    join: 'Join',
    joinMember: 'become member',
    joinHonoraryMember: 'become Member of honor ',
    joinBenefactor: 'become Benefactor',
    joinVolunteer: 'become Volunteer',
    donate: 'Donate',
  },
  footer: {
    association: 'The association',
    contactUs: 'Contact us',
    rulesOfProcedure: 'Rules of procedure',
    statutes: 'See the statutes',
    social: 'Social networks',
    logoSentence: 'We think Afrik & Haiti',
    newsletter: 'Newsletter',
    joinNewsletter:
      'Subscribe to our newsletter and follow our actions on the internet',
  },

  heroCarousel: {
    phoneTitle1: 'OUR GENERATION DESERVES HAPPINESS',
    title1: "LET'S INDUSTRIALIZE AFRICA",
    description1:
      'Because this new generation deserves social & economic well-being.',

    title2: "WE HAVE CREATIVE CAPACITIES, LET'S VALUE THEM.",
    description2:
      'Because this new generation deserves social & economic well-being.',

    title3: "LET'S MAKE OUR MOTHERLAND HAPPY.",
    description3:
      'Because this new generation deserves social & economic well-being.',
  },

  homepage: {
    ourThought: 'Our thought',
    mainText: {
      first:
        'The Association for Industrialization and Social and Economic Development of Africa: A.I.S.E.D.A. is pleased to put online this site which we hope will bring more information and interaction with it.',
      second:
        'Our association would like to be active thanks to its members and those who among its members and volunteers will decide to devote time and to make a full commitment to its growth and to give meaning to its goals and ambitions. We will therefore be delighted to welcome all those who wish to do so, knowing that the strength of an association is its team, its values and the meaning it decides to give to its actions.',
      third:
        'The birth of our association thus reflects the convergence and the coming together of different expectations and isolated individual ambitions in order to build an industrialized Africa full of hope and where it is good to live. In summary, A.I.S.E.D.A. is a translation of the ambition of its founders for a project and a model promoting the defense of Africa’s interests by sharing and attracting the support of the greatest number of interested people, Afro-descendants first.',
      fourth:
        'It is therefore the recognition, not only of an essential mission but also of a passion at the heart of Africa’s development problems and its economic and social development, of happiness and self-esteem of the Afro-descendants. In other words, it is about confirming the will to value Africa’s wealth and creative capacity.',
      fifth:
        'Finally, the A.I.S.E.D.A. Association is an ambassador for the promotion of the industrial process in Africa. It will fulfill its objective, satisfy its Ambition thanks to the close involvement of all its adherent members. It is therefore up to us to invest in it, to take possession of it, to animate it, to be a force of proposals to accompany its development, its effectiveness and its success, that is to say, to achieve the industrialization and the social and economic development of Africa and Haiti, but above all also to make the pride of all Afro-descendants all over the world.',
    },
    presidentName: 'Prince-Henry DJOKOURY',
    presidentOf: 'President of A.I.S.E.D.A.',
  },
  aboutPage: {
    title: 'Who are we ?',
    subTitle1: 'A.I.S.E.D.A.',
    description10: 'ASSOCIATION FOR INDUSTRIALIZATION AND',
    description11: 'SOCIAL AND ECONOMIC DEVELOPMENT OF AFRICA',
    description12: 'SOCIAL AND ECONOMIC',
    description13: 'DEVELOPMENT OF AFRICA',
    description2:
      'Is an association governed by French law, subject to the law of July 1st, 1901 and to the decree of August 16th, 1901.',
    marqueeTitle: 'UNITED, WE CAN GREEN OUR INDUSTRIAL DESERT!',
    carousel: {
      title1: 'Goals',
      subTitle1: 'The purpose of the association is as follows:',
      text1:
        'A.I.S.E.D.A. is a French association (Law 1901) of international solidarity. It is dedicated to the industrialization of AFRICA (Motherland) and HAITI, which we consider to be part of Africa despite the territorial discontinuity. Explicitly, its purpose and vocation is to promote industry in Africa (including HAITI) and to contribute to the industrialization of this continent and its economic and social development, by mobilizing financial capital through popular fund raising with natural and legal persons in France, Europe and internationally.',
      title2: 'In summary',
      summary: {
        intro: 'It will be about: ',
        t1: `Identifying the project leaders or entrepreneurs in need, and likely to fulfill the conditions that underlie our
        interventions;`,

        t2: `involving projects in specific sectors of activity, such as agro-industry, to cite just this example;`,
        t3: `Evaluating projects and select the most reliable, realistic and useful to the community;`,
        t4: `Contributing to project preparation, study their feasibility, carry out field and competition studies;`,
        t5: `Provide administrative and legal support to companies and project promoters;`,
        t6: `A.I.S.E.D.A. does not exclude to financially support any Afro-descendant who has project(s), and who would fulfill our conditions for selecting projects to be financed.`,
      },
      title3: 'NB 1',
      text3: `The purpose of the A.I.S.E.D.A. association will not be (there is no question about it) to take possession of enterprises or take participations in the enterprises. The aim is to help research centers, entrepreneurs, companies and entrepreneurs involved in start-up projects by making financial funds available to them in the form of aid or loans at zero interest rates and with a certain flexibility in time for repayment in the event of loans. But also to provide them with much needed support in terms of technical support, training, management and strategy advice, etc., through training and support structures that we will have to seek through partnerships with counterparts. Nor will it be a question of handing out gift certificates to our users without demanding from them results that are in line with our objectives and salvation for Africa.`,

      title4: 'NB 2',
      text4: `After reimbursement of the funds made available, the aided company is free from any commitment towards the association A.I.S.E.D.A. except for the only moral obligation for the latter to be among the association members or donors and to strengthen the capacity of the association to help many other entrepreneurs and/or promoters of creative projects. It will therefore be a matter of strengthening solidarity action for users who have already been helped and who have been successful. As far as research centers are concerned, they will have to focus their research on the production of great public utility solutions because at this precise stage of our development, we are not asked to organize trips to the moon or to March. On the other hand, the association reserves the right to visit its users, in order to see at regular times the evolution and the performances of the already supported companies. The main objective of these visits will be to assess the results of the association's actions over the long term and to see if necessary how to reorient and/or adapt them as best as possible. Another point of clarification is that, in order to avoid or limit waste, the A.I.S.E.D.A. association reserves the right not to give away cash to its users but rather to make its contributions in financing the purchase of services, goods and equipment tools. As regards equipment and tools, they will remain the property of the A.I.S.E.D.A. association for a sufficiently long period before being definitively transferred to the users in order to avoid the resale of its equipment made available.`,

      title5: 'NB 3',
      text5: ` The main objective of these visits will be to assess the results of the association's actions over the long term and to see if necessary how to reorient and/or adapt them as best as possible. Another point of clarification is that, in order to avoid or limit waste, the A.I.S.E.D.A. association reserves the right not to give away cash to its users but rather to make its contributions in financing the purchase of services, goods and equipment tools. As regards equipment and tools, they will remain the property of the A.I.S.E.D.A. association for a sufficiently long period before being definitively transferred to the users in order to avoid the resale of its equipment made available. In this regard, any resale of goods and tools would involve criminal responsibility of the malicious user. In exceptional cases of increased working capital requirements, the availability of financial funds will be considered on a case-by-case basis. All relations of actions, which must exist between the A.I.S.E.D.A. association and its users, will be made obligatorily on the basis of contracts in good form drawn up by a sworn and competent legal service.
      `,
    },

    fundsTitle: 'THESE MOBILIZED FUNDS WILL BE INTENDED TO AFRICA AND HAITI: ',
    fundsText1: `Invested in start-up companies for which obtaining credit is not a solution or is impossible, since the vast majority of project managers in Africa do not have the funds necessary for creation and no longer have the guarantees to offer to institutions and/or financial establishments for obtaining loans;`,
    fundsText2: `Injected into enterprises going through difficult times in order to keep them alive and thus guarantee jobs;`,
    fundsText3: `Used to support the development projects of certain companies, thus supporting the economic activity of the continent;`,
    fundsText4: `Exceptionally used to contribute to the financing of certain projects of general and national interest, such as investing in the creation of seawater desalination plants or the production of drinking water to provide drinking water to certain populations to meet their domestic needs and irrigation water needs for agriculture, which would contribute to the development of agriculture and promote food self-sufficiency of the continent;`,
    fundsText5: `Used to finance the provision of technical assistance to enterprises, entrepreneurs and project promoters in order to enhance their skills and competitiveness;`,
    fundsText6: `Used to develop partnerships with major schools, universities, and research centers on the continent with the aim of encouraging the emergence of incubators in their environment with the dual aim of contributing to the creation of links between the industrial world and these structures, and of encouraging the industrialization of their research results. To some extent, we will have to accompany and graciously fund these various research centers, which will have to be accountable for their results and accept periodic audits from us in order to verify that the process of using the resources made available does not suffer from any mismanagement.`,
    fundsText7: `Also used as a guarantee fund for financial establishments and institutions for companies, entrepreneurs and project promoters in order to encourage these institutions to support them financially. In this case, exclusive partnerships will be considered with certain financial institutions.`,
  },
  officialDocuments: {
    title: 'Official Documents',
    buttonTitle: 'Consult',
    container1: {
      title: 'Official Newspaper',
      description: 'Official document of our association issued by the',
    },
    container2: {
      title: 'receipt',
      description: 'The Association declaration receipt,',
      description2: 'N° W802017800',
    },
    container3: {
      title: 'CRN',
      description: 'Active association with CRN directory,',
      description2: 'CRN Number: 884 063 660 00010',
    },
    links: {
      title1: 'Association statutes and Internal Regulations',
      link1: 'See the statutes',
      link2: 'See the rules of regulations',
      title2: 'References',
      link3: 'Official Newspaper',
      link4: 'Legal and intellectual property information',
      link5: 'Associations directory of Amiens Metropolis',
    },
  },

  partners: {
    title: 'Our partners',
    description1: 'More than',
    description2: '10',
    description3: 'partners',
    buttonTitle: 'All our partners',
  },
  joinPage: {
    become: 'Becoming',
    benefactors: {
      mainText:
        'Benefactor members of the association are natural or legal persons who make an exceptional financial contribution to the association or who agree to regularly pay a monthly or annual membership fee without taking into account the minimum amount set each year by the council. The amount chosen by honorary members is free and confidential. In order to be a benefactor member for individuals, you must be of legal age regardless of age, sex, place of residence, and embrace the cause of the association. So while thanking you for your interest in the A.I.S.E.D.A. association and therefore to Africa and Haiti, we invite you to start your membership.',
    },

    member: {
      mainText:
        'Members can be natural and legal persons interested in the object of the association and adhering to the statutes and its internal rules. Members pay an annual membership fee fixed by the General Assembly (payment of this amount can be made in several installments over the year). The joining members are, by law, members of the General Assembly with voting rights. While thanking you for your interest in the A.I.S.E.D.A. association in Africa and Haiti, please contact the management team for an interview before starting your online membership. ',
    },
    honoraryMember: {
      mainText:
        'Honorary members may be natural or legal persons, regardless of their country of residence or domicile. Also, one acquires the status of honorary member of the association, by virtue of his qualities, skills, authority, networks or by virtue of his favorable actions to the association. The honorary member may therefore be exempted from paying a contribution, unless he decides to do so on his own initiative, which would be to his honor. The amount chosen by honorary members who wish to pay a membership fee is confidential. While thanking you for your interest in the A.I.S.E.D.A. association and thus to Africa and Haiti; we invite you to start your membership.',
    },
    volunteer: {
      mainText:
        'Volunteers are natural persons who freely commit to carry out unpaid work for the association outside of their family and professional time. Any association volunteer agrees to execute his mission without being bound to the association by any rule of duration or frequency other than the rules which may have been defined in the contract of volunteering. But, above all, the volunteer is the one who participates in the association’s activity without receiving any remuneration or compensation, in any form whatsoever, cash or nature (with the exception of expenses reimbursements).While thanking you for your interest in the A.I.S.E.D.A. association and therefore to Africa and Haiti. We ask you to note that becoming a volunteer is subject to the following conditions: Being of legal age (regardless of gender and country of residence), Being included in the donors database and having to make at least a 50 € / $ minimum cumulative donation over the current year.',
    },
  },
  donationPage: {
    title: 'Pay Safely',
    subTitle1: 'FOR AFRICA AND HAITI INDUSTRIALIZATION,',
    subTitle2: 'YOUR PENNY COUNTS:',
    description:
      'Because this would mean being in solidarity and being part of more than a humanitarian movement dedicated to Africa and Haiti and consisting in giving your financial support to our projects, our actions and our events…',
    form: {
      title1: 'My contribution',
      input1: 'I give from',
      input2: 'Type of donation',
      input3: 'I contribue',
      input31: 'punctually',
      input32: 'monthly',
      input4: 'Amount',
      input401: 'Other amount',
      input402: 'If other another, please specify',
      input403: 'Currency',
      input5: 'Payment method',
      input501: 'Bank Card',
      input502: 'Mobile Money',
      title2: 'My contact details',
      input6: 'Civility',
      input601: 'Mr.',
      input602: 'Mrs.',
      input603: 'Other',
      input7: 'Lastname',
      input8: 'First names',
      input9: 'Email',
      input10: 'Confirm your email',
      input10Error: "Emails don't match, please verify",
      input11: 'Phone',
      input12: 'Profession or activity',
      input13: 'Learn more',
      otherDonationTitle: 'You can also make a donation by :',
      inputAddress: 'Address',
      country: 'Please enter your country',
    },
    otherPaymentMethods: {
      sentence1:
        'To donate by bank transfer, make the transfer to the following account :',
      sentence2:
        'To donate through Western Union – Ria – Money Gram, etc… just send it to this following person : ',

      lastname: 'Last Name : GNAMA',
      firstname: 'First names : Joviane ',
      forTheAssociation: 'For the Association :',
      emailMessage: ' You must send us an email to this address : ',
      whatsapp: 'or at this Whatsapp number',
      confirmation:
        'the copy or picture of the transfer receipt. With these elements, we will be able to collect your payment.',
      thanks: 'We thank you in advance for your contribution.',
    },
  },
  partnerPage: {
    title: 'Becoming partner',
    subtitle: 'PARTNERSHIP WITH A.I.S.E.D.A.',
    description:
      'To have the right to become a PARTNER, you must be a company, an NGO, an Association, a foundation, a research center, a college, a Major School, a State or a Public or Private Institution ... If you are interested in becoming a partner of the A.I.S.E.D.A. association, we invite you to fill out the form below:',
    form: {
      successMessage:
        'YOUR PROFILE HAS BEEN REGISTERED AND YOUR PAYMENT HAS BEEN TAKEN INTO ACCOUNT. YOUR TAX RECEIPT HAS JUST BEEN DOWNLOADED. ',

      name: 'Partner Name or Business Name',
      institutionType: 'Type of institution',
      countryOfResidence: 'Country of residence',
      address: 'Mailing Address',
      phone: 'Phone',
      presentationOverview: 'Presentation Overview',
      presentationOverviewDescription:
        'In a few sentences, present your channel (or post), your audience and the concerned territory',
      logoInput: 'Choose your logo',
      logoAcceptedFormats: 'Accepted formats: JPG, JPEG, PNG ou SVG',
      pleaseInsertYourLogo: 'Please insert your logo',
      websiteUrl: 'Your website URL',
      donationMinimum: 'Pay (a 25 € / $ minimum is required)',
      otherAmount: 'Other amount',
      ifOtherAmount: 'if other amount, please specify',
      minimumRequired: 'Please enter a minimum of 25€/$ to become partner',
      paymentMethod: 'Payment method',
      card: 'Bank card',
      rulesOfRegulation: '',
      learnMore: 'Learn more',
      termsAndConditions: 'Accept the A.I.S.E.D.A. Terms and Conditions',
      validate: 'Validate',
      correct: 'Correct',
    },
  },
  mediaPage: {
    title: 'A.I.S.E.D.A. media',
    searchbox: 'Search by title, author, keywords or date',
    by: 'By',
    read: 'Read the article',
    watch: 'Watch the video',
  },
  contactUs: {
    title: 'Contact us',
    subTitle1: 'FOR AFRICA AND HAITI,',
    subTitle2: 'WE REMAIN AT YOUR DISPOSAL.',
    form: {
      lastname: 'Names',
      firstname: 'First Names',
      email: 'Email',
      phone: 'Phone',
      message: 'Message',
      send: 'Send',
    },
  },
  form: {
    fillInForm: 'Fill in the form',
    partnerEmailAlreadyUsed:
      'The email is already in use by one of our partners.',
    thanksMembers:
      'Thank you for subscribing, Our team will contact you shortly.',
    dateOfBirth: 'Date of birth',
    placeOfBirth: 'Place of birth',
    country: 'Country',
    address: 'Address',
    reasonsApplication: 'Reasons for your application',
    reasonsApplicationDescription:
      'In a few sentences what are the reasons for your request',
    rulesOfProcedureAcknowledgment:
      'I acknowledge having read and approved the statutes, association rules and purpose of the A.I.S.E.D.A. association; And by joining this association, I strictly undertake to comply with its statutes and internal regulations and with its values ​​and ethics. Learn more',
    rulesOfProcedureAcceptation: 'Accept the association rules of procedure',
    errors: {
      fillInField: 'Please fill in this field',
      email: 'Please enter your email address',
      phone: 'Please enter a valid phone number',
      typeOfIns: 'Please enter the type of institution',
      companyName:
        'Please enter your name or the company name of your structure',
      fullMailingAddress: 'Please enter your full mailing address',
      activity: 'Please enter your profession or activity ',
      dateOfBirth: 'Please enter your date of birth',
      emailConfirm: 'Please confirm your email',
      placeOfBirth: 'Please enter your place of birth',
      lastname: 'Please enter your lastname',
      firstname: 'Please enter your first Names',
    },
    redirection: 'You will be redirected in ',
  },
  general: {
    thanks: 'Thank you',
    industrializeAfrica: 'Industrialize Africa and Haiti, together we can!',
    problemWithPayment:
      'A problem occured with your payment method, please check and try again. ',
    pageError: "This page doesn't exist...",
  },
};
