import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Individual reducers
import loaderReducer from './loader/loader.reducer';
import partnersReducer from './partners/partners.reducer';
import adminReducer from './admin/admin.reducer';
import adminDataReducer from './admin-data/admin-data.reducer';
import CardTextReducer from './card-text/card-text.reducer';
import CookieReducer from './cookies/cookies.reducer';
import LanguageReducer from './language/language.reducer';
import DonationsReducer from './donations/donations.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['admin', 'cookies', 'donations'],
};

const rootReducer = combineReducers({
  loader: loaderReducer,
  partners: partnersReducer,
  admin: adminReducer,
  adminData: adminDataReducer,
  cardText: CardTextReducer,
  cookies: CookieReducer,
  language: LanguageReducer,
  donations: DonationsReducer,
});

export default persistReducer(persistConfig, rootReducer);
