import DonationsActionTypes from './donations.types';

const INITIAL_STATE = {
  donationToken: '',
};

const DonationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DonationsActionTypes.SET_DONATION_TOKEN:
      return {
        ...state,
        donationToken: action.payload,
      };
    default:
      return state;
  }
};

export default DonationsReducer;
