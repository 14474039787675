import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Data
import { selectIsLoaderVisible } from '../../redux/loader/loader.selectors';
import { setIsLoaderVisible } from '../../redux/loader/loader.actions';
import { fetchMediaStartAsync, updateMediaTextStartAsync } from '../../redux/admin-data/admin-data.actions';
import { selectMedia, selectMediaId, selectMediaText } from '../../redux/admin-data/admin-data.selectors';

// Components
import TextEditor from '../../components/text-editor/text-editor.component';
import StateCard from '../../components/state-card/state-card.component';
import MaterialTable2 from '../../components/material-table-2/material-table-2.component';
import AddArticleForm from '../../components/add-article-form/add-article-form.component';

import './admin-media.styles.scss';

const AdminMediaPage = ({
  isLoaderVisible, // HERE
  fetchMedia,
  mediaData,
  mediaId,
  updateMediaText
}) => {
  const [isAddDonationVisible, setIsAddDonationVisible] = useState(false);
  const [isTableVisible, setIsTableVisible] = useState(false)
  const [isAddArticleVisible, setIsAddArticleVisible] = useState(false)
  useEffect(() => {
    fetchMedia();

  }, []);
  const onChange = (text) => {
updateMediaText(mediaId, text)

  }
  return (
    <div className='admin-users-page-container'>
      <h3>Média</h3>
      {isLoaderVisible && <StateCard />}
      <div className='admin-users-table-container'>
        <div className='admin-media-buttons-container'>

          <button
            onClick={() => setIsTableVisible(!isTableVisible)}
            className='green-button-1 marginTop30 marginBottom30'
          >
            Afficher les articles
          </button>
          <button
            onClick={() => setIsAddArticleVisible(!isAddArticleVisible)}
            className='blue-button-2 marginTop30 marginBottom30'
          >
            Ajouter un article
          </button>
        </div>
        {isAddArticleVisible && <AddArticleForm />}
        {isTableVisible &&
          <MaterialTable2 data={mediaData} title='Les articles' />
        }
        <div className="invisible-divider" />
        <TextEditor onChange={onChange} />
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  isLoaderVisible: selectIsLoaderVisible,
  mediaData: selectMedia,
  mediaId: selectMediaId
});
const mapDispatchToProps = (dispatch) => ({
  fetchMedia: () => dispatch(fetchMediaStartAsync()),
  updateMediaText: (id, text) => dispatch(updateMediaTextStartAsync(id, text))
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminMediaPage);
