import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import './languageSelector.styles.scss';

import EnglishFlag from '../../assets/images/en.png';
import FrenchFlag from '../../assets/images/fr.png';

import { setLanguageFunction } from '../../redux/language/language.actions';

const LanguageSelector = ({ setLanguage }) => {
  return (
    <div className='languageSelector-container'>
      <div className='languageSelector languageSelectorFr'>
        <img
          src={FrenchFlag}
          className='languageSelectorImg'
          alt='french flag'
          onClick={() => setLanguage('fr')}
        />
      </div>
      <div className='languageSelector languageSelectorEn'>
        <img
          src={EnglishFlag}
          className='languageSelectorImg'
          alt='english flag'
          onClick={() => setLanguage('en')}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  setLanguage: setLanguageFunction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
