import React from 'react';

import './searchbox.component.styles.scss';
// Dependencies

const Searchbox = ({ onChange, placeholder }) => {
  return (
    <div className='searchbox-container'>
      <input
        onChange={onChange}
        className='searchbox-input'
        placeholder={placeholder}
        type='search'
      />
    </div>
  );
};

export default Searchbox;
