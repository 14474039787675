import { createSelector } from 'reselect';

const selectCardText = (state) => state.cardText;

export const selectCardIsVisible = createSelector(
  [selectCardText],
  (cardText) => cardText.isVisible
);
export const selectCardTitle = createSelector(
  [selectCardText],
  (cardText) => cardText.title
);
export const selectCardTextText = createSelector(
  [selectCardText],
  (cardText) => cardText.text
);
