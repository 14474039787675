import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FR, EN } from '../../js/languagesData';

import { Link } from 'react-router-dom';

import './donation-form.component.styles.scss';

// Components
import Form from 'react-bootstrap/Form';
import { GreenButton1 } from '../buttons/buttons.component';
import Feedback from 'react-bootstrap/esm/Feedback';
import SycaForm from '../sycaForm/sycaForm';

import reglementInterieurPDF from '../../assets/official-docs/reglement_interieur.pdf';

// Images
import wuImg from '../../assets/images/wu.jpg';
import riaImg from '../../assets/images/ria.jpg';
import mgImg from '../../assets/images/mg.png';
import vbImg from '../../assets/images/vb.png';

import {
  setIsLoaderVisible,
  setIsLoaderLoading,
  setTextInsideLoader,
  setIsLoaderSuccess,
} from '../../redux/loader/loader.actions';
import {
  countryList,
  storeDonationInFirestore,
  sendEmailOnNewDonation,
} from '../../js/utils';
import { fetchProjectsStartAsync } from '../../redux/admin/admin.actions';
import { selectProjects } from '../../redux/admin/admin.selectors';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';

const donationsOptions = [];
// 5000 increment  -> 50 000
const calculateVals = () => {
  for (let i = 5000; i < 55000; i += 5000) {
    donationsOptions.push(<option key={i}>{i}</option>);
  }
};

const DonationForm = ({
  setIsLoaderVisible,
  setIsLoaderLoading,
  setTextInsideLoader,
  setIsLoaderSuccess,
  fetchTypeOfDonations,
  projects = ['Don ordinaire', 'Don spécial Haïti'],
  currentLanguage,
}) => {
  const languageData = currentLanguage === 'english' ? EN : FR;

  const [form, setForm] = useState({
    country: 'afghanistan',
    donationType: 'don ordinaire',
    donationFrequency: 'ponctuellement',
    // Payment informations
    donationAmount: 1000,
    donationCurrency: 'XOF',
    donationSpecificAmount: 1000,
    paymentMethod: 'card',
    // contact informations
    lastname: '',
    firstname: '',
    civility: 'monsieur',
    email: '',
    emailConfirm: '',
    phone: '',
    address: '',
    activity: '',
    // Checkbox
    rulesOfProcedures: false,
    termsAndConditions: false,
  });

  const [validated, setValidated] = useState(false);
  const [isFormComplet, setIsFormComplet] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isEmailSame, setIsEmailSame] = useState(true);

  // 438,22 + 508,66.

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    fetchTypeOfDonations();
    calculateVals();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);

    const { email, emailConfirm, donationAmount, donationSpecificAmount } =
      form;

    const formJsx = e.currentTarget;

    if (formJsx.checkValidity() === false) {
      e.stopPropagation();
    }
    if (email !== emailConfirm) return setIsEmailSame(false);
    if (donationAmount !== 'autre montant') {
      if (donationAmount < 5) return;
    } else {
      if (donationSpecificAmount < 5) return;
    }
    if (
      formJsx.checkValidity() === true &&
      form.rulesOfProcedures &&
      form.termsAndConditions
    ) {
      if (form.donationAmount === 'autre montant') {
        form.donationAmount = form.donationSpecificAmount;
      }
      if (form.paymentMethod.toLowerCase() === 'mobile money') {
        form.donationCurrency = 'XOF';
      }

      setIsFormComplet(true);
      setIsLoaderVisible(true);
    } else {
      return setIsFormComplet(false);
    }
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        className='bootstrap-form'
        id='donationForm'
      >
        <h2>{languageData.donationPage.form.title1}</h2>
        <Form.Group>
          <Form.Label>{languageData.donationPage.form.input1}</Form.Label>
          <Form.Control
            as='select'
            name='country'
            onChange={handleChange}
            required
          >
            {countryList.map((country, idx) => (
              <option key={idx} value={country}>
                {country}
              </option>
            ))}
          </Form.Control>
          <Feedback type='invalid'>
            {languageData.donationPage.form.country}
          </Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>{languageData.donationPage.form.input2}</Form.Label>

          <Form.Control
            as='select'
            name='donationType'
            defaultValue={form.donationType}
            onChange={handleChange}
          >
            {projects.map((project) => (
              <option key={project}>{project}</option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Label>{languageData.donationPage.form.input5}</Form.Label>
        <Form.Control
          as='select'
          name='paymentMethod'
          defaultValue={form.paymentMethod}
          onChange={handleChange}
        >
          <option>{languageData.donationPage.form.input501}</option>
          <option>{languageData.donationPage.form.input502}</option>
        </Form.Control>

        <div className='container-display-flex-col'>
          <Form.Label>{languageData.donationPage.form.input4}</Form.Label>
          <Form.Group className='bootstrap-form-select-currency'>
            <Form.Control
              as='select'
              name='donationCurrency'
              onChange={handleChange}
              className='bootstrap-form-select-currency-select'
            >
              <option value='XOF'>XOF (CFA)</option>
            </Form.Control>
            <Form.Control
              className='bootstrap-form-select-currency-amount'
              as='select'
              name='donationAmount'
              onChange={handleChange}
              defaultValue={form.donationAmount}
              required
            >
              <option>1000</option>
              <option>5000</option>
              {donationsOptions.map((d) => d)}

              <option value='autre montant'>
                {languageData.donationPage.form.input401}
              </option>
            </Form.Control>
          </Form.Group>
          {form.donationAmount === 'autre montant' ? (
            <Form.Group>
              <Form.Label>{languageData.donationPage.form.input402}</Form.Label>
              <Form.Control
                type='number'
                min={5}
                name='donationSpecificAmount'
                defaultValue={form.donationSpecificAmount}
                onChange={handleChange}
                required
              />
            </Form.Group>
          ) : (
            ''
          )}
        </div>

        {/*  contact informations */}
        <br />
        <h2>{languageData.donationPage.form.title2}</h2>
        <Form.Group>
          <Form.Label>{languageData.donationPage.form.input6}</Form.Label>
          <Form.Control
            as='select'
            name='civility'
            defaultValue={form.civility}
            onChange={handleChange}
          >
            <option>{languageData.donationPage.form.input601}</option>
            <option>{languageData.donationPage.form.input602}</option>
            <option>{languageData.donationPage.form.input603}</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>{languageData.donationPage.form.input7}</Form.Label>
          <Form.Control
            type='text'
            name='lastname'
            defaultValue={form.lastname}
            onChange={handleChange}
            required
          />
          <Feedback type='invalid'>
            {languageData.donationPage.lastname}
          </Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>{languageData.donationPage.form.input8}</Form.Label>
          <Form.Control
            type='text'
            name='firstname'
            defaultValue={form.firstname}
            onChange={handleChange}
            required
          />
          <Feedback type='invalid'>
            {languageData.donationPage.firstname}
          </Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>{languageData.donationPage.form.input9}</Form.Label>
          <Form.Control
            type='email'
            name='email'
            defaultValue={form.email}
            onChange={handleChange}
            required
          />
          <Feedback type='invalid'>{languageData.form.email}</Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>{languageData.donationPage.form.input10}</Form.Label>
          <Form.Control
            type='email'
            name='emailConfirm'
            defaultValue={form.emailConfirm}
            onChange={handleChange}
            required
          />
          <Feedback type='invalid'>{languageData.form.emailConfirm}</Feedback>
        </Form.Group>
        <p
          className={`alert-helper marginTop-10 ${isEmailSame ? 'hidden' : ''}`}
        >
          {languageData.donationPage.form.input10Error}
        </p>
        <Form.Group>
          <Form.Label>{languageData.donationPage.form.input11}</Form.Label>
          <Form.Control
            onChange={handleChange}
            type='number'
            name='phone'
            defaultValue={form.phone}
            placeholder='Ex: +33 000 000 000'
            required
          />
          <Feedback type='invalid'>
            <Feedback type='invalid'>{languageData.form.phone}</Feedback>
          </Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>{languageData.donationPage.form.inputAddress}</Form.Label>
          <Form.Control type='text' name='address' onChange={handleChange} />
          <Feedback type='invalid'>{languageData.form.address}</Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>{languageData.donationPage.form.input12}</Form.Label>
          <Form.Control type='text' name='activity' onChange={handleChange} />
          <Feedback type='invalid'>
            <Feedback type='invalid'>{languageData.form.activity}</Feedback>
          </Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Check
            type='checkbox'
            className='align-justify'
            name='rulesOfProcedures'
            onChange={handleChange}
            label={languageData.form.rulesOfProcedureAcceptation}
            required
          />
          <a
            href={reglementInterieurPDF}
            target='_blank'
            rel='noreferrer'
            className='paddingLeft20'
          >
            {languageData.donationPage.form.input13}
          </a>
        </Form.Group>
        <Form.Group>
          <Form.Check
            type='checkbox'
            name='termsAndConditions'
            onChange={handleChange}
            label={languageData.partnerPage.form.termsAndConditions}
            required
          />
          <Link to='/terms-and-conditions' className='paddingLeft20'>
            {languageData.donationPage.form.input13}
          </Link>
        </Form.Group>
        {!isFormComplet && (
          <button
            title='Valider'
            className='margin0Center green-button-1'
            onClick={handleSubmit}
          >
            {languageData.partnerPage.form.validate}
          </button>
        )}

        <h3 className='header-small-title marginTop50'>
          {languageData.donationPage.form.otherDonationTitle}
        </h3>

        <div className='basic-container-100-row marginTop40'>
          <img
            src={wuImg}
            className='images-donation-form'
            alt='Western Union'
          />
          <img src={riaImg} className='images-donation-form' alt='RIA' />
          <img src={mgImg} className='images-donation-form' alt='MoneyGram' />

          <img
            src={vbImg}
            className='images-donation-form'
            alt='Virement Bancaire'
          />
        </div>
        {/* {currentLanguage === 'english' && (
        <p>
          By <b>Mobile Money</b>
        </p>
      )}
      {currentLanguage === 'french' && (
        <p>
          Par
          <b> Mobile Money</b>
        </p>
      )}
      <p>ORANGE money : +2250757402563</p>
      <p>MTN money : +2250556011817</p> */}
      </Form>
      {isFormComplet && (
        <SycaForm form={form} handleCorrect={() => setIsFormComplet(false)} />
      )}
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  projects: selectProjects,
  currentLanguage: selectCurrentLanguage,
});
const mapDispatchToProps = (dispatch) => ({
  setIsLoaderVisible: (isVisible) => dispatch(setIsLoaderVisible(isVisible)),
  setIsLoaderLoading: (isLoading) => dispatch(setIsLoaderLoading(isLoading)),
  setTextInsideLoader: (text) => dispatch(setTextInsideLoader(text)),
  setIsLoaderSuccess: (isLoaderSuccess) =>
    dispatch(setIsLoaderSuccess(isLoaderSuccess)),
  fetchTypeOfDonations: () => dispatch(fetchProjectsStartAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DonationForm);
