import React from 'react';
import { connect } from 'react-redux';

import './join-page.styles.scss';

// Components
import HeroTop from '../../components/hero-top/hero-top.component';
import HeaderGreenTitle from '../../components/header-green-title/header-green-title.component';
import FormAndImage from '../../components/form-and-image/form-and-image.component';
import StateCard from '../../components/state-card/state-card.component';

// redux
import { selectIsLoaderVisible } from '../../redux/loader/loader.selectors';
import { FR, EN } from '../../js/languagesData';

// Images
import partnerImg from '../../assets/images/partner.jpg';
import { createStructuredSelector } from 'reselect';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';
import { translateTitle } from '../../js/utils';

const JoinPage = ({ title, text, isLoaderVisible, currentLanguage }) => {
  const languageData = currentLanguage === 'english' ? EN : FR;

  return (
    <div className='join-page paddingTop74 '>
      {isLoaderVisible && <StateCard />}

      <HeroTop
        title={`${languageData.joinPage.become} ${translateTitle(
          title,
          currentLanguage
        )}`}
        img={partnerImg}
        isPartnerOrJoin={true}
      />
      <div className='page-content-width-100'>
        <HeaderGreenTitle
          title={`${translateTitle(title, currentLanguage)}`}
          text={text}
        />
        <FormAndImage img={partnerImg} page='join' title={title} />
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoaderVisible: selectIsLoaderVisible,
  currentLanguage: selectCurrentLanguage,
});
export default connect(mapStateToProps)(JoinPage);
