
import React, { useState, useEffect } from 'react'
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux'

import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';

import { stateFromHTML } from 'draft-js-import-html';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import './text-editor.component.styles.scss';

import { selectMediaText } from '../../redux/admin-data/admin-data.selectors';
import { setMediaText } from '../../redux/admin-data/admin-data.actions';


const TextEditor = ({ articleText, setMediaText,onChange}) => {
    let contentState = stateFromHTML(articleText);
    const [convertedContent, setConvertedContent] = useState('')
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    useEffect(() => {
        if (articleText) {
            setEditorState(() => EditorState.createWithContent(contentState),)
        }
    }, [articleText])
    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }
    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    }
    const handleTextSubmit = () => {
        onChange(convertedContent);
        // set media text 
    }
    return (
        <>
            {articleText && (
                <>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={handleEditorChange}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                    />
                    <div className="text-editor-button-container">
                        <button className='red-button-2 marginTop20' onClick={() => setMediaText(null)}>Retour</button>
                        <button className='dark-grey-button-1 marginTop20' onClick={handleTextSubmit}>Valider le texte</button>
                    </div>
                </>
            )}

        </>
    )
}
const mapStateToProps = createStructuredSelector({
    articleText: selectMediaText
})
const mapDispatchToProps = dispatch => ({
    setMediaText: (text,id) => dispatch(setMediaText(text, id))
})
export default connect(mapStateToProps, mapDispatchToProps)(TextEditor)