import React, { useState } from "react";
import { connect } from "react-redux";

import { NavLink } from "react-router-dom";

import "./header.component.styles.scss";

// Dependencies
import logo from "../../assets/logo.png";
import "../../js/utils";
import { toggleNavbar } from "../../js/utils";

// Language
import { FR, EN } from "../../js/languagesData";
import { createStructuredSelector } from "reselect";
import { selectCurrentLanguage } from "../../redux/language/language.selectors";

const Header = ({ handleClick, currentLanguage }) => {
  const languageData = currentLanguage === "english" ? EN : FR;

  const [isSubUlHidden, setIsSubUlHidden] = useState(true);
  return (
    <div className="header">
      <div className="header-container">
        <img className="header-logo" src={logo} alt="AIDSEA Logo" />
        <ul className="header-ul">
          <li>
            <div className="li-container">
              <NavLink exact to="/" activeClassName="li-active" href="/">
                {languageData.header.homepage}
              </NavLink>
            </div>
          </li>
          <li>
            <div className="li-container">
              <NavLink to="/about" activeClassName="li-active">
                {languageData.header.about}
              </NavLink>
            </div>
          </li>
          <li>
            <div className="li-container">
              <NavLink to="/official-documents" activeClassName="li-active">
                {languageData.header.officialDocuments}
              </NavLink>
            </div>
          </li>
          <li>
            <div className="li-container">
              <NavLink to="/become-partner" activeClassName="li-active">
                {languageData.header.becomePartner}
              </NavLink>
            </div>
          </li>

          <li>
            <div className="li-container">
              <NavLink to="/media" activeClassName="li-active">
                {languageData.header.news}
              </NavLink>
            </div>
          </li>
          <li>
            <div
              className="li-container"
              onMouseOver={() => setIsSubUlHidden(false)}
              onMouseOut={() => setIsSubUlHidden(true)}
            >
              <p> {languageData.header.join}</p>
            </div>
            {/* Sub menu  */}
            <div
              onMouseOver={() => setIsSubUlHidden(false)}
              onMouseOut={() => setIsSubUlHidden(true)}
              className={`header-join-sub-menu ${
                isSubUlHidden ? "" : "show-opacity"
              }`}
            >
              <ul id="header-join-sub-ul">
                <NavLink activeClassName="li-active" to="/join/member">
                  <li> {languageData.header.joinMember}</li>
                </NavLink>
                <NavLink activeClassName="li-active" to="/join/honorary-member">
                  <li> {languageData.header.joinHonoraryMember}</li>
                </NavLink>
                <NavLink activeClassName="li-active" to="/join/benefactor">
                  <li> {languageData.header.joinBenefactor}</li>
                </NavLink>
                <NavLink activeClassName="li-active" to="/join/volunteer">
                  <li> {languageData.header.joinVolunteer}</li>
                </NavLink>
              </ul>
            </div>
          </li>

          <li>
            <div id="header-donation-ul" className="li-container">
              <NavLink to="/donation" activeClassName="li-active">
                {languageData.header.donate}
              </NavLink>
            </div>
          </li>
        </ul>
        <NavLink to="/donation" className="header-donation-button">
          <p>{languageData.header.donate}</p>
        </NavLink>
        <div
          onClick={() => {
            toggleNavbar();
            handleClick();
          }}
          id="navbar-toggle-button"
          className="navbar-toggle-button"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  currentLanguage: selectCurrentLanguage,
});

export default connect(mapStateToProps)(Header);
