
import React, { useState, useEffect } from 'react'
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux'

import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';

import { stateFromHTML } from 'draft-js-import-html';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


import { selectMediaText } from '../../redux/admin-data/admin-data.selectors';
import { setMediaText } from '../../redux/admin-data/admin-data.actions';

import './text-editor-2.component.styles.scss';


const TextEditor2 = ({ articleText, setMediaText, handleTextChange }) => {
    const [convertedContent, setConvertedContent] = useState('')
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );


    const handleEditorChange =  (state) => {
        setEditorState(state);
         convertContentToHTML();
    }

    const convertContentToHTML = async () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);

        
    }
    useEffect(() => {
        handleTextChange(convertedContent)
    }, [convertedContent])
   

    return (
  <>
<Editor
editorState={editorState}
onEditorStateChange={handleEditorChange}
toolbarClassName="toolbarClassName"
wrapperClassName="wrapperTextEditor"
editorClassName="editorClassName"
                    />
                </>
    )
}
const mapStateToProps = createStructuredSelector({
    articleText: selectMediaText
})
const mapDispatchToProps = dispatch => ({
    setMediaText: (text,id) => dispatch(setMediaText(text, id))
})
export default connect(mapStateToProps, mapDispatchToProps)(TextEditor2)