import CardTextActionTypes from './card-text.types';

// ------ ACTIONS ----- //
export const setCardIsVisible = (isVisible) => ({
  type: CardTextActionTypes.SET_IS_VISIBLE,
  payload: isVisible,
});
export const setCardTitle = (title) => ({
  type: CardTextActionTypes.SET_CARD_TITLE,
  payload: title,
});
export const setCardText = (text) => ({
  type: CardTextActionTypes.SET_CARD_TEXT,
  payload: text,
});
