import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FR, EN } from '../../js/languagesData';

import { selectCurrentLanguage } from '../../redux/language/language.selectors';

import './about.page.styles.scss';

// Components
import AboutCarousel from '../../components/about-carousel/about-carousel.component';
import { createStructuredSelector } from 'reselect';

const AboutPage = ({ currentLanguage }) => {
  const languageData = currentLanguage === 'english' ? EN : FR;
  const [isSmartphone, setIsSmartphone] = useState(false);
  const setScreen = () => {
    if (window.screen.width <= 400) {
      setIsSmartphone(true);
    } else {
      setIsSmartphone(false);
    }
  };
  useEffect(() => {
    setScreen();
  }, []);
  window.onresize = () => {
    setScreen();
  };
  return (
    <div className='about-page'>
      {/* Hero  */}
      <div className='about-image-container'>
        <div className='about-image-filter' />
        <div className='about-text-container'>
          <div className='about-divider'></div>
          <h1> {languageData.aboutPage.title}</h1>
          <div className='about-divider'>
            <p className='about-divider-text'>
              {languageData.aboutPage.subTitle1}
            </p>
          </div>
          {isSmartphone ? (
            <p className='about-sub-text align-justifyLeftOnPhones'>
              {languageData.aboutPage.description10} <br />
              {languageData.aboutPage.description12} <br />{' '}
              {languageData.aboutPage.description13}
            </p>
          ) : (
            <p className='about-sub-text align-justifyLeftOnPhones'>
              {languageData.aboutPage.description10} <br />
              {languageData.aboutPage.description11}
            </p>
          )}
        </div>
      </div>
      {/* Page Content */}

      <div className='about-page-content'>
        <div className='about-page-content-container'>
          <div className='about-page-header-content'>
            <p className='about-page-header-title'>
              {languageData.aboutPage.subTitle1}{' '}
            </p>
            <p className='about-page-header-text align-justify'>
              {languageData.aboutPage.description2}
            </p>
          </div>
          {/* Carousel */}
          <div className='about-page-carousel-container'>
            <AboutCarousel />
          </div>
          {/* Funds Section */}
          <div className='about-page-funds-section'>
            <div className='about-page-funds-container'>
              <p className='about-page-header-title'>
                {languageData.aboutPage.subTitle1}{' '}
              </p>
              <div className='about-page-funds-sub-title'>
                <p>{languageData.aboutPage.fundsTitle} </p>
              </div>
              <div className='about-carousel-text-ul align-justify'>
                <ul>
                  <li>{languageData.aboutPage.fundsText1}</li>
                  <li>{languageData.aboutPage.fundsText2}</li>
                  <li>{languageData.aboutPage.fundsText3}</li>
                  <li>{languageData.aboutPage.fundsText4}</li>
                  <li>{languageData.aboutPage.fundsText5}</li>
                  <li>{languageData.aboutPage.fundsText6}</li>
                  <li>{languageData.aboutPage.fundsText7}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  currentLanguage: selectCurrentLanguage,
});

export default connect(mapStateToProps)(AboutPage);
