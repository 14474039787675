import React, { useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import './aidsea-media.page.styles.scss';

// Components
import HeroTop from '../../components/hero-top/hero-top.component';
import YoutubeVideoContainer from '../../components/youtube-video-container/youtube-video-container.component';
import Searchbox from '../../components/searchbox/searchbox.component';
import ActualityThumbnail from '../../components/actuality-thumbnail/actuality-thumbnail.component';

// Images
import heroAboutImg from '../../assets/images/hero-about.png';

// Import articles
import { selectMedia } from '../../redux/admin-data/admin-data.selectors';
import { fetchMediaStartAsync } from '../../redux/admin-data/admin-data.actions';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';

import { FR, EN } from '../../js/languagesData';

const AidseaMediaPage = ({ fetchArticles, articles, currentLanguage }) => {
  const languageData = currentLanguage === 'english' ? EN : FR;
  const [searchField, setSearchField] = useState('');
  useEffect(() => {
    fetchArticles();
  }, []);

  const filteredArticles = articles.filter((article) => {
    return (
      article.title.toLowerCase().includes(searchField.toLowerCase()) ||
      article.author.toLowerCase().includes(searchField.toLowerCase()) ||
      article.description.toLowerCase().includes(searchField.toLowerCase()) ||
      article.date.toLowerCase().includes(searchField.toLowerCase())
    );
  });

  return (
    <div className='container-display-flex-col-align-center paddingTop74'>
      <HeroTop title={languageData.mediaPage.title} img={heroAboutImg} />
      <div className='basic-container-90 paddingTop20 marginTop30'>
        <YoutubeVideoContainer src='https://www.youtube.com/embed/KPBO08ROkJ4' />
        {/* SearchBox */}
        <div className='basic-container-100 marginTop40'>
          <Searchbox
            value={searchField}
            placeholder={languageData.mediaPage.searchbox}
            onChange={(e) => setSearchField(e.target.value)}
          />
          {/* Thumbnails container */}
          <div className='container-display-flex-row flex-wrap justify-content-center marginTop40 width100'>
            {searchField === ''
              ? articles.map((article, idx) =>
                  idx <= 3 ? (
                    <ActualityThumbnail key={idx} data={article} />
                  ) : (
                    ''
                  )
                )
              : filteredArticles.map((article, idx) => (
                  <ActualityThumbnail key={idx} data={article} />
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  articles: selectMedia,
  currentLanguage: selectCurrentLanguage,
});
const mapDispatchToProps = (dispatch) => ({
  fetchArticles: () => dispatch(fetchMediaStartAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AidseaMediaPage);
