import { createSelector } from 'reselect';
import { formatDateFromInt } from '../../js/utils';

const selectAdminData = state => state.adminData;

// Partners
export const selectPartners = createSelector(
  [selectAdminData],
  adminData => adminData.partners
);

// Members
export const selectMembers = createSelector(
  [selectAdminData],
  adminData => adminData.members
);
export const selectSimpleMembers = createSelector([selectMembers], members =>
  members.filter(member => member.memberType === 'membre')
);
export const selectHonoraryMembers = createSelector([selectMembers], members =>
  members.filter(member => member.memberType === "membre d'honneur")
);
export const selectBenefactorMembers = createSelector(
  [selectMembers],
  members =>
    members.filter(member => member.memberType === 'membre bienfaiteur')
);
export const selectVolunteerMembers = createSelector([selectMembers], members =>
  members.filter(member => member.memberType === 'bénévole')
);

// Expenses
export const selectExpenses = createSelector(
  [selectAdminData],
  adminData => adminData.expenses
);
export const selectTotalExpenseAmount = createSelector(
  [selectExpenses],
  expenses =>
    expenses &&
    expenses.reduce((acc, expense) => acc + parseInt(expense.amount), 0)
);
export const selectFilteredExpenses = createSelector(
  [selectExpenses],
  expense =>
    expense.map(expense => ({
      amount: expense.amount,
      date: expense.date,
    }))
);

// Donations
export const selectDonations = createSelector(
  [selectAdminData],
  adminData => adminData.donations
);
export const selectTotalDonationAmount = createSelector(
  [selectDonations],
  donations =>
    donations &&
    donations.reduce((acc, donation) => acc + parseInt(donation.amount), 0)
);
export const selectDonationsAndExpenses = createSelector(
  [selectAdminData],
  adminData => {
    let data = [];
    adminData.donations.map(d => data.push(d));
    adminData.expenses.map(d => data.push(d));
    return data;
  }
);

// Media
export const selectMedia = createSelector(
  [selectAdminData],
  adminData => adminData.media
);

export const selectFilteredDonations = createSelector(
  [selectDonations],
  donations =>
    donations.map(donation => ({
      amount: donation.amount,
      date: donation.date,
      country: donation.country,
      email: donation.email,
      paymentMethod: donation.paymentMethod,
      donationType: donation.donationType,
      activity: donation.activity,
      lastname: donation.lastname,
      phone: donation.phone,
    }))
);
export const selectMediaText = createSelector(
  [selectAdminData],
  adminData => adminData.articleText
);
export const selectMediaId = createSelector(
  [selectAdminData],
  adminData => adminData.id
);
// ADMINS

export const selectAdmins = createSelector(
  [selectAdminData],
  adminData => adminData.admins
);
export const selectIsUserUpdateVisible = createSelector(
  [selectAdminData],
  adminData => adminData.isUserUpdateVisible
);
export const selectUserUpdateProfil = createSelector(
  [selectAdminData],
  adminData => adminData.userUpdateProfil
);
export const selectFormatedDonationsForGraph = createSelector(
  [selectFilteredDonations],
  donations => donations
);
