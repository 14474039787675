import React from 'react';
import { connect } from 'react-redux';

import Table from 'react-bootstrap/Table';
import { createStructuredSelector } from 'reselect';
import { formatDateWithMonthName } from '../../js/utils';

import {
  deleteDonationStartAsync,
  deleteExpenseStartAsync,
} from '../../redux/admin-data/admin-data.actions';

const Table7 = ({
  data,
  searchFieldVal = '',
  deleteDonation,
  deleteExpense,
}) => {
  const newData = data.reverse();
  const filteredData = newData.filter((donation) => {
    const sf = searchFieldVal.toLowerCase();
    let {
      date = '',
      lastname = '',
      email = '',
      address = '',
      amount = '',
      donationType = 'Partenariat',
      paymentMethod = '',
      donationCurrency = '',
      country = '',
      id,
    } = donation;
    let firstname = donation.firstname || '';
    return (
      date.toLowerCase().includes(sf) ||
      lastname.toLowerCase().includes(sf) ||
      firstname.toLowerCase().includes(sf) ||
      email.toLowerCase().includes(sf) ||
      address.toLowerCase().includes(sf) ||
      amount === searchFieldVal ||
      donationType.toLowerCase().includes(sf) ||
      paymentMethod.toLowerCase().includes(sf) ||
      donationCurrency.toLowerCase().includes(sf) ||
      country.toLowerCase().includes(sf) ||
      id.toLowerCase().includes(sf)
    );
  });
  return (
    <Table striped bordered hover responsive className='admin-table'>
      <thead>
        <tr>
          <th>Date</th>
          <th>Noms</th>
          <th>Montant</th>
          <th>Type de dépense</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {searchFieldVal === ''
          ? newData.map(
              (
                {
                  date,
                  lastname,
                  firstname = '',
                  amount,
                  typeOfExpense = '',
                  id,
                },
                idx
              ) => (
                <tr key={idx}>
                  <td>{date}</td>
                  <td>
                    {lastname} {firstname}
                  </td>
                  <td>{amount}</td>
                  <td>{typeOfExpense}</td>

                  <td>
                    <button
                      className='red-button-1'
                      onClick={() => {
                        const isConfirmed = window.confirm(
                          'Voulez vous supprimer la dépense ?'
                        );
                        if (isConfirmed) {
                          deleteExpense(id);
                        }
                      }}
                    >
                      Supprimer
                    </button>
                  </td>
                </tr>
              )
            )
          : filteredData.map(
              (
                {
                  date,
                  lastname,
                  firstname = '',
                  amount,
                  typeOfExpense = '',
                  id,
                },
                idx
              ) => (
                <tr key={idx}>
                  <td>{date}</td>
                  <td>
                    {lastname} {firstname}
                  </td>
                  <td>{amount}</td>
                  <td>{typeOfExpense}</td>

                  <td>
                    <button
                      className='red-button-1'
                      onClick={() => {
                        const isConfirmed = window.confirm(
                          'Voulez vous supprimer la dépense ? '
                        );
                        if (isConfirmed) {
                          deleteExpense(id);
                        }
                      }}
                    >
                      Supprimer
                    </button>
                  </td>
                </tr>
              )
            )}
      </tbody>
    </Table>
  );
};
const mapStateToProps = createStructuredSelector({
  //
});
const mapDispatchToProps = (dispatch) => ({
  deleteDonation: (donationId) =>
    dispatch(deleteDonationStartAsync(donationId)),
  deleteExpense: (expenseId) => dispatch(deleteExpenseStartAsync(expenseId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Table7);
