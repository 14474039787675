export const setIsLoaderLoading = (isLoading) => ({
  type: 'SET_IS_LOADER_LOADING',
  payload: isLoading,
});

export const setIsLoaderVisible = (isLoaderVisible) => ({
  type: 'SET_IS_LOADER_VISIBLE',
  payload: isLoaderVisible,
});

export const setTextInsideLoader = (text) => ({
  type: 'SET_TEXT_INSIDE_LOADER',
  payload: text,
});

export const setIsLoaderSuccess = (isLoaderSuccess) => ({
  type: 'SET_IS_LOADER_SUCCESS',
  payload: isLoaderSuccess,
});

export const resetLoaderParams = () => ({
  type: 'RESET_LOADER_PARAMS',
});
export const setIsLoaderClosing = (isClosing) => ({
  type: 'SET_LOADER_IS_CLOSING',
  payload: isClosing,
});
export const setIsLoaderReloading = isReloading => ({
  type: 'SET_LOADER_IS_RELOADING', 
  payload: isReloading
})