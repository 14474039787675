import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import "./state-card.component.styles.scss";

// Redux actions
import {
  setIsLoaderVisible,
  resetLoaderParams,
} from "../../redux/loader/loader.actions";

const StateCard = ({
  text,
  setIsLoaderVisible,
  isLoaderLoading,
  isLoaderVisible,
  isLoaderSuccess,
  resetLoaderParams,
  isLoaderClosing,
  isLoaderReloading,
}) => {
  let history = useHistory();

  const handleClick = () => {
    // DISPATCH IS VISIBLE TO FALSE
    resetLoaderParams();

    // Then redirect if is success prop is true
    isLoaderSuccess && history.push("/");
  };

  return (
    <div className="state-card-container">
      <div className="state-card basic-container-100">
        <h3 className="header-small-title marginBottom30">A.I.D.S.E.A.</h3>
        {isLoaderLoading && (
          <div className="">
            <div className="loader"></div>
            <br />
          </div>
        )}{" "}
        {!isLoaderLoading && !isLoaderClosing && !isLoaderReloading ? (
          <div style={{ textAlign: "center" }}>
            <p>{text}</p>
            <button
              onClick={handleClick}
              className="green-button-1 marginTop30"
            >
              {isLoaderSuccess ? "Accueil" : "Corriger"}
            </button>
          </div>
        ) : (
          ""
        )}
        {isLoaderClosing && !isLoaderLoading && !isLoaderReloading ? (
          <div style={{ textAlign: "center" }}>
            <p>{text}</p>
            <button
              onClick={() => {
                resetLoaderParams();
                setIsLoaderVisible(false);
              }}
              className="green-button-1 marginTop30"
            >
              Retour
            </button>
          </div>
        ) : (
          ""
        )}
        {isLoaderReloading && (
          <div style={{ textAlign: "center" }}>
            <p>{text}</p>
            <button
              onClick={() => {
                resetLoaderParams();
                setIsLoaderVisible(false);
                window.location.reload();
              }}
              className="green-button-1 marginTop30"
            >
              Retour
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = ({ loader }) => ({
  isLoaderVisible: loader.isLoaderVisibile,
  isLoaderLoading: loader.isLoaderLoading,
  isLoaderSuccess: loader.isLoaderSuccess,
  isLoaderClosing: loader.isClosing,
  text: loader.text,
  isLoaderReloading: loader.isReloading,
});
const mapDispatchToProps = dispatch => ({
  setIsLoaderVisible: isVisible => dispatch(setIsLoaderVisible(isVisible)),
  resetLoaderParams: () => dispatch(resetLoaderParams()),
});
export default connect(mapStateToProps, mapDispatchToProps)(StateCard);
