import React, { useState } from 'react';

// Styles
import './admin-login.page.styles.scss';
import logo from '../../assets/logo-big.png';

// Dependencies
import Form from 'react-bootstrap/Form';
import Feedback from 'react-bootstrap/esm/Feedback';

// Components
import StateCard from '../../components/state-card/state-card.component';

// Redux
import { adminLoginStartAsync } from '../../redux/admin/admin.actions';
import { connect } from 'react-redux';

const AdminLoginPage = ({ adminLoginStartAsync, isLoaderVisible }) => {
  const [validated, setValidated] = useState(false);
  const [form, setForm] = useState({
    email: '',
    password: '',
  });
  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setForm({ ...form, [name]: value });
  };
  const handleSubmit = (e) => {
    setValidated(true);
    e.preventDefault();
    const formJsx = e.currentTarget;
    if (formJsx.checkValidity() === false) return e.stopPropagation();
    // Handle logic password and login func with redux thunk
    adminLoginStartAsync(form);
  };

  return (
    <div className='admin-login-page'>
      {isLoaderVisible && <StateCard />}
      <div className='admin-login-container'>
        {/* // LOGO  */}
        <img src={logo} alt='logo' />

        <Form
          method='POST'
          className='admin-login-form'
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type='email'
              name='email'
              defaultValue={form.email}
              onChange={handleChange}
              required
            />
            <Feedback type='invalid'>
              Veuillez entrer une adresse email valide
            </Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Mot de passe</Form.Label>
            <Form.Control
              type='password'
              name='password'
              defaultValue={form.password}
              onChange={handleChange}
              required
            />
            <Feedback type='invalid'>
              Veuillez entrer votre mot de passe
            </Feedback>
          </Form.Group>
          <button className='green-button-1'>Connexion</button>
        </Form>
      </div>
    </div>
  );
};
const mapStateToProps = ({ loader }) => ({
  isLoaderVisible: loader.isLoaderVisible,
});
const mapDispatchToProps = (dispatch) => ({
  adminLoginStartAsync: (email, password) =>
    dispatch(adminLoginStartAsync(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminLoginPage);
