const INITIAL_STATE = {
  isLoaderLoading: true,
  isLoaderVisible: false,
  isLoaderSuccess: false,
  isClosing: false,
  text: '',
  isReloading: false,
};

const loaderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_IS_LOADER_LOADING':
      return {
        ...state,
        isLoaderLoading: action.payload,
      };

    case 'SET_IS_LOADER_VISIBLE':
      return {
        ...state,
        isLoaderVisible: action.payload,
      };
    case 'SET_TEXT_INSIDE_LOADER':
      return {
        ...state,
        text: action.payload,
      };
    case 'SET_IS_LOADER_SUCCESS':
      return {
        ...state,
        isLoaderSuccess: action.payload,
      };
    case 'SET_LOADER_IS_RELOADING':
      return {
        ...state,
        isReloading: action.payload,
      };
    case 'RESET_LOADER_PARAMS':
      return {
        ...state,
        isLoaderLoading: true,
        isLoaderVisible: false,
        isLoaderSuccess: false,
        isClosing: false,
        text: '',
      };
    case 'SET_LOADER_IS_CLOSING':
      return {
        ...state,
        isClosing: action.payload,
      };
    default:
      return state;
  }
};

export default loaderReducer;
