import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FR, EN } from '../../js/languagesData';

import { NavLink } from 'react-router-dom';

import { toggleNavOnClick } from '../../js/utils';
import './navbar.menu.component.styles.scss';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';

const NavbarMenu = ({ currentLanguage }) => {
  const languageData = currentLanguage === 'english' ? EN : FR;
  const [subUlIsHidden, setSubUlIsHidden] = useState(true);
  useEffect(() => {
    toggleNavOnClick();
  }, []);

  return (
    <div id='menu-section'>
      <div className={`menu-container ${!subUlIsHidden ? 'extend' : ''}`}>
        <div className='menu-selection'>
          <ul>
            <NavLink activeClassName='li-active-bg' exact to='/'>
              <li>{languageData.header.homepage}</li>
            </NavLink>

            <NavLink activeClassName='li-active-bg' to='/about'>
              <li>{languageData.header.about}</li>
            </NavLink>
            <NavLink activeClassName='li-active-bg' to='/official-documents'>
              <li>{languageData.header.officialDocuments}</li>
            </NavLink>
            <NavLink activeClassName='li-active-bg' to='/become-partner'>
              <li>{languageData.header.becomePartner}</li>
            </NavLink>
            <NavLink activeClassName='li-active-bg' to='/media'>
              <li>{languageData.header.news}</li>
            </NavLink>

            <li
              onClick={() => setSubUlIsHidden(!subUlIsHidden)}
              id='nav-sub-toggle'
            >
              <span id='nav-sub-toggle'>{languageData.header.join}</span>
            </li>
            <ul
              id='menu-sub-ul'
              className={!subUlIsHidden ? 'show-sub-ul' : ''}
            >
              <NavLink activeClassName='li-active-bg' to='/join/member'>
                <li>{languageData.header.joinMember}</li>
              </NavLink>
              <NavLink
                activeClassName='li-active-bg'
                to='/join/honorary-member'
              >
                <li>{languageData.header.joinHonoraryMember}</li>
              </NavLink>
              <NavLink activeClassName='li-active-bg' to='/join/benefactor'>
                <li>{languageData.header.joinBenefactor}</li>
              </NavLink>
              <NavLink activeClassName='li-active-bg' to='/join/volunteer'>
                <li>{languageData.header.joinVolunteer}</li>
              </NavLink>
            </ul>
          </ul>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  currentLanguage: selectCurrentLanguage,
});

export default connect(mapStateToProps)(NavbarMenu);
