import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';

import './official-documents.page.styles.scss';

// Components
import OfficialDocumentsCardContainer from '../../components/official-documents-card/official-documents-card.component';
import HeroTop from '../../components/hero-top/hero-top.component';

// Icons
import officialDocImg1 from '../../assets/icons/official-doc-img-1.png';
import officialDocImg2 from '../../assets/icons/official-doc-img-2.png';
import officialDocImg3 from '../../assets/icons/official-doc-img-3.png';

// Images
import heroAbout from '../../assets/images/hero-about.png';

// Official docs
import journalOfficielPDF from '../../assets/official-docs/JOURNAL_OFFICIEL.pdf';
import recepissePDF from '../../assets/official-docs/recepisse.pdf';
import siretPDF from '../../assets/official-docs/siret.pdf';

// Links docs
import statutsPDF from '../../assets/official-docs/statuts.pdf';
import reglementInterieurPDF from '../../assets/official-docs/reglement_interieur.pdf';

import { FR, EN } from '../../js/languagesData';

const OfficialDocumentsPage = ({ currentLanguage }) => {
  const languageData = currentLanguage === 'english' ? EN : FR;

  return (
    <div className='official-documents-page'>
      {/* Hero  */}
      <HeroTop
        title={languageData.officialDocuments.title}
        img={heroAbout}
        isDocumentsPage={true}
      />
      {/* Content */}
      <div className='official-documents-content'>
        <div className='official-documents-cards-container'>
          <OfficialDocumentsCardContainer
            title={languageData.officialDocuments.container1.title}
            description={languageData.officialDocuments.container1.description}
            description2={languageData.officialDocuments.container1.title}
            img={officialDocImg1}
            link={journalOfficielPDF}
          />
          <OfficialDocumentsCardContainer
            title={languageData.officialDocuments.container2.title}
            description={languageData.officialDocuments.container2.description}
            description2={
              languageData.officialDocuments.container2.description2
            }
            img={officialDocImg2}
            link={recepissePDF}
          />
          <OfficialDocumentsCardContainer
            title={languageData.officialDocuments.container3.title}
            description={languageData.officialDocuments.container3.description}
            description2={
              languageData.officialDocuments.container3.description2
            }
            img={officialDocImg3}
            link={siretPDF}
          />
        </div>
        {/*  Links section  */}
        <div className='official-documents-links-container'>
          <div className='official-documents-links-1-container'>
            <h3>{languageData.officialDocuments.links.title1}</h3>
            <a
              href={statutsPDF}
              target='_blank'
              rel='noreferrer'
              alt={languageData.officialDocuments.links.link1}
            >
              {languageData.officialDocuments.links.link1}
            </a>
            <a
              href={reglementInterieurPDF}
              target='_blank'
              rel='noreferrer'
              alt={languageData.officialDocuments.links.link2}
            >
              {languageData.officialDocuments.links.link2}
            </a>
          </div>
          <div className='official-documents-links-2-container'>
            <h3>{languageData.officialDocuments.links.title2} </h3>
            <a
              href='https://www.journal-officiel.gouv.fr/associations/detail-annonce/associations_b/20200023/1111'
              target='_blank'
              rel='noreferrer'
              alt={languageData.officialDocuments.links.link3}
            >
              {languageData.officialDocuments.links.link3}
            </a>
            <a
              href='https://www.societe.com/societe/association-pour-l-industrialisation-et-le-developpement-social-et-economique-de-l-afrique-a-i-d-s-e-a-884063660.html'
              target='_blank'
              rel='noreferrer'
            >
              {languageData.officialDocuments.links.link4}
            </a>
            <a
              href='https://www.maam.fr/annuaire/association/association-pour-lindustrialisation-et-le-developpement-social-et-economique-de-lafrique-aidsea--4779'
              target='_blank'
              rel='noreferrer'
            >
              {languageData.officialDocuments.links.link5}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  currentLanguage: selectCurrentLanguage,
});

export default connect(mapStateToProps)(OfficialDocumentsPage);
