import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'


import './form-and-image.component.styles.scss';

// Components
import BootstrapForm from '../bootstrap-form/bootstrap-form.component';
import JoinForm from '../join-form/join-form.component';
import DonationForm from '../donation-form/donation-form.component';
import { createStructuredSelector } from 'reselect';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';

import {FR, EN} from '../../js/languagesData';

const FormAndImage = ({ title, img, page, isDonation, currentLanguage }) => {
  const languageData = currentLanguage === 'english' ? EN : FR;

  const [isTabletOrSmaller, setIsTabletOrSmaller] = useState(false);
  const setScreen = () => {
    if (window.screen.width <= 989) {
      setIsTabletOrSmaller(true);
    } else {
      setIsTabletOrSmaller(false);
    }
  };
  useEffect(() => {
    setScreen();
  }, []);
  window.onresize = () => {
    setScreen();
  };

  if (isDonation) {
    return (
      <div className='form-and-image-container margin0Center'>
        <div className='form-and-image-form-container'>
          <div className={`form-and-image-form`}>
            <DonationForm />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className='form-and-image-container margin0Center'>
        {isTabletOrSmaller ? (
          <div
            style={{ backgroundImage: `url(${img})` }}
            className='form-and-image-image'
            alt='Partenaire'
          >
            <marquee>
              {languageData.general.industrializeAfrica}
            </marquee>
          </div>
        ) : (
          ''
        )}

        <div className='form-and-image-form-container'>
          <div className={`form-and-image-form`}>
            <h2>{languageData.form.fillInForm}</h2>
            {page === 'join' ? <JoinForm title={title} /> : <BootstrapForm />}
          </div>
        </div>
      </div>
    );
  }
};
const mapStateToProps = createStructuredSelector({
  currentLanguage: selectCurrentLanguage
})

export default connect(mapStateToProps)(FormAndImage);
