import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

// redux
import { createStructuredSelector } from "reselect";
import { fetchCategoryStartAsync } from "../../redux/admin-data/admin-data.actions";

import { setIsLoaderVisible } from "../../redux/loader/loader.actions";

// Loader & Card
import { selectIsLoaderVisible } from "../../redux/loader/loader.selectors";
import { selectCardIsVisible } from "../../redux/card-text/card-text.selectors";
import StateCard from "../../components/state-card/state-card.component";
import CardWithText from "../../components/card-text/card-text.component";
import {
  selectDonations,
  selectExpenses,
  selectDonationsAndExpenses,
} from "../../redux/admin-data/admin-data.selectors";

// Dependencies
import TextField from "@material-ui/core/TextField";
import Table6 from "../../components/table-6/table-6.component";
import Table7 from "../../components/table-7/table-7.component";
import MaterialTable1 from "../../components/material-table-1/material-table-1.component";

import AddAccountingOperationComponent from "../../components/add-accounting-operation/add-accounting-operation.component";
import AddDonationForm from "../../components/add-donation-form/add-donation-form.component";

// import Table

import "./accounting.page.styles.scss";

const AccountingPage = ({
  isLoaderVisible,
  isCardTextVisible,
  donations,
  expenses,
  fetchCategory,
  setIsLoaderVisible,
  donationsAndExpenses,
}) => {
  let { id } = useParams();
  const [isAddDonationVisible, setIsAddDonationVisible] = useState(false);
  const [isAddOperationVisible, setIsAddOperationVisible] = useState(false);
  const fetchData = async () => {
    await fetchCategory("donations");
    await fetchCategory("expenses");
    setIsLoaderVisible(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleAddAccountingOperationClick = id => {
    if (id === "incomes") {
      setIsAddDonationVisible(!isAddDonationVisible);
    } else if (id === "expenses") {
      setIsAddOperationVisible(!isAddOperationVisible);
    } else {
      setIsAddOperationVisible(!isAddOperationVisible);
    }
  };
  let title;
  switch (id) {
    case "incomes":
      title = "Entrées";
      break;
    case "expenses":
      title = "Sorties";
      break;
    case "balance":
      title = "Solde";
      break;
    default:
      return (title = "");
  }

  return (
    <div className="accounting-page">
      <h3>{title}</h3>
      {isLoaderVisible && <StateCard />}
      {isCardTextVisible && <CardWithText />}
      {id === "balance" ? (
        ""
      ) : (
        <button
          className="green-button-1 marginTop30 marginBottom30"
          onClick={() => handleAddAccountingOperationClick(id)}
        >
          {id === "incomes" && "Ajouter une entrée"}
          {id === "expenses" && "Ajouter une sortie"}
        </button>
      )}

      <div className="accounting-page-button-container">
        {isAddOperationVisible && <AddAccountingOperationComponent />}
        {isAddDonationVisible && <AddDonationForm />}
      </div>

      {id === "incomes" && (
        <MaterialTable1
          data={donations}
          title="Entrées"
          typeOfTable="donations"
        />
      )}
      {id === "expenses" && (
        <MaterialTable1
          data={expenses}
          title="Sorties"
          typeOfTable="expenses"
        />
      )}
      {id === "balance" && (
        <MaterialTable1 data={donationsAndExpenses} title="Solde" />
      )}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  isLoaderVisible: selectIsLoaderVisible,
  isCardTextVisible: selectCardIsVisible,
  donations: selectDonations,
  expenses: selectExpenses,
  donationsAndExpenses: selectDonationsAndExpenses,
});
const mapDispatchToProps = dispatch => ({
  fetchCategory: cat => dispatch(fetchCategoryStartAsync(cat)),
  setIsLoaderVisible: isVisible => dispatch(setIsLoaderVisible(isVisible)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountingPage);
