import AdminActionTypes from "./admin.types";

const INITIAL_STATE = {
  currentAdmin: null,
  typeOfAdmin: undefined,
  error: "",
  projects: [],
};

const AdminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        currentAdmin: action.payload,
      };
    case AdminActionTypes.ADMIN_LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case AdminActionTypes.SET_ADMIN_TYPE:
      return {
        ...state,
        typeOfAdmin: action.payload,
      };
    case AdminActionTypes.ADMIN_LOGOUT_START:
      return {
        ...state,
        currentAdmin: null,
        typeOfAdmin: undefined,
      };
    case AdminActionTypes.FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      };
    default:
      return state;
  }
};
export default AdminReducer;
