import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";

import {
  fetchProjectsStartAsync,
  createProjectStartAsync,
  deleteProjectStartAsync,
} from "../../redux/admin/admin.actions";
import { selectProjects } from "../../redux/admin/admin.selectors";
import { createStructuredSelector } from "reselect";

const TableDonationTypes = ({
  fetchProjects,
  projects,
  createProject,
  deleteProject,
}) => {
  const [columns, setColumns] = useState([
    { title: "Type de don", field: "donationType" },
  ]);

  const [data, setData] = useState([{ donationType: "Don ordinaire" }]);

  useEffect(() => {
    fetchProjects();
  }, []);
  useEffect(() => {
    const formatedData = [];
    projects.map(project => formatedData.push({ donationType: project }));
    setData(formatedData);
  }, [projects]);

  return (
    <MaterialTable
      title="Types de don"
      columns={columns}
      data={data}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              setData([...data, newData]);
              createProject(newData.donationType);
              resolve();
            }, 1000);
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            setTimeout(async () => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              await deleteProject(dataDelete[index].donationType);
              dataDelete.splice(index, 1);
              setData([...dataDelete]);

              resolve();
            }, 1000);
          }),
      }}
    />
  );
};
const mapStateToProps = createStructuredSelector({
  projects: selectProjects,
});

const mapDispatchToProps = dispatch => ({
  fetchProjects: () => dispatch(fetchProjectsStartAsync()),
  createProject: donationType =>
    dispatch(createProjectStartAsync(donationType)),
  deleteProject: project => dispatch(deleteProjectStartAsync(project)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableDonationTypes);
