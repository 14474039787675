import React, { useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import firebase from '../../js/firebase';
import {
  addTemporaryDonationToFirestore,
  deleteTemporaryDonationFromFirestore,
} from '../../js/utils';

import { addDonationToken } from '../../redux/donations/donations.actions';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';
import { setIsLoaderVisible } from '../../redux/loader/loader.actions';

import { FR, EN } from '../../js/languagesData';

import './sycaForm.component.styles.scss';

const SycaForm = ({
  form,
  handleCorrect,
  addDonationToken,
  currentLanguage,
  setIsLoaderVisible,
  isPartner = false,
}) => {
  const languageData = currentLanguage === 'english' ? EN : FR;
  const { donationCurrency, donationAmount, firstname, lastname } = form;
  const [token, setToken] = useState('');

  const handleCorrectClick = (e) => {
    e.preventDefault();
    handleCorrect();
    deleteTemporaryDonationFromFirestore(token.data);
  };

  const getLoginTokenFromSycaPay = async () => {
    const sPay = firebase.functions().httpsCallable('sycapayDev');
    const res = await sPay({
      amount: donationAmount,
      currency: donationCurrency,
    });
    setToken(res);
    await addTemporaryDonationToFirestore(form, res.data, (isPartner = true));
  };

  useEffect(() => {
    setTimeout(async () => {
      await getLoginTokenFromSycaPay();
      setIsLoaderVisible(false);
    }, 100);
  }, []);

  useEffect(() => {
    token !== '' && addDonationToken(token?.data);
  }, [token]);

  if (token) {
    return (
      <form
        method='POST'
        action='https://secure.sycapay.com/checkresponsive'
        className='syca-form'
      >
        <h3>Confirmation:</h3>

        {!isPartner && (
          <label className='label-input'>
            {languageData.donationPage.form.input8}
            <input
              name='firstname'
              value={firstname}
              className='input-disabled'
              readOnly
            />
          </label>
        )}

        <label className='label-input'>
          {languageData.donationPage.form.input7}:
          <input
            name='lastname'
            value={lastname}
            className='input-disabled'
            readOnly
          />
        </label>

        <label className='label-input'>
          {languageData.donationPage.form.input4}:
          <input
            name='amount'
            value={donationAmount}
            className='input-disabled'
            readOnly
          />
        </label>

        <label className='label-input'>
          {languageData.donationPage.form.input403}:
          <input
            name='currency'
            value={donationCurrency}
            className='input-disabled'
            readOnly
          />
        </label>

        <input name='token' value={token?.data} hidden readOnly />

        <input
          name='urls'
          value='https://us-central1-aidsea-b19de.cloudfunctions.net/sycapaySuccess'
          readOnly
          hidden
        />
        <input
          name='urlc'
          value='https://us-central1-aidsea-b19de.cloudfunctions.net/sycapayFailure'
          readOnly
          hidden
        />
        <input name='commande' value={token?.data} hidden readOnly />
        <input name='merchandid' value='C_61A5BC5BD6307' hidden readOnly />
        <input name='typpaie' id='typpaie' value='payement' hidden readOnly />
        <input
          type='submit'
          value={languageData.partnerPage.form.validate}
          className='green-button-1'
        />

        <button
          value='valider'
          className='red-button-1'
          onClick={handleCorrectClick}
        >
          {languageData.partnerPage.form.correct}
        </button>
      </form>
    );
  } else {
    return '';
  }
};
const mapStateToProps = createStructuredSelector({
  currentLanguage: selectCurrentLanguage,
});
const mapDispatchToProps = (dispatch) => ({
  addDonationToken: (token) => dispatch(addDonationToken(token)),
  setIsLoaderVisible: (isVisible) => dispatch(setIsLoaderVisible(isVisible)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SycaForm);
