const INITIAL_STATE = {
  partners: [
    {
      id: 'contact@macenicolas.fr',
      logoUrl:
        'https://firebasestorage.googleapis.com/v0/b/aidsea-b19de.appspot.com/o/logos%2Fcontact%40macenicolas.fr%2Flogo-black.png?alt=media&token=f73b00b5-e54a-416b-abe4-16bc36a40c41',
      websiteUrl: 'macenicolas.fr',
    },
  ],
};

const partnersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PARTNERS':
      return {
        ...state,
        partners: action.payload,
      };

    default:
      return state;
  }
};

export default partnersReducer;
