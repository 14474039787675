import CardTextActionTypes from './card-text.types';

const INITIAL_STATE = {
  title: '',
  text: '',
  isVisible: false,
};

const CardTextReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CardTextActionTypes.SET_IS_VISIBLE:
      return {
        ...state,
        isVisible: action.payload,
      };
    case CardTextActionTypes.SET_CARD_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case CardTextActionTypes.SET_CARD_TEXT:
      return {
        ...state,
        text: action.payload,
      };
    default:
      return state;
  }
};
export default CardTextReducer;
