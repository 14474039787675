import { createSelector } from "reselect";

const selectAdmin = state => state.admin;

export const selectCurrentAdmin = createSelector(
  [selectAdmin],
  selectAdmin => selectAdmin.currentAdmin
);
export const selectTypeOfAdmin = createSelector(
  [selectAdmin],
  admin => admin.typeOfAdmin
);
export const selectProjects = createSelector(
  [selectAdmin],
  admin => admin.projects
);
