import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Cookies from "js-cookie";

import "./cookie-consent.component.styles.scss";

import { setIsCookieAccepted } from "../../redux/cookies/cookies.actions";

const CookieConsentCard = ({ setIsCookieAccepted }) => {
  setTimeout(() => {}, 2000);

  return (
    <div className="cookie-consent-card">
      <p className="small-title-green">Nous utilisons des cookies</p>
      <p>
        Les cookies permettent le bon fonctionnement du site et la
        personnalisation des publicités par Google. Nous partageons certaines
        informations avec nos partenaires afin d'améliorer notre site et vous
        proposer une bonne expérience d'A.I.D.S.E.A.
      </p>
      <div className="cookie-consent-btn-container">
        <button
          className="green-button-1"
          onClick={() => setIsCookieAccepted(true)}
        >
          Accepter
        </button>
        <a
          href="https://policies.google.com/technologies/cookies?hl=fr"
          target="blank"
          rel="noreferrer"
        >
          En savoir plus...{" "}
        </a>
      </div>
    </div>
  );
};
const mapDispatchToProps = dispatch => ({
  setIsCookieAccepted: isAccepted => dispatch(setIsCookieAccepted(isAccepted)),
});

export default connect(null, mapDispatchToProps)(CookieConsentCard);
