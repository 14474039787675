import React, { useState, useEffect } from 'react';

import './bootstrap-form.component.styles.scss';

// Components
import Form from 'react-bootstrap/Form';
import { GreenButton1 } from '../buttons/buttons.component';
import Feedback from 'react-bootstrap/esm/Feedback';
import {
  createPartnerProfile,
  storeDonationInFirestore,
  sendEmailOnNewPartner,
  countryList,
} from '../../js/utils';
import { Link } from 'react-router-dom';
import reglementInterieurPDF from '../../assets/official-docs/reglement_interieur.pdf';
import SycaForm from '../sycaForm/sycaForm';

// Firebase
import { storage } from '../../js/firebase';

// Redux
import {
  setIsLoaderVisible,
  setIsLoaderLoading,
  setTextInsideLoader,
  setIsLoaderSuccess,
} from '../../redux/loader/loader.actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';

import { FR, EN } from '../../js/languagesData';

const donationsOptions = [];
// 5000 increment  -> 50 000
const calculateVals = () => {
  for (let i = 5000; i < 55000; i += 5000) {
    donationsOptions.push(i);
  }
};

const BootstrapForm = ({ setIsLoaderVisible, currentLanguage }) => {
  const languageData = currentLanguage === 'english' ? EN : FR;
  const [validated, setValidated] = useState(false);
  const [isLogoValid, setIsLogoValid] = useState(true);
  const [isAmountValid, setIsAmountValid] = useState(true);
  const [isFormComplet, setIsFormComplet] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [cardPaymentIsApproved, setCardPaymentIsApproved] = useState(false);

  const [form, setForm] = useState({
    // Contact informations
    civility: 'monsieur',
    typeOfCompany: 'ONG',
    lastname: '',
    firstname: null,
    email: '',
    phone: '',
    address: '',
    country: 'afghanistan',
    presentation: '',
    logoUrl: '',
    logo: '',
    websiteUrl: '',
    // Payment Informations
    donationCurrency: 'XOF',
    donationAmount: 25000,
    donationSpecificAmount: 25000,
    paymentMethod: 'card',
    // Checkbox
    rulesOfProcedures: false,
    termsAndConditions: false,
  });

  const file = form.logo;

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setForm({ ...form, [name]: value });
  };

  const createUserProfileWithLogo = () => {
    setIsLoaderVisible(true);
    const storageRef = storage.ref();
    const fileImageRef = storageRef.child(`logos/${form.email}/${file.name}`);
    fileImageRef.put(file).then(() => {
      fileImageRef.getDownloadURL().then((url) => {
        form.logoUrl = url;
        setIsFormComplet(true);
      });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);

    const formJsx = e.currentTarget;

    // Check validity on bootstrap form
    if (form.donationAmount < 25000) return setIsAmountValid(false);
    if (!file) return setIsLogoValid(false);
    if (formJsx.checkValidity() === false) return e.stopPropagation();
    if (
      formJsx.checkValidity() === true &&
      form.rulesOfProcedures &&
      form.termsAndConditions
    ) {
      if (form.donationAmount < 25000) {
        return setIsFormComplet(false);
      } else if (
        form.donationAmount === 'autre montant' &&
        form.donationSpecificAmount < 25000
      ) {
        return setIsFormComplet(false);
      }
      if (form.donationAmount === 'autre montant') {
        form.donationAmount = form.donationSpecificAmount;
      }
      if (form.paymentMethod.toLowerCase() === 'mobile money') {
        form.donationCurrency = 'XOF';
      }
      setIsLoaderVisible(true);
      createUserProfileWithLogo();
    }
  };
  const handleFileChange = async (e) => {
    const logo = e.target.files[0];
    setForm({ ...form, logo });
  };

  useEffect(() => {
    if (!file) {
      return;
    }
    file.name && setIsLogoValid(true);
  }, [file]);

  useEffect(() => {
    calculateVals();
  }, []);

  return (
    <>
      <Form
        method='POST'
        noValidate
        validated={validated}
        className='bootstrap-form'
        onSubmit={handleSubmit}
      >
        <Form.Group>
          <Form.Label>{languageData.partnerPage.form.name}</Form.Label>
          <Form.Control
            type='text'
            name='lastname'
            defaultValue={form.lastname}
            onChange={handleChange}
            required
          />
          <Feedback type='invalid'>
            {languageData.form.errors.companyName}
          </Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>
            {languageData.partnerPage.form.institutionType}
          </Form.Label>
          <Form.Control
            as='select'
            name='typeOfCompany'
            onChange={handleChange}
          >
            {currentLanguage === 'english' && (
              <>
                <option>NGO</option>
                <option>Association</option>
                <option>Research center</option>
                <option>University</option>
                <option>Major School</option>
                <option>State</option>
                <option>Public or private institution</option>
                <option>Municipality</option>
                <option>Company</option>
                <option>Other</option>
              </>
            )}
            {currentLanguage === 'french' && (
              <>
                <option>ONG</option>
                <option>Association</option>
                <option>Centre de recherche</option>
                <option>Une université</option>
                <option>Grande école</option>
                <option>Etat</option>
                <option>Institution Privée ou publique</option>
                <option>Commune</option>
                <option>Entreprise</option>
                <option>Autre</option>
              </>
            )}
          </Form.Control>
          <Feedback type='invalid'>
            {languageData.form.errors.typeOfIns}
          </Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>
            {languageData.partnerPage.form.countryOfResidence}
          </Form.Label>
          <Form.Control as='select' name='country' onChange={handleChange}>
            {countryList.map((country, i) => (
              <option key={i} value={country}>
                {country}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>{languageData.partnerPage.form.address}</Form.Label>
          <Form.Control
            type='text'
            name='address'
            defaultValue={form.address}
            onChange={handleChange}
            required
          />
          <Feedback type='invalid'>
            {languageData.form.errors.fullMailingAddress}
          </Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>{languageData.partnerPage.form.phone}</Form.Label>
          <Form.Control
            onChange={handleChange}
            type='number'
            name='phone'
            defaultValue={form.phone}
            placeholder='Ex: +33 000 000 000'
            required
          />
          <Feedback type='invalid'>{languageData.form.errors.phone}</Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type='email'
            name='email'
            defaultValue={form.email}
            onChange={handleChange}
            required
          />
          <Feedback type='invalid'>
            Veuillez entrer votre adresse email
          </Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>
            {languageData.partnerPage.form.presentationOverview}
          </Form.Label>
          <Form.Control
            as='textarea'
            name='presentation'
            defaultValue={form.presentation}
            rows={3}
            placeholder={
              languageData.partnerPage.form.presentationOverviewDescription
            }
            onChange={handleChange}
            required
          />
          <Feedback type='invalid'>
            {languageData.form.errors.fillInField}
          </Feedback>
        </Form.Group>
        <Form.Group>
          <Form.File
            label={languageData.partnerPage.form.logoInput}
            id='fileInput'
            onChange={handleFileChange}
            accept='image/*'
          />
          <p className='helper-text'>
            {languageData.partnerPage.form.logoAcceptedFormats}
          </p>
          <p
            className={`alert-helper marginTop-10 ${
              isLogoValid ? 'hidden' : ''
            }`}
          >
            {languageData.partnerPage.form.pleaseInsertYourLogo}
          </p>
        </Form.Group>

        <Form.Group>
          <Form.Label>{languageData.partnerPage.form.websiteUrl}</Form.Label>
          <Form.Control
            type='text'
            name='websiteUrl'
            onChange={handleChange}
            defaultValue={form.websiteUrl}
            placeholder='Ex: aidseafrice.org'
          />
          <Feedback type='invalid'>
            Veuillez entrer l'url de votre site web
          </Feedback>
        </Form.Group>
        <Form.Label className='marginTop20'>
          {languageData.partnerPage.form.paymentMethod}
        </Form.Label>
        <Form.Control
          as='select'
          name='paymentMethod'
          defaultValue={form.paymentMethod}
          onChange={handleChange}
        >
          <option>{languageData.donationPage.form.input501}</option>
          <option>{languageData.donationPage.form.input502}</option>
        </Form.Control>
        <Form.Group>
          <Form.Label>
            {languageData.partnerPage.form.donationMinimum}
          </Form.Label>
          <div className='container-display-flex-col'>
            <Form.Group className='bootstrap-form-select-currency'>
              <Form.Control
                as='select'
                name='donationCurrency'
                onChange={handleChange}
                className='bootstrap-form-select-currency-select'
              >
                <option value='XOF'>XOF (CFA)</option>
              </Form.Control>
              <Form.Control
                className='bootstrap-form-select-currency-amount'
                as='select'
                name='donationAmount'
                onChange={handleChange}
                defaultValue={form.donationAmount}
                required
              >
                <option>1000</option>

                {donationsOptions.map((d) => (
                  <option key={d}>{d}</option>
                ))}
                <option value='autre montant'>
                  {languageData.partnerPage.form.otherAmount}
                </option>
              </Form.Control>
            </Form.Group>
            {form.donationAmount === 'autre montant' ? (
              <Form.Group>
                <Form.Label>
                  {languageData.partnerPage.form.ifOtherAmount}
                </Form.Label>
                <Form.Control
                  type='number'
                  min={1000}
                  name='donationSpecificAmount'
                  defaultValue={form.donationSpecificAmount}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            ) : (
              ''
            )}

            <p
              className={`alert-helper marginTop-10 ${
                isAmountValid ? 'hidden' : ''
              }`}
            >
              {languageData.partnerPage.form.minimumRequired}
            </p>
          </div>
        </Form.Group>
        <Form.Group>
          <Form.Check
            type='checkbox'
            name='rulesOfProcedures'
            onChange={handleChange}
            label={languageData.form.rulesOfProcedureAcknowledgment}
            required
          />
          <a
            href={reglementInterieurPDF}
            target='_blank'
            rel='noreferrer'
            className='paddingLeft20'
          >
            {languageData.partnerPage.form.learnMore}
          </a>
        </Form.Group>

        <Form.Group>
          <Form.Check
            type='checkbox'
            name='termsAndConditions'
            onChange={handleChange}
            label={languageData.partnerPage.form.termsAndConditions}
            required
          />
          <Link to='/terms-and-conditions' className='paddingLeft20'>
            {languageData.partnerPage.form.learnMore}
          </Link>
        </Form.Group>

        {!isFormComplet ? (
          <GreenButton1
            title={languageData.partnerPage.form.validate}
            className='margin0Center'
          />
        ) : (
          ''
        )}
      </Form>
      {isFormComplet && (
        <SycaForm
          form={form}
          handleCorrect={() => setIsFormComplet(false)}
          isPartner
        />
      )}
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  currentLanguage: selectCurrentLanguage,
});
const mapDispatchToProps = (dispatch) => ({
  setIsLoaderVisible: (isVisible) => dispatch(setIsLoaderVisible(isVisible)),
  setIsLoaderLoading: (isLoading) => dispatch(setIsLoaderLoading(isLoading)),
  setTextInsideLoader: (text) => dispatch(setTextInsideLoader(text)),
  setIsLoaderSuccess: (isLoaderSuccess) =>
    dispatch(setIsLoaderSuccess(isLoaderSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BootstrapForm);
