import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { FR, EN } from '../../js/languagesData';

import './contact.page.styles.scss';

// Components
import HeroTop from '../../components/hero-top/hero-top.component';
import ContactForm from '../../components/contact-form/contact-form.component';

// Images
import heroAbout from '../../assets/images/hero-about.png';

import whatsappImg from '../../assets/images/wtsp.png';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';

const ContactPage = ({ currentLanguage }) => {
  const languageData = currentLanguage === 'english' ? EN : FR;

  return (
    <div>
      <HeroTop title={languageData.contactUs.title} img={heroAbout} />
      <div className='page-content-width-100'>
        <h2 id='header-contact' className='header-small-title2'>
          {languageData.contactUs.subTitle1}
          <br /> {languageData.contactUs.subTitle2}
        </h2>
        <div className='contact-form-container marginTop30'>
          <ContactForm languageData={languageData} />
          <div
            id='container-contact'
            className='container-display-flex-row-wrap width70 margin40Center'
          >
            <div className='container-contact-info'>
              <p>A.I.D.S.E.A / MAAM</p>
              <p>12 RUE FRÉDÉRIC PETIT</p>
              <p>80000 AMIENS - FRANCE</p>
              <p>contactsaidsea@gmail.com</p>
            </div>
            <div className='container-contact-info'>
              <div className='marginTop-10 marginBottom10'>
                <a
                  href='https://wa.me/+33781219829'
                  className='color-black fontSize18 '
                >
                  Cell: +33.7.81.21.98.29
                </a>
                <img
                  src={whatsappImg}
                  alt='Whatsapp'
                  style={{ width: '40px', transform: 'translateY(-30%)' }}
                />
              </div>
              <p>Phone: +33.9.82.20.61.47</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  currentLanguage: selectCurrentLanguage,
});

export default connect(mapStateToProps)(ContactPage);
