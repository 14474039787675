import React from 'react';
import { connect } from 'react-redux';

import Table from 'react-bootstrap/Table';
import './table-4.component.styles.scss';

import { createStructuredSelector } from 'reselect';
import { formateDateFromTimestamp } from '../../js/utils';

import {
  deleteDonationStartAsync,
  deletePartnerStartAsync,
} from '../../redux/admin-data/admin-data.actions';
import {
  setCardIsVisible,
  setCardText,
  setCardTitle,
} from '../../redux/card-text/card-text.actions';

const Table4 = ({
  data,
  searchFieldVal = '',
  deleteDonation,
  deletePartner,
  setCardIsVisible,
  setCardText,
  setCardTitle,
}) => {
  const newData = data.reverse();
  const filteredData = newData.filter((partner) => {
    const sf = searchFieldVal.toLowerCase();
    let {
      address,
      country,
      createdAt,
      email,
      lastname,
      logoUrl,
      phone,
      presentation,
      typeOfCompany,
      websiteUrl,
      id,
    } = partner;

    return (
      address.toLowerCase().includes(sf) ||
      lastname.toLowerCase().includes(sf) ||
      presentation.toLowerCase().includes(sf) ||
      address.toLowerCase().includes(sf) ||
      email.toLowerCase().includes(sf) ||
      typeOfCompany.toLowerCase().includes(sf) ||
      websiteUrl.toLowerCase().includes(sf) ||
      country.toLowerCase().includes(sf) ||
      phone.toLowerCase().includes(sf) ||
      id.toLowerCase().includes(sf)
    );
  });
  const handlePresentationClick = (presentation) => {
    setCardIsVisible(true);
    setCardText(presentation);
    setCardTitle('Présentation');
  };
  return (
    <Table striped bordered hover responsive className='admin-table'>
      <thead>
        <tr>
          <th>Logo</th>
          <th>Date d'inscription</th>
          <th>Nom</th>
          <th>Email</th>
          <th>Téléphone</th>
          <th>Adresse</th>
          <th>Pays</th>
          <th>Presentation</th>
          <th>Type d'entreprise</th>
          <th>Site Web</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {searchFieldVal === ''
          ? newData.map(
              (
                {
                  lastname,
                  email,
                  address,
                  phone,
                  country,
                  presentation,
                  typeOfCompany,
                  logoUrl,
                  websiteUrl,
                  createdAt,
                  id,
                },
                idx
              ) => (
                <tr key={idx}>
                  <td>
                    <img
                      className='partner-table-img'
                      src={logoUrl}
                      alt='logo Url'
                    />
                  </td>
                  <td>{formateDateFromTimestamp(createdAt)}</td>
                  <td>{lastname}</td>
                  <td>{email}</td>
                  <td>{phone}</td>
                  <td>{address}</td>
                  <td>{country}</td>
                  <td
                    onClick={() => handlePresentationClick(presentation)}
                    className='cursorPointer'
                  >
                    Lire présentation
                  </td>
                  <td>{typeOfCompany}</td>

                  <td>{websiteUrl}</td>

                  <td>
                    <button
                      className='red-button-1'
                      onClick={() => {
                        const isConfirmed = window.confirm(
                          'Voulez vous supprimer le partenaire ? '
                        );
                        if (isConfirmed) {
                          deletePartner(id);
                        }
                      }}
                    >
                      Supprimer
                    </button>
                  </td>
                </tr>
              )
            )
          : filteredData.map(
              (
                {
                  lastname,
                  email,
                  address,
                  phone,
                  country,
                  presentation,
                  typeOfCompany,
                  logoUrl,
                  websiteUrl,
                  createdAt,
                  id,
                },
                idx
              ) => (
                <tr key={idx}>
                  <td>
                    <img
                      className='partner-table-img'
                      src={logoUrl}
                      alt='logo Url'
                    />
                  </td>
                  <td>{formateDateFromTimestamp(createdAt)}</td>
                  <td>{lastname}</td>
                  <td>{email}</td>
                  <td>{phone}</td>
                  <td>{address}</td>
                  <td>{country}</td>
                  <td
                    onClick={() => handlePresentationClick(presentation)}
                    className='cursorPointer'
                  >
                    Lire présentation
                  </td>
                  <td>{typeOfCompany}</td>

                  <td>{websiteUrl}</td>

                  <td>
                    <button
                      className='red-button-1'
                      onClick={() => {
                        const isConfirmed = window.confirm(
                          'Voulez vous supprimer le partenaire ? '
                        );
                        if (isConfirmed) {
                          deletePartner(id);
                        }
                      }}
                    >
                      Supprimer
                    </button>
                  </td>
                </tr>
              )
            )}
      </tbody>
    </Table>
  );
};
const mapStateToProps = createStructuredSelector({
  //
});
const mapDispatchToProps = (dispatch) => ({
  deleteDonation: (donationId) =>
    dispatch(deleteDonationStartAsync(donationId)),
  deletePartner: (partnerId) => dispatch(deletePartnerStartAsync(partnerId)),
  setCardIsVisible: (isVisible) => dispatch(setCardIsVisible(isVisible)),
  setCardText: (text) => dispatch(setCardText(text)),
  setCardTitle: (title) => dispatch(setCardTitle(title)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Table4);
