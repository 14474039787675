import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { createStructuredSelector } from 'reselect';

import { storeDonationInFirestore } from '../../js/utils';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';
import { selectDonationToken } from '../../redux/donations/donations.selectors';
import { addDonationToken } from '../../redux/donations/donations.actions';

import {
  createPartnerProfile,
  sendEmailOnNewPartner,
  sendEmailOnNewDonation,
} from '../../js/utils';

import {
  deleteTemporaryDonationFromFirestore,
  getIsPaymentSuccess,
} from '../../js/utils';

import './payment.page.styles.scss';

import { FR, EN } from '../../js/languagesData';

const PaymentPage = ({
  donationToken,
  removeDonationToken,
  currentLanguage,
}) => {
  const languageData = currentLanguage === 'english' ? EN : FR;
  let [timer, setTimer] = useState(8);
  let [databaseIsUpdated, setDatabaseIsUpdated] = useState(false);
  const [paymentInfos, setPaymentInfos] = useState({
    title: 'Oops',
    description: languageData.general.pageError,
    buttonTitle: languageData.header.homepage,
  });
  const history = useHistory();

  useEffect(async () => {
    if (donationToken.length > 0) {
      const paymentResult = await getIsPaymentSuccess(donationToken);

      if (paymentResult?.isPaymentSuccess?.isPaymentSuccess) {
        setPaymentInfos({
          title: languageData.general.thanks,
          description: languageData.partnerPage.form.successMessage,
          buttonTitle: languageData.header.homepage,
        });
        await storeDonationInFirestore(
          paymentResult.isPaymentSuccess.data,
          donationToken
        );
        if (paymentResult.isPaymentSuccess.data?.logoUrl?.length > 0) {
          // If logo url exists, then it is a pro, then create profile as partner in firestore
          const { data } = paymentResult.isPaymentSuccess;

          await createPartnerProfile(data, data.logoUrl);

          await sendEmailOnNewPartner(data);
        } else {
          await sendEmailOnNewDonation(paymentResult.isPaymentSuccess);
        }
      } else if (paymentResult?.isPaymentFailure?.isPaymentFailure) {
        setPaymentInfos({
          title: 'Oops',
          description: languageData.general.problemWithPayment,
          buttonTitle: languageData.header.homepage,
        });
      }

      if (donationToken?.length > 0) {
        await deleteTemporaryDonationFromFirestore(donationToken);
        setDatabaseIsUpdated(true);
      }
    }
  }, [donationToken]);

  useEffect(() => {
    if (databaseIsUpdated) {
      removeDonationToken();
    }
  }, [databaseIsUpdated]);

  useEffect(() => {
    setInterval(() => {
      setTimer((timer -= 1));
    }, 1000);
    setTimeout(() => {
      history.push('/');
    }, 8000);
  }, []);

  const { title, description, buttonTitle } = paymentInfos;

  return (
    <div className='payment-container'>
      <div className='payment-info-container'>
        <h2>{title}</h2>
        <p>{description}</p>
        <p>
          {languageData.form.redirection} {timer}...
        </p>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  donationToken: selectDonationToken,
  currentLanguage: selectCurrentLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  removeDonationToken: () => dispatch(addDonationToken('')),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
