import React from 'react';
import { connect } from 'react-redux';

import Table from 'react-bootstrap/Table';
import { createStructuredSelector } from 'reselect';

import { deleteDonationStartAsync } from '../../redux/admin-data/admin-data.actions';

const Table6 = ({ data, searchFieldVal = '', deleteDonation }) => {
  const newData = data.reverse();
  const filteredData = newData.filter((donation) => {
    const sf = searchFieldVal.toLowerCase();
    let {
      date = '',
      lastname = '',
      email = '',
      address = '',
      amount = '',
      donationType = 'Partenariat',
      paymentMethod = '',
      donationCurrency = '',
      country = '',
      id,
    } = donation;
    if (paymentMethod === 'card') {
      paymentMethod = 'Carte bancaire';
    } else if (paymentMethod === 'paypal') {
      paymentMethod = 'Paypal';
    } else if (paymentMethod === 'wu') {
      paymentMethod = 'Western Union';
    } else if (paymentMethod === 'wireTransfer') {
      paymentMethod = 'Virement bancaire';
    }

    let firstname = donation.firstname || '';

    return (
      date.toLowerCase().includes(sf) ||
      lastname.toLowerCase().includes(sf) ||
      firstname.toLowerCase().includes(sf) ||
      email.toLowerCase().includes(sf) ||
      address.toLowerCase().includes(sf) ||
      amount === searchFieldVal ||
      donationType.toLowerCase().includes(sf) ||
      paymentMethod.toLowerCase().includes(sf) ||
      donationCurrency.toLowerCase().includes(sf) ||
      country.toLowerCase().includes(sf) ||
      id.toLowerCase().includes(sf)
    );
  });
  return (
    <Table striped bordered hover responsive className='admin-table'>
      <thead>
        <tr>
          <th>Date</th>
          <th>Noms</th>
          <th>Montant</th>
          <th>Type de don</th>
          <th>Méthode de paiement</th>
          <th>Pays</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {searchFieldVal === ''
          ? newData.map(
              (
                {
                  date,
                  lastname,
                  firstname = '',
                  amount,
                  donationType = 'Partenariat',
                  paymentMethod,
                  donationCurrency,
                  country,
                  id,
                },
                idx
              ) => (
                <tr key={idx}>
                  <td>{date}</td>
                  <td>
                    {lastname} {firstname}
                  </td>
                  <td>{amount}</td>
                  <td>{donationType}</td>
                  <td>
                    {paymentMethod === 'card' && 'Carte bancaire'}
                    {paymentMethod === 'paypal' && 'Paypal'}
                  </td>
                  <td>{country}</td>

                  <td>
                    <button
                      className='red-button-1'
                      onClick={() => {
                        const isConfirmed = window.confirm(
                          'Voulez vous définir le don comme remboursé ? '
                        );
                        if (isConfirmed) {
                          deleteDonation(id);
                        }
                      }}
                    >
                      Remboursé
                    </button>
                  </td>
                </tr>
              )
            )
          : filteredData.map(
              (
                {
                  date,
                  lastname,
                  firstname = '',
                  email,
                  address,
                  amount,
                  donationType = 'Partenariat',
                  paymentMethod,
                  donationCurrency,
                  country,
                  id,
                },
                idx
              ) => (
                <tr key={idx}>
                  <td>{date}</td>
                  <td>
                    {lastname} {firstname}
                  </td>
                  <td>{amount}</td>
                  <td>{donationType}</td>
                  <td>
                    {paymentMethod === 'card' && 'Carte bancaire'}
                    {paymentMethod === 'paypal' && 'Paypal'}
                  </td>
                  <td>{country}</td>

                  <td>
                    <button
                      className='red-button-1'
                      onClick={() => {
                        const isConfirmed = window.confirm(
                          'Voulez vous définir le don comme remboursé ? '
                        );
                        if (isConfirmed) {
                          deleteDonation(id);
                        }
                      }}
                    >
                      Remboursé
                    </button>
                  </td>
                </tr>
              )
            )}
      </tbody>
    </Table>
  );
};
const mapStateToProps = createStructuredSelector({
  //
});
const mapDispatchToProps = (dispatch) => ({
  deleteDonation: (donationId) =>
    dispatch(deleteDonationStartAsync(donationId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Table6);
