import React from 'react';

import './youtube-video-container.component.styles.scss';

const YoutubeVideoContainer = ({ src }) => {
  return (
    <div className='iframe-iframe-container'>
      <div className='iframe-container'>
        <iframe
          title='Youtube Video'
          src={src}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default YoutubeVideoContainer;
