import React, { useState, useEffect } from 'react';



const HeroTop = ({
  title,
  img,
  isPartnerOrJoin,
  isDonationPage,
  isDocumentsPage,
}) => {

  return (
    <div
      className='hero-top-image-container'
      style={{
        backgroundImage: `url(${img})`,
      }}
    >
      <div className='hero-top-image-filter' />

      <div className='hero-top-text-container'>
        <div className='hero-top-divider'></div>
        <h1> {title} </h1>
        <div className='hero-top-divider'></div>
      </div>
    </div>
  );
};

export default HeroTop;
