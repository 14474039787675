import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import './homepage.page.styles.scss';

// Components
import HeroCarousel from '../../components/carousel/carousel.component';
import ActualityThumbnail from '../../components/actuality-thumbnail/actuality-thumbnail.component';
import CarouselPartner from '../../components/carousel-partner/carousel-partner.component';
import YoutubeVideoContainer from '../../components/youtube-video-container/youtube-video-container.component';

// Import articles
import articles from '../../js/articles';
import { selectMedia } from '../../redux/admin-data/admin-data.selectors';
import { fetchMediaStartAsync } from '../../redux/admin-data/admin-data.actions';

// Firebase Data
import { convertCollectionsSnapshotToMap } from '../../js/utils';
import { firestore } from '../../js/firebase';
import { setPartners } from '../../redux/partners/partners.actions';
import { createStructuredSelector } from 'reselect';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';
import { FR, EN } from '../../js/languagesData';

const Homepage = ({
  partners,
  setPartners,
  fetchMedia,
  medias,
  currentLanguage,
}) => {
  const [isSmartphone, setIsSmartphone] = useState(false);
  const languageData = currentLanguage === 'english' ? EN : FR;

  const setScreen = () => {
    if (window.screen.width <= 590) {
      setIsSmartphone(true);
    } else {
      setIsSmartphone(false);
    }
  };

  useEffect(() => {
    setScreen();
    fetchMedia();
  }, []);
  window.onresize = () => {
    setScreen();
  };

  useEffect(() => {
    const partnersRef = firestore.collection('partners-showable');
    partnersRef.onSnapshot(async (snapshot) => {
      setPartners(convertCollectionsSnapshotToMap(snapshot));
    });
  }, []);

  return (
    <div className='homepage'>
      <HeroCarousel isSmartphone={isSmartphone} />
      <div className='homepage-container'>
        <div className='homepage-container-left'>
          <div className='homepage-about'>
            <p className='homepage-about-title'>
              {languageData.homepage.ourThought}
            </p>
            <marquee
              scrollamount='1'
              direction='up'
              className='homepage-about-paragraph align-justify'
            >
              {languageData.homepage.mainText.first}
              <br />
              <br />
              {languageData.homepage.mainText.second}
              <br />
              <br />
              {languageData.homepage.mainText.third}
              <br />
              <br />
              {languageData.homepage.mainText.fourth}
              <br />
              <br />
              {languageData.homepage.mainText.fifth}
              <br />
              <br />
              {languageData.homepage.presidentName}
              <br />
              <br />
              {languageData.homepage.presidentOf}
            </marquee>
            <YoutubeVideoContainer src='https://www.youtube.com/embed/KPBO08ROkJ4' />
          </div>
          <CarouselPartner
            partners={partners}
            title={languageData.partners.title}
            description1={languageData.partners.description1}
            description2={languageData.partners.description2}
            description3={languageData.partners.description3}
          />
          <div className='partner-button-container'>
            <Link to='/partners'>
              <button className='button-partners'>
                {languageData.partners.buttonTitle}
              </button>
            </Link>
          </div>
        </div>
        <div className='homepage-container-right'>
          {medias.map((article, idx) =>
            idx <= 3 ? <ActualityThumbnail key={idx} data={article} /> : ''
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  medias: selectMedia,
  currentLanguage: selectCurrentLanguage,
});
const mapDispatchToProps = (dispatch) => ({
  setPartners: (partners) => dispatch(setPartners(partners)),
  fetchMedia: () => dispatch(fetchMediaStartAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
