import React from 'react';

import './terms-and-conditions.page.styles.scss';

// Components
import HeroTop from '../../components/hero-top/hero-top.component';
import HeaderGreenTitle from '../../components/header-green-title/header-green-title.component';
import HeaderGreenTitleUl from '../../components/header-green-title-ul/header-green-title-ul.component';

// Images
import heroOfficialDoc from '../../assets/images/hero-official-doc.jpg';

const TermsAndConditionsPage = () => {
  return (
    <div>
      <HeroTop
        title="Conditions Générales d'Utilisation"
        img={heroOfficialDoc}
      />
      <div className='page-content-width-100'>
        <HeaderGreenTitle
          title="Collecte de l'information"
          text='Nous recueillons des informations lorsque vous faites un don sur notre site. Les informations recueillies sont uniquement celles que vous nous avez fournies et incluent selon les cas votre pays (obligatoire), votre prénom et nom, ou votre raison sociale (obligatoire), votre adresse e-mail (obligatoire), votre numéro de téléphone, votre adresse postale, votre organisme le cas échéant, et votre mode de paiement. En outre, nous recevons et enregistrons automatiquement des informations à partir de votre navigateur internet, y compris votre adresse IP, vos logiciels, votre matériel, et la page que vous visitez.'
        />
        <HeaderGreenTitleUl
          title='Utilisation des informations'
          text='Toutes les informations que nous recueillons auprès de vous peuvent être utilisées pour :'
          li={[
            'Personnaliser votre expérience et répondre à vos besoins individuels',
            'Améliorer notre site web',
            'Améliorer le service utilisateur et vos besoins de prise en charge',
            'Vous contacter par email',
            'Administrer un événement, une promotion, ou une enquête',
          ]}
        />
        <HeaderGreenTitle
          title='Divulgation à des tiers'
          text="Nous ne vendons, n'échangeons et ne transférons pas vos informations personnelles identifiables à des tiers. Cela ne comprend pas les tierces parties de confiance qui nous aident à exploiter notre site internet, tant que ces parties conviennent de garder ces informations confidentielles. Nous pensons qu'il est nécessaire de partager des informations afin d'enquêter, de prévenir ou de prendre des mesures concernant des activités illégales, fraudes présumées, situations impliquant des menaces potentielles à la sécurité physique de toute personne, violations de nos conditions d'utilisation, ou quand la loi nous y contraint. Les informations non-privées, cependant, peuvent être fournies à d'autres parties majoritairement pour des besoins d’amélioration de notre site internet."
        />
        <HeaderGreenTitle
          title='Protection des informations'
          text="Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de vos informations personnelles. Nous utilisons un cryptage à la pointe de la technologie pour protéger les informations sensibles transmises en ligne. Nous protégeons également vos informations hors ligne. Seuls les employés qui ont besoin d'effectuer un travail spécifique ont accès aux informations personnelles identifiables. Les ordinateurs et serveurs utilisés pour stocker des informations personnelles identifiables sont conservés dans un environnement sécurisé.

          Est-ce que nous utilisons des cookies ? Oui. Nos cookies améliorent l'accès à notre site et identifient les visiteurs réguliers. En outre, nos cookies améliorent l'expérience d'utilisateur grâce au suivi et au ciblage de ses intérêts. Cependant, cette utilisation des cookies n'est en aucune façon liée à des informations personnelles identifiables sur notre site."
        />

        <HeaderGreenTitle
          title="Droit d'opposition et de retrait"
          text='Nous nous engageons à reconnaître un droit d’accès et de rectification aux personnes concernées désireuses de consulter, modifier, voire radier les informations les concernant.

          Pour exercer ce droit d’accès veuillez nous écrire en vous rendant sur la page contact de notre site internet.'
        />
        <HeaderGreenTitle
          title='Droit d’accès'
          text='Nous nous engageons à vous offrir un droit d’opposition et de retrait quant à vos renseignements personnels. Le droit d’opposition s’entend comme étant la possibilité offerte aux internautes de refuser que leurs renseignements personnels soient utilisés à certaines fins mentionnées lors de la collecte. Le droit de retrait s’entend comme étant la possibilité offerte aux internautes de demander à ce que leurs renseignements personnels ne figurent plus, par exemple, dans une liste de diffusion.

          Pour exercer ce droit de retrait veuillez nous écrire en vous rendant sur la page contact de notre site internet.'
        />
        <HeaderGreenTitle
          title='Se désabonner'
          text="Nous utilisons l'adresse e-mail que vous fournissez pour vous envoyer des informations et mises à jour relatives à votre don, des nouvelles de notre ministère (si vous avez choisi de recevoir de nos nouvelles), etc. Si à n'importe quel moment vous souhaitez vous désinscrire et ne plus recevoir d'e-mails, des instructions de désabonnement détaillées sont incluses en bas de chaque e-mail, ou bien écrivez-nous par e-mail à contactsaidsea@gmail.com"
        />
        <HeaderGreenTitle
          title='Consentement'
          text='En utilisant notre site, vous consentez implicitement à nos conditions générales d’utilisation.'
        />
        <HeaderGreenTitle
          title='Des questions? Appelez-nous au +33 9 82 20 61 47'
          text='Nous sommes toujours là pour apporter nos aides.'
        />
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
