const AdminDataActionTypes = {
  FETCH_DASHBOARD_START: 'FETCH_DASHBOARD_START',
  FETCH_DASHBOARD_SUCESS: 'FETCH_DASHBOARD_SUCESS',
  FETCH_DASHBOARD_FAILURE: 'FETCH_DASHBOARD_FAILURE',
  FETCH_DONATIONS_START: 'FETCH_DONATIONS_START',
  FETCH_DONATIONS_SUCCESS: 'FETCH_DONATIONS_SUCCESS',
  FETCH_DONATIONS_FAILURE: 'FETCH_DONATIONS_FAILURE',
  FETCH_EXPENSES_START: 'FETCH_EXPENSES_START',
  FETCH_EXPENSES_SUCCESS: 'FETCH_EXPENSES_SUCCESS',
  FETCH_EXPENSES_FAILURE: 'FETCH_EXPENSES_FAILURE',
  FETCH_PARTNERS_START: 'FETCH_PARTNERS_START',
  FETCH_PARTNERS_SUCCESS: 'FETCH_PARTNERS_SUCCESS',
  FETCH_PARTNERS_FAILURE: 'FETCH_PARTNERS_FAILURE',
  FETCH_MEMBERS_START: 'FETCH_MEMBERS_START',
  FETCH_MEMBERS_SUCCESS: 'FETCH_MEMBERS_SUCCESS',
  FETCH_MEMBERS_FAILURE: 'FETCH_MEMBERS_FAILURE',
  FETCH_ADMIN_START: 'FETCH_ADMIN_START',
  FETCH_ADMIN_SUCCESS: 'FETCH_ADMIN_SUCCESS',
  FETCH_ADMIN_FAILURE: 'FETCH_ADMIN_FAILURE',
  CREATE_USER_START: 'CREATE_USER_START',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
  DELETE_USER_START: 'DELETE_USER_START',
  SET_USER_UPDATE: 'SET_USER_UPDATE',
  SET_USER_UPDATE_IS_VISIBLE: 'SET_USER_UPDATE_IS_VISIBLE',
  SET_USER_UPDATE_PROFIL: 'SET_USER_UPDATE_PROFIL',
  UPDATE_USER_PROFIL_START: 'UPDATE_USER_PROFIL_START',
  CREATE_NEW_DONATION_START: 'CREATE_NEW_DONATION_START',
  DELETE_DONATION_START: 'DELETE_DONATION_START',
  DELETE_PARTNERS_START: 'DELETE_PARTNERS_START',
  CREATE_NEW_EXPENSE_START: 'CREATE_NEW_EXPENSE_START',
  DELETE_EXPENSE_START: 'DELETE_EXPENSE_START',
  FETCH_MEDIA_START: 'FETCH_MEDIA_START',
  FETCH_MEDIA_SUCCESS: 'FETCH_MEDIA_SUCCESS',
  FETCH_MEDIA_FAILURE: 'FETCH_MEDIA_FAILURE',
  UPDATE_MEDIA_START: 'UPDATE_MEDIA_START',
  SET_MEDIA_TEXT: 'SET_MEDIA_TEXT',
  CREATE_NEW_ARTICLE: 'CREATE_NEW_ARTICLE',
  DELETE_ARTICLE_START:'DELETE_ARTICLE_START'
};
export default AdminDataActionTypes;
