import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectFilteredDonations,
  selectFilteredExpenses,
} from '../../redux/admin-data/admin-data.selectors';

import {formatDateFromSlashesToInt, arrayOfDays} from '../../js/utils';


import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';


const AreaChartDashboard = ({  donations, expenses, chartDataType, startDate, endDate }) => {
 
  let donationTotal = 0;
  let expenseTotal = 0;
  const donationsFormatedData = [];
  const expensesFormatedData = [];
  let formatedData = arrayOfDays(donations, expenses, startDate, endDate)
  const dataForGraph = []

  
  formatedData.map(day => {
    if(chartDataType == 'donations') {

      if(day.donationAmount) { donationTotal = parseInt(donationTotal) + parseInt(day.donationAmount) } 
      dataForGraph.push({
            name: day.date,
            donations: donationTotal,
          }) 
      } else {
        if(day.expenseAmount) {expenseTotal = parseInt(expenseTotal) + parseInt(day.expenseAmount)}
        dataForGraph.push({
          name: day.date,
          dépenses: expenseTotal,
        })
      }
  })

  

  
  return (
    <ResponsiveContainer>
      <AreaChart
        data={dataForGraph}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        <Area  type='monotone' dataKey='donations' stroke='#0db539' fill='#0db539' />
        <Area  type='monotone' dataKey='dépenses' stroke='#fa575a' fill='#fa575a' />
      </AreaChart>
    </ResponsiveContainer>
  );
};
const mapStateToProps = createStructuredSelector({
  donations: selectFilteredDonations,
  expenses: selectFilteredExpenses,
});
export default connect(mapStateToProps)(AreaChartDashboard);
