import React from 'react';
import { connect } from 'react-redux';

import Table from 'react-bootstrap/Table';

import { createStructuredSelector } from 'reselect';
import { formateDateFromTimestamp } from '../../js/utils';

import { deleteMemberStartAsync } from '../../redux/admin-data/admin-data.actions';
import {
  setCardIsVisible,
  setCardText,
  setCardTitle,
} from '../../redux/card-text/card-text.actions';

const Table5 = ({
  data,
  searchFieldVal = '',

  setCardIsVisible,
  setCardText,
  setCardTitle,
  deleteMember,
}) => {
  const newData = data.reverse();
  const filteredData = newData.filter((member) => {
    const sf = searchFieldVal.toLowerCase();
    let {
      createdAt,
      lastname,
      firstname,
      email,
      address,
      country,
      dateOfBirth,
      activity,
      memberType,
      placeOfBirth,
      presentation,
    } = member;

    return (
      lastname.toLowerCase().includes(sf) ||
      activity.toLowerCase().includes(sf) ||
      address.toLowerCase().includes(sf) ||
      country.toLowerCase().includes(sf) ||
      createdAt.toLowerCase().includes(sf) ||
      dateOfBirth.toLowerCase().includes(sf) ||
      email.toLowerCase().includes(sf) ||
      firstname.toLowerCase().includes(sf) ||
      memberType.toLowerCase().includes(sf) ||
      placeOfBirth.toLowerCase().includes(sf) ||
      presentation.toLowerCase().includes(sf)
    );
  });
  const handlePresentationClick = (presentation) => {
    setCardIsVisible(true);
    setCardText(presentation);
    setCardTitle('Présentation');
  };

  return (
    <Table striped bordered hover responsive className='admin-table'>
      <thead>
        <tr>
          <th>Date d'inscription</th>
          <th>Type de membre</th>
          <th>Noms</th>
          <th>Email</th>
          <th>Téléphone</th>
          <th>Adresse</th>
          <th>Pays</th>
          <th>Profession ou activité</th>
          <th>Presentation</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {searchFieldVal === ''
          ? newData.map(
              (
                {
                  createdAt,
                  lastname,
                  firstname,
                  email,
                  address,
                  country,
                  dateOfBirth,
                  activity,
                  memberType,
                  placeOfBirth,
                  presentation,
                  phone,
                },
                idx
              ) => (
                <tr key={idx}>
                  <td>{formateDateFromTimestamp(createdAt)}</td>
                  <td>{memberType}</td>
                  <td>{(lastname, firstname)}</td>
                  <td>{email}</td>
                  <td>{phone}</td>
                  <td>{address}</td>
                  <td>{country}</td>
                  <td>{activity}</td>
                  <td
                    onClick={() => handlePresentationClick(presentation)}
                    className='cursorPointer'
                  >
                    Lire présentation
                  </td>
                  <td>
                    <button
                      className='red-button-1'
                      onClick={() => {
                        const isConfirmed = window.confirm(
                          'Voulez vous supprimer le membre ? '
                        );
                        if (isConfirmed) {
                          deleteMember(email);
                        }
                      }}
                    >
                      Supprimer
                    </button>
                  </td>
                </tr>
              )
            )
          : filteredData.map(
              (
                {
                  lastname,
                  email,
                  address,
                  phone,
                  country,
                  presentation,
                  typeOfCompany,
                  logoUrl,
                  websiteUrl,
                  createdAt,
                  id,
                },
                idx
              ) => (
                <tr key={idx}>
                  <td>
                    <img
                      className='partner-table-img'
                      src={logoUrl}
                      alt='logo Url'
                    />
                  </td>
                  <td>{formateDateFromTimestamp(createdAt)}</td>
                  <td>{lastname}</td>
                  <td>{email}</td>
                  <td>{phone}</td>
                  <td>{address}</td>
                  <td>{country}</td>
                  <td
                    onClick={() => handlePresentationClick(presentation)}
                    className='cursorPointer'
                  >
                    Lire présentation
                  </td>

                  <td>
                    <button
                      className='red-button-1'
                      onClick={() => {
                        const isConfirmed = window.confirm(
                          'Voulez vous supprimer le membre ? '
                        );
                        if (isConfirmed) {
                          deleteMember(email);
                        }
                      }}
                    >
                      Supprimer
                    </button>
                  </td>
                </tr>
              )
            )}
      </tbody>
    </Table>
  );
};
const mapStateToProps = createStructuredSelector({
  //
});
const mapDispatchToProps = (dispatch) => ({
  setCardIsVisible: (isVisible) => dispatch(setCardIsVisible(isVisible)),
  setCardText: (text) => dispatch(setCardText(text)),
  setCardTitle: (title) => dispatch(setCardTitle(title)),
  deleteMember: (memberEmail) => dispatch(deleteMemberStartAsync(memberEmail)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Table5);
