import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'

import { setMediaText, updateMediaStartAsync, deleteArticleStartAsync } from '../../redux/admin-data/admin-data.actions';

import { formatDateFromSlashesToInt } from '../../js/utils';


import { generateUid } from '../../js/utils';

import MaterialTable from 'material-table';

const MaterialTable1 = ({ title, data, setMediaText, updateMedia, deleteArticle}) => {
  const [columns, setColumns] = useState([]);
 
  useEffect(() => {
    setColumns([
      {
        title: 'Date',
        field: 'date',
        customSort: (a, b) => {
          const newDateA = formatDateFromSlashesToInt(a.date);
          const newDateB = formatDateFromSlashesToInt(b.date);
          return newDateA - newDateB;
        },
      },
      { title: "Type d'article", field: 'typeOfArticle' },
      {
        title: 'Image',
        field: 'imageUrl',
        cellStyle: {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          maxWidth: 100,
        },
      },
      {
        title: 'Titre',
        field: 'title',
      },

      {
        title: 'Description',
        field: 'description',
      },

      {
        title: 'Auteur',
        field: 'author',
      },
      {
        title: "Lien de l'auteur",
        field: 'authorLink',
      },

      {
        title: 'Lien externe',
        field: 'articleUrl',
      },
    ]);
  }, []);

  return (
    <MaterialTable
      title={title}
      columns={columns}
      data={data}
      actions={[
        {
          icon: 'check',
          tooltip: 'Modifier Contenu',
          isFreeAction: false,
          onClick: (event, rowData) => {
            if (rowData.content) {

              setMediaText(rowData.content, rowData.id)
            } else {
              alert("Vous ne pouvez modifier que le contenu des articles internes.")
            }
          }
        }
      ]}
      editable={{

        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;

              updateMedia(oldData.id, dataUpdate[index])

              resolve();
            }, 1000);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              deleteArticle(dataDelete[index].id)
              resolve();
            }, 1000);
          }),
      }}
    />
  );
};
const mapDispatchToProps = dispatch => ({
  setMediaText: (text, id) => dispatch(setMediaText(text, id)),
  updateMedia: (title, updatedData) => dispatch(updateMediaStartAsync(title, updatedData)),
  deleteArticle: id => dispatch(deleteArticleStartAsync(id))
})

export default connect(null, mapDispatchToProps)(MaterialTable1);
