import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect';

// Dependencies
import { fetchMediaStartAsync } from '../../redux/admin-data/admin-data.actions';
import { selectMedia } from '../../redux/admin-data/admin-data.selectors';

// Components 
import HeroTop2 from '../../components/hero-top-2/hero-top-2.component';

import './media-article.page.styles.scss';



const MediaArticlePage = ({ articles, fetchMedia }) => {
    let { id } = useParams();
    const [article, setArticle] = useState({
        title: '',
        description: '',
        content: '',
        imageUrl: '',
    })
    useEffect(() => {
        fetchMedia()
    }, [])
    useEffect(() => {
        articles.map((article) => {
            const { title, description, imageUrl, content } = article
            if (article.link.toLowerCase() === id.toLowerCase()) {
                setArticle({
                    title,
                    description, imageUrl, content
                })
            }
        })
    }, [articles])
    useEffect(() => {
        const contentDiv = document.querySelector('.media-article-content')
        contentDiv.innerHTML = article.content
    }, [article])


    const { title, imageUrl } = article
    return (
        <div className="media-article-page">
            <HeroTop2 title='AIDSEA médias' img={imageUrl} />
            <div className="media-article-container">
                <h2>{title}</h2>
                <p className='media-article-content'></p>
            </div>
        </div>
    )
}
const mapStateToProps = createStructuredSelector({
    articles: selectMedia
})
const mapDispatchToProps = dispatch => ({
    fetchMedia: () => dispatch(fetchMediaStartAsync())
})
export default connect(mapStateToProps, mapDispatchToProps)(MediaArticlePage);