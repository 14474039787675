import DashboardAdminActionTypes from './admin-data.types';
import { firestore } from '../../js/firebase';
import firebase from '../../js/firebase';
import {
  storeDonationInFirestore,
  formatDateFromInput,
  generateUid,
  createNewDate,
} from '../../js/utils';

import {
  setIsLoaderLoading,
  setIsLoaderSuccess,
  setIsLoaderVisible,
  setTextInsideLoader,
  setIsLoaderClosing,
  setIsLoaderReloading,
} from '../loader/loader.actions';
import AdminDataActionTypes from './admin-data.types';

// ------------- ACTIONS -------------- //

// Media
export const fetchMediaStart = () => ({
  type: DashboardAdminActionTypes.FETCH_MEDIA_START,
});
export const fetchMediaSuccess = mediaData => ({
  type: DashboardAdminActionTypes.FETCH_MEDIA_SUCCESS,
  payload: mediaData,
});
export const updateMediaStart = () => ({
  type: DashboardAdminActionTypes.UPDATE_MEDIA_START,
});

// Donations
export const fetchDonationsStart = () => ({
  type: DashboardAdminActionTypes.FETCH_DONATIONS_START,
});
export const fetchDonationsSuccess = donations => ({
  type: DashboardAdminActionTypes.FETCH_DONATIONS_SUCCESS,
  payload: donations,
});
export const fetchDonationsFailure = errMessage => ({
  type: DashboardAdminActionTypes.FETCH_DONATIONS_FAILURE,
  payload: errMessage,
});

// Expenses
export const fetchExpensesStart = () => ({
  type: AdminDataActionTypes.FETCH_EXPENSES_START,
});
export const fetchExpensesSuccess = expenses => ({
  type: AdminDataActionTypes.FETCH_EXPENSES_SUCCESS,
  payload: expenses,
});
export const fetchExpensesFailure = errMessage => ({
  type: AdminDataActionTypes.FETCH_EXPENSES_FAILURE,
  payload: errMessage,
});
export const createNewExpenseStart = () => ({
  type: AdminDataActionTypes.CREATE_NEW_EXPENSE_START,
});
export const deleteExpenseStart = () => ({
  type: AdminDataActionTypes.DELETE_EXPENSE_START,
});

// Partners
export const fetchPartnersStart = () => ({
  type: AdminDataActionTypes.FETCH_PARTNERS_START,
});
export const fetchPartnersSuccess = partners => ({
  type: AdminDataActionTypes.FETCH_PARTNERS_SUCCESS,
  payload: partners,
});
export const fetchPartnersFailure = errMessage => ({
  type: AdminDataActionTypes.FETCH_PARTNERS_FAILURE,
  payload: errMessage,
});

export const fetchMembersStart = () => ({
  type: AdminDataActionTypes.FETCH_MEMBERS_START,
});
export const fetchMembersSuccess = members => ({
  type: AdminDataActionTypes.FETCH_MEMBERS_SUCCESS,
  payload: members,
});
export const fetchMembersFailure = errMessage => ({
  type: AdminDataActionTypes.FETCH_MEMBERS_FAILURE,
  payload: errMessage,
});

// Admins
export const fetchAdminsStart = () => ({
  type: AdminDataActionTypes.FETCH_ADMIN_START,
});
export const fetchAdminsSuccess = admins => ({
  type: AdminDataActionTypes.FETCH_ADMIN_SUCCESS,
  payload: admins,
});
export const fetchAdminsFailure = err => ({
  type: AdminDataActionTypes.FETCH_ADMIN_FAILURE,
  payload: err.message,
});
export const createNewUserStart = () => ({
  type: AdminDataActionTypes.CREATE_USER_START,
});
export const createNewUserFailure = errMessage => ({
  type: AdminDataActionTypes.CREATE_USER_FAILURE,
  payload: errMessage,
});
export const createNewUserSuccess = user => ({
  type: AdminDataActionTypes.CREATE_USER_SUCCESS,
  payload: user,
});
export const deleteUserStart = () => ({
  type: AdminDataActionTypes.DELETE_USER_START,
});
export const setUserUpdateIsVisible = isVisible => ({
  type: AdminDataActionTypes.SET_USER_UPDATE_IS_VISIBLE,
  payload: isVisible,
});
export const setUserUpdateIsProfil = userProfil => ({
  type: AdminDataActionTypes.SET_USER_UPDATE_PROFIL,
  payload: userProfil,
});
export const updateUserProfilStart = () => ({
  type: AdminDataActionTypes.UPDATE_USER_PROFIL_START,
});
export const createNewDonationStart = () => ({
  type: AdminDataActionTypes.CREATE_NEW_DONATION_START,
});
export const deleteDonationStart = () => ({
  type: AdminDataActionTypes.DELETE_DONATION_START,
});
export const deletePartnerStart = () => ({
  type: AdminDataActionTypes.DELETE_PARTNERS_START,
});
export const setMediaText = (text, id) => ({
  type: AdminDataActionTypes.SET_MEDIA_TEXT,
  payload: { text, id },
});
export const createNewArticleStart = () => ({
  tye: AdminDataActionTypes.CREATE_NEW_ARTICLE,
});
export const deleteArticleStart = () => ({
  type: AdminDataActionTypes.DELETE_ARTICLE_START,
});
// ------------ ASYNC --------- //
export const updateMediaStartAsync = (idOfDataToUpdate, updatedData) => {
  return async dispatch => {
    dispatch(updateMediaStart());
    dispatch(setIsLoaderVisible(true));
    dispatch(setIsLoaderLoading(true));
    try {
      await firestore
        .collection('media')
        .doc(idOfDataToUpdate)
        .set(updatedData);
      dispatch(fetchMediaStartAsync());
      dispatch(setIsLoaderVisible(false));

      // Set visible loader and set message if successful
    } catch (err) {
      throw new Error(err);
    }
  };
};
export const updateMediaTextStartAsync = (idOfDataToUpdate, updatedText) => {
  return async dispatch => {
    dispatch(updateMediaStart());
    dispatch(setIsLoaderVisible(true));
    dispatch(setIsLoaderLoading(true));
    try {
      await firestore
        .collection('media')
        .doc(idOfDataToUpdate)
        .update({ content: updatedText });
      dispatch(fetchMediaStartAsync());

      // Set visible loader and set message if successful
    } catch (err) {
      throw new Error(err);
    }
  };
};

export const fetchMediaStartAsync = () => {
  return async dispatch => {
    dispatch(fetchMediaStart());
    dispatch(setIsLoaderVisible(true));
    dispatch(setIsLoaderLoading(true));
    try {
      const mediaRef = await firestore.collection('media').get();
      const mediaData = [];
      const mediaDocs = mediaRef.docs;
      mediaDocs.forEach(doc => {
        if (doc.exists) {
          mediaData.push(doc.data());
        }
      });
      dispatch(fetchMediaSuccess(mediaData));
      dispatch(setIsLoaderVisible(false));
    } catch (err) {
      throw new Error(err);
    }
  };
};

// Fetch Donations & Expenses for dashboard
const filterPaymentMethod = paymentMethod => {
  switch (paymentMethod) {
    case 'card':
      return 'Carte bancaire';
    case 'paypal':
      return 'Paypal';
    case 'wu':
      return 'Western Union';
    case 'ria':
      return 'Ria';
    case 'wireTransfer':
      return 'Virement Bancaire';
    case 'moneyGram':
      return 'Western Union';
    default:
      return paymentMethod;
  }
};
export const fetchCategoryStartAsync = category => {
  return async dispatch => {
    dispatch(setIsLoaderVisible(true));

    category === 'donations' && dispatch(fetchDonationsStart());
    category === 'expenses' && dispatch(fetchExpensesStart());

    try {
      // Get donations
      const d = await firestore.collection(category).get();
      const dataDocs = d.docs;
      const dataArray = dataDocs.map(doc => {
        let data = doc.data();

        data = {
          ...data,
          id: doc.id,
          paymentMethod: filterPaymentMethod(data.paymentMethod),
        };
        return data;
      });
      category === 'donations' && dispatch(fetchDonationsSuccess(dataArray));
      category === 'expenses' && dispatch(fetchExpensesSuccess(dataArray));
    } catch (err) {
      category === 'donations' && dispatch(fetchDonationsFailure(err.message));
      category === 'expenses' && dispatch(fetchExpensesFailure(err.message));
    }
  };
};
export const createNewExpenseStartAsync = form => {
  return async dispatch => {
    dispatch(setIsLoaderVisible(true));
    dispatch(setIsLoaderLoading(true));
    try {
      const { date, lastname, amount, typeOfExpense } = form;
      const formatedDate = formatDateFromInput(date);
      const expenseId = `${date}-${lastname}-${amount}`;
      await firestore.collection('expenses').doc(expenseId).set({
        date: formatedDate,
        amount,
        lastname,
        typeOfExpense,
      });
      await dispatch(fetchCategoryStartAsync('expenses'));

      dispatch(setIsLoaderLoading(false));
      dispatch(setTextInsideLoader("L'opération à bien été ajoutée."));
      dispatch(setIsLoaderClosing(true));
    } catch (err) {
      throw new Error(err);
    }
  };
};

// Partners
export const fetchPartnersStartAsync = () => {
  return async dispatch => {
    dispatch(setIsLoaderVisible(true));
    try {
      const partnersRef = await firestore.collection('partners').get();
      const partnersDocs = partnersRef.docs;
      const partners = partnersDocs.map(doc => {
        let data = doc.data();
        data = { ...data, id: doc.id };
        return data;
      });
      dispatch(fetchPartnersSuccess(partners));
    } catch (err) {
      dispatch(setIsLoaderVisible(false));
      dispatch(fetchPartnersFailure(err.message));
    }
  };
};
export const deletePartnerStartAsync = partnerId => {
  return async dispatch => {
    dispatch(setIsLoaderVisible(true));
    dispatch(setIsLoaderLoading(true));
    dispatch(deleteUserStart());
    try {
      await firebase.firestore().collection('partners').doc(partnerId).delete();
      dispatch(setIsLoaderVisible(true));
      dispatch(setIsLoaderLoading(false));
      dispatch(setIsLoaderSuccess(true));
      dispatch(setIsLoaderClosing(true));
      dispatch(setTextInsideLoader('Le don à bien été supprimé'));
      await dispatch(fetchPartnersStartAsync());
    } catch (err) {
      throw new Error(err);
    }
  };
};

// Members
export const fetchMembersStartAsync = () => {
  return async dispatch => {
    try {
      const membersRef = await firestore.collection('members').get();
      const membersDocs = membersRef.docs;
      const members = membersDocs.map(doc => doc.data());
      dispatch(fetchMembersSuccess(members));
    } catch (err) {
      dispatch(fetchMembersFailure(err.message));
    }
  };
};
export const deleteMemberStartAsync = memberEmail => {
  return async dispatch => {
    // Delete from member and showable in firestore
    dispatch(setIsLoaderVisible(true));
    dispatch(setIsLoaderLoading(true));
    dispatch(deleteUserStart());
    try {
      await firebase
        .firestore()
        .collection('members')
        .doc(memberEmail)
        .delete();
      await firebase
        .firestore()
        .collection('members-showable')
        .doc(memberEmail)
        .delete();

      dispatch(setIsLoaderVisible(true));
      dispatch(setIsLoaderLoading(false));
      dispatch(setIsLoaderSuccess(true));
      dispatch(setIsLoaderClosing(true));
      dispatch(setTextInsideLoader('Le membre à bien été supprimé'));
      await dispatch(fetchPartnersStartAsync());
    } catch (err) {
      throw new Error(err);
    }

    //
  };
};
// Admins
export const fetchAdminsStartAsync = () => {
  return async dispatch => {
    dispatch(setIsLoaderVisible(true));
    dispatch(fetchAdminsStart);
    try {
      const adminsRef = await firestore.collection('admins').get();
      const adminsDocs = adminsRef.docs;
      const admins = adminsDocs.map(doc => doc.data());
      dispatch(fetchAdminsSuccess(admins));
    } catch (err) {
      dispatch(fetchAdminsFailure(err));
    }
  };
};
export const createNewUserStartAsync = ({
  lastname,
  firstname,
  email,
  password,
  typeOfUser,
}) => {
  return async dispatch => {
    dispatch(setIsLoaderVisible(true));
    dispatch(createNewUserStart());
    try {
      await firestore.collection('admins').doc(email).set({
        lastname,
        firstname,
        email,
        typeOfAdmin: typeOfUser,
      });
      const createUser = firebase.functions().httpsCallable('adminCreateUser');
      const form = { email, password };
      await createUser(form);
      dispatch(setIsLoaderVisible(true));
      dispatch(setIsLoaderLoading(false));
      dispatch(setIsLoaderSuccess(true));
      dispatch(setIsLoaderClosing(true));
      dispatch(setTextInsideLoader('Le compte à bien été ajouté.'));
      await dispatch(fetchAdminsStartAsync());
    } catch (err) {
      dispatch(setIsLoaderVisible(true));
      dispatch(createNewUserFailure(err.message));
      dispatch(setIsLoaderLoading(false));
      dispatch(setTextInsideLoader(err.message));
    }
  };
};
export const deleteUserStartAsync = email => {
  return async dispatch => {
    dispatch(setIsLoaderVisible(true));
    dispatch(setIsLoaderLoading(true));
    dispatch(deleteUserStart());

    const userRecord = await dispatch(fetchUserByEmail(email.toString()));
    const userUid = userRecord.uid;
    const deleteUser = firebase.functions().httpsCallable('deleteUser');
    const deleteFunc = await deleteUser(userUid);
    await dispatch(deleteUserFromFirestore(email));

    if (deleteFunc.data.success) {
      dispatch(setIsLoaderVisible(true));
      dispatch(setIsLoaderLoading(false));
      dispatch(setIsLoaderSuccess(true));
      dispatch(setIsLoaderClosing(true));
      dispatch(setTextInsideLoader('Le compte à bien été supprimé'));
      await dispatch(fetchAdminsStartAsync());
    }
  };
};
export const fetchUserByEmail = email => {
  return async dispatch => {
    const fetchUserDataByEmail = firebase
      .functions()
      .httpsCallable('fetchUserByEmail');
    const data = await fetchUserDataByEmail(email);
    return data.data.userRecord;
  };
};
export const deleteUserFromFirestore = email => {
  return async dispatch => {
    await firebase
      .firestore()
      .collection('admins')
      .doc(email)
      .delete()
      .then(() => {
        return { success: true };
      });
  };
};
export const updateUserProfilStartAsync = userData => {
  const { email, firstname, lastname, typeOfUser } = userData;
  return async dispatch => {
    dispatch(setIsLoaderVisible(true));
    dispatch(setIsLoaderLoading(true));
    dispatch(updateUserProfilStart());

    try {
      await firebase.firestore().collection('admins').doc(email).update({
        firstname,
        lastname,
        email,
        typeOfAdmin: typeOfUser,
      });
      dispatch(setIsLoaderVisible(true));
      dispatch(setIsLoaderLoading(false));
      dispatch(setIsLoaderSuccess(true));
      dispatch(setIsLoaderClosing(true));
      dispatch(setTextInsideLoader('Le compte à bien été modifié'));
      await dispatch(fetchAdminsStartAsync());
    } catch (err) {
      throw new Error(err);
    }
  };
};

export const createNewDonationStartAsync = form => {
  return async dispatch => {
    dispatch(setIsLoaderVisible(true));
    dispatch(setIsLoaderLoading(true));
    dispatch(createNewUserStart());
    try {

      await storeDonationInFirestore(form);
      dispatch(setIsLoaderVisible(true));
      dispatch(setIsLoaderLoading(false));
      dispatch(setIsLoaderSuccess(true));
      dispatch(setIsLoaderClosing(true));
      dispatch(setTextInsideLoader('Le don à bien été enregistré'));
      await dispatch(fetchCategoryStartAsync('donations'));
    } catch (err) {
      throw new Error(err);
    }
  };
};
export const deleteDonationStartAsync = donationId => {
  return async dispatch => {
    dispatch(setIsLoaderVisible(true));
    dispatch(setIsLoaderLoading(true));
    dispatch(deleteUserStart());
    try {
      const donationRef = firebase
        .firestore()
        .collection('donations')
        .doc(donationId);

      await donationRef.get().then(async doc => {
        const donationPrevAmount = doc.data().amount;
        await donationRef.set(
          {
            amount: 0,
            refundedAmount: donationPrevAmount,
          },
          { merge: true }
        );
      });

      dispatch(setIsLoaderVisible(true));
      dispatch(setIsLoaderLoading(false));
      dispatch(setIsLoaderSuccess(true));
      dispatch(setIsLoaderClosing(true));
      dispatch(setTextInsideLoader('Le don à bien été supprimé'));
      await dispatch(fetchCategoryStartAsync('donations'));
    } catch (err) {
      throw new Error(err);
    }
  };
};
export const deleteExpenseStartAsync = expenseId => {
  return async dispatch => {
    try {
      dispatch(setIsLoaderVisible(true));
      dispatch(setIsLoaderLoading(true));
      dispatch(deleteExpenseStart());
      await firestore.collection('expenses').doc(expenseId).delete();
      dispatch(fetchCategoryStartAsync('expenses'));
      dispatch(setIsLoaderLoading(false));
      dispatch(setIsLoaderSuccess(true));
      dispatch(setIsLoaderClosing(true));
      dispatch(setTextInsideLoader("L'opération à bien été supprimée"));
    } catch (err) {
      throw new Error(err);
    }
  };
};
export const createNewArticleStartAsync = articleData => {
  return async dispatch => {
    dispatch(setIsLoaderLoading(true));
    dispatch(setIsLoaderVisible(true));

    // DD/MM/YYYY
    try {
      const date = createNewDate();
      const id = generateUid();
      const article = { ...articleData, id, date };
      await firestore.collection('media').doc(id).set(article);
      dispatch(setIsLoaderLoading(false));
      dispatch(setIsLoaderSuccess(false));
      dispatch(setIsLoaderReloading(true));
      dispatch(setTextInsideLoader("L'article à bien été ajouté"));
    } catch (err) {
      throw new Error(err);
    }
  };
};
export const deleteArticleStartAsync = articleId => {
  return async dispatch => {
    dispatch(setIsLoaderLoading(true));
    dispatch(setIsLoaderVisible(true));

    // DD/MM/YYYY
    try {
      await firestore.collection('media').doc(articleId).delete();
      dispatch(setIsLoaderLoading(false));
      dispatch(setIsLoaderSuccess(false));
      dispatch(setIsLoaderClosing(true));
      dispatch(setTextInsideLoader("L'article à bien été supprimé"));
      dispatch(fetchMediaStartAsync());
    } catch (err) {
      throw new Error(err);
    }
  };
};

// --------- Global Dashboard ---------- //
export const fetchDashboardStartAsync = () => {
  return async dispatch => {
    dispatch(setIsLoaderVisible(true));
    try {
      await dispatch(fetchCategoryStartAsync('donations'));
      await dispatch(fetchCategoryStartAsync('expenses'));
      await dispatch(fetchPartnersStartAsync());
      await dispatch(fetchMembersStartAsync());
      dispatch(setIsLoaderVisible(false));
    } catch (err) {
      dispatch(setIsLoaderVisible(false));
    }
  };
};
