import React from 'react';
import { connect } from 'react-redux';

import './partners.page.styles.scss';

const PartnersPage = ({ partners }) => {
  return (
    <div className='partner-page'>
      <div className='partner-page-container'>
        {partners.map((partner, idx) => {
          const { websiteUrl = '', logoUrl, name } = partner;
          return (
            <div key={idx} className='partner-card basic-container-100'>
              <img src={logoUrl} alt='partner' />
              <p className='small-title-green'>{name}</p>
              <a href={`https://${websiteUrl}`}>{websiteUrl}</a>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const mapStateToProps = ({ partners }) => ({
  partners: partners.partners,
});

export default connect(mapStateToProps)(PartnersPage);
