import React from 'react';

import './header-green-title-ul.component.styles.scss';

const HeaderGreenTitleUl = ({ title, text, li }) => {
  return (
    <div className='basic-container-90 margin40Center'>
      <p className='header-small-title'>{title}</p>
      <p>{text}</p>
      <ul className='header-green-title-ul-ul'>
        {li.map((i, idx) => (
          <li key={idx}>{i}</li>
        ))}
      </ul>
    </div>
  );
};

export default HeaderGreenTitleUl;
