import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectUserUpdateProfil } from '../../redux/admin-data/admin-data.selectors';
import { updateUserProfilStartAsync } from '../../redux/admin-data/admin-data.actions';

import Form from 'react-bootstrap/Form';

const AdminUpdateUser = ({ userProfil, updateUserProfil }) => {
  const [validated, setValidated] = useState(false);
  const [form, setForm] = useState({
    lastname: '',
    firstname: '',
    typeOfUser: 'admin',
    email: '',
    password: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setValidated(true);
    const formJsx = e.currentTarget;
    if (formJsx.checkValidity() === false) return e.stopPropagation();
    updateUserProfil(form);
  };
  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setForm({ ...form, [name]: value });
  };
  useEffect(() => {
    const { email, lastname, firstname, typeOfAdmin } = userProfil;
    setForm({ ...form, email, lastname, firstname, typeOfUser: typeOfAdmin });
  }, []);

  return (
    <div className='admin-users-add-user-container'>
      <Form
        method='POST'
        noValidate
        validated={validated}
        className='bootstrap-form'
        onSubmit={handleSubmit}
      >
        <Form.Group>
          <Form.Label>Nom</Form.Label>
          <Form.Control
            type='text'
            name='lastname'
            onChange={handleChange}
            value={form.lastname}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Prénoms</Form.Label>
          <Form.Control
            type='text'
            name='firstname'
            onChange={handleChange}
            value={form.firstname}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Type d'utilisateur</Form.Label>
          <Form.Control
            as='select'
            name='typeOfUser'
            onChange={handleChange}
            value={form.typeOfUser}
          >
            <option value='admin'>Admin</option>
            <option value='super_admin'>Super Admin</option>
            <option value='accounting'>Comptabilité</option>
            <option value='media'>Media</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type='email'
            name='email'
            onChange={handleChange}
            value={form.email}
            required
          />
        </Form.Group>
        <button className='green-button-1 marginTop10'>Enregistrer</button>
      </Form>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  userProfil: selectUserUpdateProfil,
});
const mapDispatchToProps = (dispatch) => ({
  updateUserProfil: (userData) =>
    dispatch(updateUserProfilStartAsync(userData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminUpdateUser);
