import { jsPDF } from 'jspdf';
import logo from '../assets/logo.png';
import sign from '../assets/sign.png';

import firebase from './firebase';

export const createDonationReceipt = async (
  donationNumber,
  civility,
  lastname,
  firstname,
  address,
  paymentMethod,
  amount,
  donationCurrency,
  date
) => {
  const doc = new jsPDF();

  // header pdf
  doc.addImage(logo, 'Png', 15, 15, 20, 20);
  doc.line(0, 40, 300, 40, 'F');
  doc.text('Reçu Fiscal', 100, 50, 'center');
  doc.line(0, 55, 300, 55, 'F');

  // Presentation
  doc.setFontSize(20);
  doc.text("REÇU FISCAL AU TITRE DES DONS A l'ASSOCIATION ", 15, 70);
  doc.text("POUR L'INDUSTRIALISATION ET LE DÉVELOPPEMENT ", 13, 80);
  doc.text("SOCIAL ET ECONOMIQUE DE L'AFRIQUE (A.I.D.S.E.A.) ", 13, 90);
  doc.text('Articles 200, 238 bis et 885-0 V bis A du  ', 40, 100);
  doc.text('code général des impôts (CGI)  ', 50, 110);

  // Content
  doc.setFontSize(18);
  doc.setFont('times', 'bold');
  doc.text("Numéro d'ordre du reçu:", 13, 130);
  doc.setFont('times', 'normal');
  doc.text(`${donationNumber}`, 85, 130.5); // ! Numero d'ordre

  doc.setFontSize(18);
  doc.setFont('times', 'bold');
  doc.text('Bénéficiaire des versements:', 13, 140);
  doc.setFont('times', 'normal');
  doc.text('ASSOCIATION POUR ', 93, 140.5);
  doc.text("L'INDUSTRIALISATION ET LE DÉVELOPPEMENT ", 13, 150.5);
  doc.text("SOCIAL ET ECONOMIQUE DE L'AFRIQUE (A.I.D.S.E.A.) ", 13, 160.5);

  doc.setFont('times', 'bold');
  doc.text("Objet de l'association:", 13, 180);
  doc.setFont('times', 'normal');
  doc.text("Promouvoir l'industrie et son ", 78, 180.5);
  doc.text('importante en Afrique et en Haïti et de contribuer ', 13, 190.5);
  doc.text(
    'au développement social et économique de cet territoires.',
    13,
    200.5
  );

  doc.setFont('times', 'bold');
  doc.text("Qualité de l'association:", 13, 220);
  doc.setFont('times', 'normal');
  doc.text("Organisme d'intérêt général ", 82, 220.5);

  doc.setFont('times', 'bold');
  doc.text('Donateur:', 13, 235);
  {
    civility.toLowerCase() === 'monsieur'
      ? doc.text('M. ', 13, 245.5)
      : doc.text('Mme ', 13, 245.5);
  }

  doc.text('Nom: ', 13, 255.5);
  doc.setFont('times', 'normal');
  doc.text(`${lastname}`, 30, 255.5); // ! Last name

  if (firstname) {
    doc.setFont('times', 'bold');
    doc.text('Prénom: ', 13, 265.5);
    doc.setFont('times', 'normal');
    doc.text(`${firstname}`, 38, 265.5); // ! First name
  }

  doc.setFont('times', 'bold');
  doc.text('Adresse: ', 13, 275.5);
  doc.setFont('times', 'normal');
  doc.text(`${address}`, 38, 275.5); // ! Adresse

  doc.addPage('a4', 'm');

  doc.setFont('times', 'bold');
  doc.text('Nature du don: ', 13, 20);
  doc.setFont('times', 'normal');
  doc.text('Don numéraire', 57, 20);

  doc.setFont('times', 'bold');
  doc.text('Forme du don: ', 13, 30);
  doc.setFont('times', 'normal');
  doc.text('Don en ligne', 55, 30);

  doc.setFont('times', 'bold');
  doc.text('Mode de versement: ', 13, 40);
  doc.setFont('times', 'normal');
  doc.text(`${paymentMethod === 'card' ? 'Carte bancaire' : 'paypal'}`, 69, 40); // ! Payment method

  doc.setFont('times', 'bold');
  doc.text('Date du versement: ', 13, 50);
  doc.setFont('times', 'normal');
  doc.text(`${date}`, 69, 50); // ! Date

  doc.setFont('times', 'normal');
  doc.text(
    "Je soussigné M. P-Henry DJOKOURY, Président de l'association",
    13,
    70
  );
  doc.text("ASSOCIATION POUR L'INDUSTRIALISATION ET LE ", 13, 80);
  doc.text("DÉVELOPPEMENT SOCIAL ET ECONOMIQUE DE L'AFRIQUE", 13, 90);
  doc.text(
    'Reconnais avoir reçu au titre des dons et versements ouvrant droit',
    13,
    100
  );
  doc.text("à réduction d'impôt, le montant de:", 13, 110);
  doc.text(`${amount} XOF`, 105, 110);

  doc.text(
    "Je soussigné M. P-Henry DJOKOURY, Président de l'association",
    13,
    70
  );
  doc.text(
    "Le bénéficiaire certifie sur l'honneur que les dons et versements qu'il ",
    13,
    125
  );
  doc.text(
    "reçoit ouvrent droit à la réduction d'impôt prévue à l'article 200 du CGI ",
    13,
    135
  );

  doc.setFont('times', 'bold');
  doc.text('Fait à Amiens / France le: ', 13, 145);
  doc.setFont('times', 'normal');
  doc.text(`${date}`, 85, 145); // ! Date

  doc.setFont('times', 'bold');
  doc.text('Signature du Président: ', 13, 155);
  doc.setFont('times', 'normal');
  doc.addImage(sign, 'Png', 50, 150, 110, 90);

  doc.save(`Donation-${donationNumber}.pdf`); // ! Num of bill
};
