import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { FR, EN } from '../../js/languagesData';

// Dependencies
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';

import './actuality-thumbnail.component.styles.scss';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';

const ActualityThumbnail = ({
  data: {
    isInternal,
    typeOfArticle,
    imageUrl,
    title,
    description,
    author,
    authorLink,
    link,
  },
  currentLanguage,
}) => {
  const languageData = currentLanguage === 'english' ? EN : FR;
  return (
    <div className='actuality-thumbnail'>
      <Card
        style={{
          boxShadow: '0px 3px 9px 2px #ebebeb',
          margin: '10px',
          minHeight: '96%',
        }}
      >
        <Card.Img variant='top' src={imageUrl} />
        <Card.Body
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Card.Title className='align-justify'>{title}</Card.Title>
          <Card.Text className='align-justify'>{description}</Card.Text>
          <Card.Text>
            {languageData.mediaPage.by}
            {isInternal ? (
              <span>{author} </span>
            ) : (
              <a href={authorLink} target='_blank' rel='noreferrer'>
                {' '}
                {author}{' '}
              </a>
            )}{' '}
            |{' '}
            {isInternal ? (
              <Link to={`media/${link}`} alt="Lire l'article">
                {' '}
                {typeOfArticle === 'text'
                  ? languageData.mediaPage.read
                  : languageData.mediaPage.watch}
              </Link>
            ) : (
              <a
                href={link}
                alt="Lire l'article"
                target='_blank'
                rel='noreferrer'
              >
                {' '}
                {typeOfArticle === 'text'
                  ? languageData.mediaPage.read
                  : languageData.mediaPage.watch}
              </a>
            )}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentLanguage: selectCurrentLanguage,
});

export default connect(mapStateToProps)(ActualityThumbnail);
