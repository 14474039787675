import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./partners.page.styles.scss";

import Table4 from "../../components/table-4/table-4.component";
import MaterialTable1 from "../../components/material-table-1/material-table-1.component";
import StateCard from "../../components/state-card/state-card.component";

import CardWithText from "../../components/card-text/card-text.component";

// Redux
import { createStructuredSelector } from "reselect";
import { selectCardIsVisible } from "../../redux/card-text/card-text.selectors";
import { fetchPartnersStartAsync } from "../../redux/admin-data/admin-data.actions";
import { selectPartners } from "../../redux/admin-data/admin-data.selectors";
import { setIsLoaderVisible } from "../../redux/loader/loader.actions";
import { selectIsLoaderVisible } from "../../redux/loader/loader.selectors";

// Components
import AreaChart3 from "../../components/area-chart-3/area-chart-3.component";

const AdminPartnersPage = ({
  isLoaderVisible,
  fetchPartners,
  partners,
  setIsLoaderVisible,
  isCardTextVisible,
}) => {
  const [form, setForm] = useState({ startDate: "", endDate: "", country: "" });
  const [isGraphVisible, setIsGraphVisible] = useState(false);
  const [searchField, setSearchField] = useState("");

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    const getData = async () => {
      await fetchPartners();
      setIsLoaderVisible(false);
    };
    getData();
  }, []);

  return (
    <div className="admin-users-page-container">
      <h3 className="admin-partners-h3">Les Partenaires</h3>
      {isLoaderVisible && <StateCard />}
      {isCardTextVisible && <CardWithText />}
      <button
        className="green-button-1"
        onClick={() => setIsGraphVisible(!isGraphVisible)}
      >
        {isGraphVisible ? "Fermer" : "Voir le graphique"}
      </button>
      {isGraphVisible && (
        <div className="admin-dashboard-chart">
          <div className="admin-dashboard-chart-button-container">
            <div className="admin-dashboard-chart-date marginBottom40">
              <label>Début</label>
              <input type="date" name="startDate" onChange={handleChange} />
              <label>Fin</label>
              <input type="date" name="endDate" onChange={handleChange} />
              <label>Pays</label>
              <input type="text" name="country" onChange={handleChange} />
            </div>
          </div>
          <AreaChart3
            startDate={form.startDate}
            endDate={form.endDate}
            country={form.country}
          />
        </div>
      )}

      <div className="admin-users-table-container marginTop40">
        <MaterialTable1 data={partners} title="Partenaires" />
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  partners: selectPartners,
  isLoaderVisible: selectIsLoaderVisible,
  isCardTextVisible: selectCardIsVisible,
});
const mapDispatchToProps = (dispatch) => ({
  fetchPartners: () => dispatch(fetchPartnersStartAsync()),
  setIsLoaderVisible: (isVisible) => dispatch(setIsLoaderVisible(isVisible)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminPartnersPage);
