import React, { useState } from 'react';
import { connect } from 'react-redux';

import { createNewExpenseStartAsync } from '../../redux/admin-data/admin-data.actions';

import Form from 'react-bootstrap/Form';

const AddAccountingOperationComponent = ({ createNewExpense }) => {
  const [validated, setValidated] = useState(false);
  const [form, setForm] = useState({
    date: '',
    lastname: '',
    amount: '',
    typeOfExpense: '',
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    createNewExpense(form);
  };
  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setForm({ ...form, [name]: value });
  };
  return (
    <div className='add-accounting-operation-container'>
      <div className='admin-users-add-user-container'>
        <Form
          method='POST'
          noValidate
          validated={validated}
          className='bootstrap-form'
          onSubmit={handleSubmit}
        >
          <Form.Group>
            <Form.Label>Date</Form.Label>
            <Form.Control
              type='date'
              name='date'
              onChange={handleChange}
              value={form.date}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Noms</Form.Label>
            <Form.Control
              type='text'
              name='lastname'
              onChange={handleChange}
              value={form.lastname}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Montant</Form.Label>
            <Form.Control
              type='number'
              name='amount'
              onChange={handleChange}
              value={form.amount}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Type de dépense</Form.Label>
            <Form.Control
              type='text'
              name='typeOfExpense'
              defaultValue={form.donationAmount}
              onChange={handleChange}
            ></Form.Control>
          </Form.Group>

          <button className='green-button-1 marginTop10' onClick={handleSubmit}>
            Enregistrer
          </button>
        </Form>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  createNewExpense: (form) => dispatch(createNewExpenseStartAsync(form)),
});
export default connect(
  null,
  mapDispatchToProps
)(AddAccountingOperationComponent);
