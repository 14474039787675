import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { Form } from 'react-bootstrap';

import TextEditor2 from '../text-editor-2/text-editor-2.component';
import {createNewArticleStartAsync} from '../../redux/admin-data/admin-data.actions';


import './add-article-form.component.styles.scss';

const AddArticleForm = ({createNewArticle}) => {
    const [validated, setValidated] = useState(false);
    const [form, setForm] = useState({
        typeOfArticle: 'text',
        isInternal: false, 
        link: '',
        imageUrl: '',
        title: '',
        description: '',
        author: '', 
        authorLink: '',
        content: ''
    });

    const handleChange = (e) => {
        const target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if(value === 'true')  value = true
        if(value === 'false')  value = false
        setForm({ ...form, [name]: value });
    };
    const handleSubmit = (e) => {
        createNewArticle(form)
    }
    let timeout; 
    const handleTextChange = (text) => {
        clearTimeout(timeout); 
        timeout = setTimeout(() => {
            setForm({...form, content: text})
        },1000)
        
    }

    return (
        <div className="add-article-form">
            <Form
                method='POST'
                noValidate
                validated={validated}
                className='bootstrap-form'
                onSubmit={handleSubmit}
            >
                <Form.Group>
                    <Form.Label>Type d'article</Form.Label>
                    <Form.Control
                        as='select'
                        name='typeOfArticle'
                        onChange={handleChange}
                        value={form.typeOfArticle}
                    >
                        <option value='text'>Texte</option>
                        <option value='video'>Vidéo</option>

                    </Form.Control>
                </Form.Group>
                {form.typeOfArticle === 'text' && (
                    <Form.Group>
                        <Form.Label>Appartenance</Form.Label>
                        <Form.Control
                            as='select'
                            name='isInternal'
                            onChange={handleChange}
                            value={form.isInternal}
                        >
                            <option value={true}>Interne</option>
                            <option value={false}>Externe</option>
                        </Form.Control>
                    </Form.Group>
                )}
                 
                      <Form.Group>
                      <Form.Label>Lien de {form.typeOfArticle==='video' ? 'la vidéo' : "l'article"}</Form.Label>
                      <Form.Control
                          type='text'
                          name='link'
                          placeholder={!form.isInternal ? 'https://youtu.be/KPBO08ROkJ4' : 'agriculture-en-afrique' }
                          onChange={handleChange}
                          value={form.link}
                      />
                  </Form.Group>
                  <Form.Group>
                      <Form.Label>Lien de l'image </Form.Label>
                      <Form.Control
                          type='text'
                          name='imageUrl'
                          placeholder='https://www.fratmat.info/media/k2/items/cache/b87de673beaea5362a30c6579b7607d0_XL.jpg'
                          onChange={handleChange}
                          value={form.imageUrl}
                      />
                  </Form.Group>
               

                <Form.Group>
                    <Form.Label>Titre</Form.Label>
                    <Form.Control
                         type='text'
                        name='title'
                        placeholder="Aliko Dangote : « Le Nigeria a commis l’erreur de préférer le pétrole à l’agriculture »"
                        onChange={handleChange}
                        value={form.title}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                         as='textarea'
                        name='description'
                        placeholder="Découvrez l'impact de l'agriculture et de l'agro-industrie en Afrique et comment nous pouvons les utiliser pour améliorer la qualité de vie des Africains"
                        onChange={handleChange}
                        value={form.description}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Auteur</Form.Label>
                    <Form.Control
                         type='text'
                        name='author'
                        placeholder='jeuneafrique.com'
                        onChange={handleChange}
                        value={form.author}
                    />
                </Form.Group>
                {!form.isInternal ? (
                     <Form.Group>
                     <Form.Label>Lien de l'auteur</Form.Label>
                     <Form.Control
                         type='text'
                         name='authorLink'
                         placeholder='https://www.fratmat.info'
                         onChange={handleChange}
                         value={form.authorLink}
                     />
                 </Form.Group>
                ): ''}
                {form.isInternal && (
                    <TextEditor2 handleTextChange={handleTextChange} />
                )}
                
            </Form>
            <button className='green-button-1 marginTop10'onClick={handleSubmit} >
                    Enregistrer
              </button>
        </div>

    )
}
const mapDispatchToProps = (dispatch) => ({
    createNewArticle: articleData => dispatch(createNewArticleStartAsync(articleData))
})

export default connect(null, mapDispatchToProps)(AddArticleForm);