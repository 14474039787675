import LanguageActionTypes from "./language.types";

export const setLanguage = language => ({
  type: LanguageActionTypes.SET_LANGUAGE,
  payload: language,
});

export const setLanguageFunction = language => {
  return async dispatch => {
    if (language.slice(0, 2).toLowerCase() === "en") {
      dispatch(setLanguage("english"));
    } else if (language.slice(0, 2).toLowerCase() === "fr") {
      dispatch(setLanguage("french"));
    }
  };
};
