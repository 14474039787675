import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { toggleNavbar } from "../../js/utils";
import firebase from "../../js/firebase";
import { adminLogout } from "../../redux/admin/admin.actions";

import "./header-admin.component.styles.scss";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

// Components

import Dropdown from "react-bootstrap/Dropdown";

const HeaderAdmin = ({ adminEmail, adminLogout, adminType }) => {
  const [isMenuHidden, setIsMenuHidden] = useState(true);
  const handleClick = () => {
    setIsMenuHidden(!isMenuHidden);
  };
  const handleSignOut = () => {
    firebase.auth().signOut();
    adminLogout();
  };

  return (
    <div className="header-admin">
      <h2 className="header-admin-logo">
        <Link to="/admin/dashboard">AIDSEA</Link>
      </h2>
      <div
        onClick={() => {
          toggleNavbar();
          handleClick();
        }}
        id="navbar-toggle-button"
        className="navbar-toggle-button"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      {/* Sidebar */}
      <div
        className={`header-admin-sidebar ${
          isMenuHidden ? "hideAdminSidebar" : ""
        }`}
      >
        <ul>
          {adminType === "super_admin" || adminType === "accounting" ? (
            <>
              <Link to="/admin/users">
                <li>Utilisateurs</li>
              </Link>
              <Link to="/admin/donations">
                <li>Dons</li>
              </Link>
              <Link to="/admin/partners">
                <li>Partenaires</li>
              </Link>
              <a>
                <li>
                  Membres <KeyboardArrowDownIcon />
                </li>
              </a>
              {/* Sub menu for members */}
              <Link to="/admin/members/normal" className="sub-member-nav">
                <li>Membres</li>
              </Link>
              <Link to="/admin/members/honorary" className="sub-member-nav">
                <li>Membres d'honneur</li>
              </Link>
              <Link to="/admin/members/benefactor" className="sub-member-nav">
                <li>Membres bienfaiteurs</li>
              </Link>
              <Link
                to="/admin/members/volunteer"
                className="sub-member-nav marginBottom40"
              >
                <li>Membres bénévoles</li>
              </Link>
              {/*  End sub menu for members */}
              <a>
                <li>
                  Gestion comptable <KeyboardArrowDownIcon />
                </li>
              </a>
              {/* Sub menu for gestion compta */}
              <Link to="/admin/accounting/incomes" className="sub-member-nav ">
                <li>Entrées</li>
              </Link>
              <Link to="/admin/accounting/expenses" className="sub-member-nav">
                <li>Sorties</li>
              </Link>
              <Link
                to="/admin/accounting/balance"
                className="sub-member-nav marginBottom40"
              >
                <li>Solde</li>
              </Link>
            </>
          ) : (
            ""
          )}
          {adminType === "admin" ? (
            <>
              <Link to="/admin/partners">
                <li>Partenaires</li>
              </Link>
              <a>
                <li>
                  Membres <KeyboardArrowDownIcon />
                </li>
              </a>
              {/* Sub menu for members */}
              <Link to="/admin/members/normal" className="sub-member-nav">
                <li>Membres</li>
              </Link>
              <Link to="/admin/members/honorary" className="sub-member-nav">
                <li>Membres d'honneur</li>
              </Link>
              <Link to="/admin/members/benefactor" className="sub-member-nav">
                <li>Membres bienfaiteurs</li>
              </Link>
              <Link
                to="/admin/members/volunteer"
                className="sub-member-nav marginBottom40"
              >
                <li>Membres bénévoles</li>
              </Link>
            </>
          ) : (
            ""
          )}
          {/*  End sub menu for  */}
          {adminType === "super_admin" ||
          adminType === "accounting" ||
          adminType === "media" ? (
            <Link to="/admin/media">
              <li>Média</li>
            </Link>
          ) : (
            ""
          )}
          <Link>
            <li>
              {adminEmail} <KeyboardArrowDownIcon />
            </li>
          </Link>
          <a className="sub-member-nav" onClick={handleSignOut}>
            <li>Déconnexion</li>
          </a>
        </ul>
      </div>
      <div className="header-admin-nav">
        <ul>
          {adminType === "super_admin" || adminType === "accounting" ? (
            <>
              <li>
                <Link to="/admin/users">Utilisateurs</Link>
              </li>
              <li>
                <Link to="/admin/donations">Dons</Link>
              </li>
              <li>
                <Link to="/admin/partners">Partenaires</Link>
              </li>
              <li>
                <div className="dropdown">
                  <p className="dropbtn">Membres</p>
                  <div className="dropdown-content">
                    {/* Sub menu for members */}
                    <Link to="/admin/members/normal" className="sub-member-nav">
                      Membres
                    </Link>
                    <Link
                      to="/admin/members/honorary"
                      className="sub-member-nav"
                    >
                      Membres d'honneurs
                    </Link>
                    <Link
                      className="sub-member-nav"
                      to="/admin/members/benefactor"
                    >
                      Membres bienfaiteurs
                    </Link>
                    <Link
                      to="/admin/members/volunteer"
                      className="sub-member-nav marginBottom40"
                    >
                      Membres bénévoles
                    </Link>
                    {/*  End sub menu for members */}
                  </div>
                </div>
              </li>
              <li>
                <div className="dropdown">
                  <p className="dropbtn">Gestion comptable</p>
                  <div className="dropdown-content compta-dropdown">
                    {/* Sub menu for members */}
                    <Link
                      to="/admin/accounting/incomes"
                      className="sub-member-nav"
                    >
                      Entrées
                    </Link>
                    <Link
                      to="/admin/accounting/expenses"
                      className="sub-member-nav"
                    >
                      Sorties
                    </Link>
                    <Link
                      to="/admin/accounting/balance"
                      className="sub-member-nav marginBottom40"
                    >
                      Solde
                    </Link>
                    {/*  End sub menu for members */}
                  </div>
                </div>
              </li>
            </>
          ) : (
            ""
          )}

          <li>
            <Link to="/admin/media">Média</Link>
          </li>
        </ul>
      </div>
      <div className="header-nav-name">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {adminEmail}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={handleSignOut}>Déconnexion</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
const mapStateToProps = ({ admin }) => ({
  adminEmail: admin.currentAdmin,
  adminType: admin.typeOfAdmin,
});
const mapDispatchToProps = dispatch => ({
  adminLogout: () => dispatch(adminLogout()),
});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderAdmin);
