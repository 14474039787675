import React from 'react';

import './admin-dashboard-big-card.component.styles.scss';

const AdminDashboardBigCard = ({
  isDonation,
  isBalance,
  isExpense,
  amount,
}) => {
  return (
    <div className='admin-dashboard-big-card'>
      <p
        className={`admin-dashboard-big-card-price ${
          isDonation && 'color-green'
        } ${isExpense && 'color-red'} ${isBalance && 'color-blue'}
        `}
      >
        {amount}€
      </p>
      <p className='admin-dashboard-big-card-title'>
        {isDonation && 'Les dons'}
        {isExpense && 'Les dépenses'}
        {isBalance && 'Total Disponible'}
      </p>
    </div>
  );
};

export default AdminDashboardBigCard;
