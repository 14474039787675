import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectIsLoaderVisible } from '../../redux/loader/loader.selectors';
import './become-partner.page.styles.scss';

// Components
import HeroTop from '../../components/hero-top/hero-top.component';
import HeaderGreenTitle from '../../components/header-green-title/header-green-title.component';
import FormAndImage from '../../components/form-and-image/form-and-image.component';
import StateCard from '../../components/state-card/state-card.component';

// Images
import partnerImg from '../../assets/images/partner.jpg';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';

import { FR, EN } from '../../js/languagesData';

const BecomePartnerPage = ({ isLoaderVisible, currentLanguage }) => {
  const languageData = currentLanguage === 'english' ? EN : FR;

  return (
    <div className='become-partner-page'>
      {isLoaderVisible && <StateCard />}
      <HeroTop
        title={languageData.partnerPage.title}
        img={partnerImg}
        isPartnerOrJoin={true}
      />
      {/*  Header Text */}
      <div className='become-partner-content'>
        <HeaderGreenTitle
          title={languageData.partnerPage.subtitle}
          text={languageData.partnerPage.description}
        />

        {/* Form & Image */}
        <FormAndImage img={partnerImg} />
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  isLoaderVisible: selectIsLoaderVisible,
  currentLanguage: selectCurrentLanguage,
});

export default connect(mapStateToProps)(BecomePartnerPage);
