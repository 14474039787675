import React from 'react';
import { connect } from 'react-redux';
import { FR, EN } from '../../js/languagesData';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';
import { createStructuredSelector } from 'reselect';

import './about-carousel-image.component.styles.scss';
// Components
import AboutCarouselText from '../about-carousel-text/about-carousel-text.component';

const AboutCarouselImage = ({ title, currentLanguage }) => {
  const languageData = currentLanguage === 'english' ? EN : FR;
  return (
    <div className='about-carousel-container'>
      <div className='about-carousel-image'>
        <marquee className='about-carousel-image-text'>
          {languageData.aboutPage.marqueeTitle}
        </marquee>
      </div>
      <AboutCarouselText title={title} />
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  currentLanguage: selectCurrentLanguage,
});
export default connect(mapStateToProps)(AboutCarouselImage);
