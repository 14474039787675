import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';

import { FR, EN } from '../../js/languagesData';

import { firestore } from '../../js/firebase';
import { selectDonationToken } from '../../redux/donations/donations.selectors';

// Components
import HeroTop from '../../components/hero-top/hero-top.component';
import HeaderGreenTitle from '../../components/header-green-title/header-green-title.component';
import FormAndImage from '../../components/form-and-image/form-and-image.component';
import wtspImg from '../../assets/images/wtsp.png';

// Images
import donateImg from '../../assets/images/donate.jpg';

const DonationPage = ({ currentLanguage, donationToken }) => {
  const history = useHistory();
  const languageData = currentLanguage === 'english' ? EN : FR;
  const [isSmartphone, setIsSmartphone] = useState(false);
  const setScreen = () => {
    if (window.screen.width <= 380) {
      setIsSmartphone(true);
    } else {
      setIsSmartphone(false);
    }
  };
  useEffect(() => {
    setScreen();
  }, []);
  window.onresize = () => {
    setScreen();
  };

  useEffect(async () => {
    if (donationToken !== '' && donationToken !== null) {
      const donationRef = firestore
        .collection('temporaryDonationsData')
        .doc(donationToken);
      donationRef.onSnapshot(async (doc) => {
        const data = doc.data();
        if (data?.isCancelled === true) {
          await donationRef.delete();
          history.push('/');
        }
      });
    }
  }, [donationToken]);


  return (
    <div className='join-page'>
      <HeroTop
        title={languageData.donationPage.title}
        img={donateImg}
        isDonationPage={true}
      />
      <div className='page-content-width-100'>
        <h2
          id='header-contact'
          className='header-small-title marginBottom-30'
          style={{ margin: '20px auto' }}
        >
          {languageData.donationPage.subTitle1}
          <br /> {languageData.donationPage.subTitle2}
        </h2>
        <HeaderGreenTitle text={languageData.donationPage.description} />

        <FormAndImage isDonation={true} />
        <div className='basic-container-90 margin0Center'>
          <p className='medium-p align-justify'>
            {languageData.donationPage.otherPaymentMethods.sentence1}
            <br />
            IBAN : FR76 1627 5003 0008 0007{' '}
            {isSmartphone && (
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            )}
            2843 553
            <br />
            BIC : CEPAFRPP627
            <br />
            <br />
            {languageData.donationPage.otherPaymentMethods.sentence2}
            <br />
            <br />
            {languageData.donationPage.otherPaymentMethods.lastname}
            <br />
            {languageData.donationPage.otherPaymentMethods.firstname} <br />
            {languageData.donationPage.otherPaymentMethods.forTheAssociation} :
            A.I.D.S.E.A / MAAM - 12 RUE FRÉDÉRIC PETIT - 80000 AMIENS.
            <br />
            <br />
            {languageData.donationPage.otherPaymentMethods.emailMessage}
            <a href='mailto:contacts@aidseafrica.org'>
              {' '}
              contacts@aidseafrica.org
            </a>{' '}
            {languageData.donationPage.otherPaymentMethods.whatsapp}
            <a
              href='https://wa.me/+33752541282'
              style={{ color: 'rgb(3, 121, 23)' }}
            >
              +33.7.52.54.12.82
              <img src={wtspImg} style={{ width: '20px', height: '20px' }} />
            </a>{' '}
            {languageData.donationPage.otherPaymentMethods.confirmation}
            <br />
            <br />
            {languageData.donationPage.otherPaymentMethods.thanks}
          </p>
          <br /> <br />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  currentLanguage: selectCurrentLanguage,
  donationToken: selectDonationToken,
});

export default connect(mapStateToProps)(DonationPage);
