import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import "./admin-dashboard.component.styles.scss";

// Databases & Redux
import { fetchDashboardStartAsync } from "../../redux/admin-data/admin-data.actions";
import {
  selectTotalDonationAmount,
  selectTotalExpenseAmount,
  selectPartners,
  selectSimpleMembers,
  selectHonoraryMembers,
  selectBenefactorMembers,
  selectVolunteerMembers,
  selectDonations,
} from "../../redux/admin-data/admin-data.selectors";
import { selectCurrentAdmin } from "../../redux/admin/admin.selectors";
import { selectIsLoaderVisible } from "../../redux/loader/loader.selectors";

// Components
import StateCard from "../../components/state-card/state-card.component";
import AdminDashboardBigCard from "../../components/admin-dashboard-big-card/admin-dashboard-big-card.component";
import AdminDashboardSmallCard from "../../components/admin-dashboard-small-card/admin-dashboard-small-card.component";
import AreaChartDashboard from "../../components/area-chart/area-chart.component";

const AdminDashboard = ({
  isLoaderVisible,
  selectIsLoaderVisible,
  currentAdmin,
  fetchDashboardStartAsync,
  donationTotal,
  expenseTotal,
  partners,
  simpleMembers = 0,
  honoraryMembers = 0,
  benefactors = 0,
  volunteers = 0,
  donations,
}) => {
  const [form, setForm] = useState({ startDate: "", endDate: "" });
  const [isGraphVisible, setIsGraphVisible] = useState(false);
  const [chartDataType, setChartDataType] = useState("donations");

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    document.title = "AIDSEA | Dashboard";
    fetchDashboardStartAsync();
  }, []);

  return (
    <>
      {isLoaderVisible && <StateCard />}
      <div className="admin-dashboard">
        <h3>Tableau de bord</h3>
        <div className="admin-dashboard-container">
          <div className="admin-dashboard-row">
            <AdminDashboardBigCard isDonation amount={donationTotal} />
            <AdminDashboardBigCard
              isBalance
              amount={donationTotal - expenseTotal}
            />
            <AdminDashboardBigCard isExpense amount={expenseTotal} />
          </div>
          <div className="admin-dashboard-button-container">
            <button
              className="green-button-1"
              onClick={() => setIsGraphVisible(!isGraphVisible)}
            >
              {`${isGraphVisible ? "Fermer" : "Voir"} le graphique`}
            </button>
          </div>

          {isGraphVisible && (
            <div className="admin-dashboard-chart">
              <div className="admin-dashboard-chart-button-container">
                <div className="admin-dashboard-btn">
                  <button
                    className="green-button-1"
                    onClick={() => setChartDataType("donations")}
                  >
                    Dons
                  </button>
                  <button
                    className="green-button-1"
                    onClick={() => setChartDataType("expenses")}
                  >
                    Dépenses
                  </button>
                </div>
                <div className="admin-dashboard-chart-date">
                  <label>Début</label>
                  <input type="date" name="startDate" onChange={handleChange} />
                  <label>Fin</label>
                  <input type="date" name="endDate" onChange={handleChange} />
                </div>
              </div>
              <AreaChartDashboard
                chartDataType={chartDataType}
                startDate={form.startDate}
                endDate={form.endDate}
              />
            </div>
          )}

          <div className="admin-dashboard-divider" />
          <div className="admin-dashboard-row">
            <AdminDashboardSmallCard
              isPartners
              title="Total des partenaires"
              number={partners.length}
            />
            <AdminDashboardSmallCard
              title="Membres ordinaires"
              isExpense
              number={simpleMembers.length}
            />
            <AdminDashboardSmallCard
              title="Membres d'honneurs"
              isDonation
              number={honoraryMembers.length}
            />
            <AdminDashboardSmallCard
              title="Membres bienfaiteurs"
              isExpense
              number={benefactors.length}
            />
            <AdminDashboardSmallCard
              title="Membres bénévoles"
              isExpense
              number={volunteers.length}
            />
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  currentAdmin: selectCurrentAdmin,
  donations: selectDonations,
  donationTotal: selectTotalDonationAmount,
  expenseTotal: selectTotalExpenseAmount,
  isLoaderVisible: selectIsLoaderVisible,
  partners: selectPartners,
  simpleMembers: selectSimpleMembers,
  honoraryMembers: selectHonoraryMembers,
  benefactors: selectBenefactorMembers,
  volunteers: selectVolunteerMembers,
});
const mapDispatchToProps = (dispatch) => ({
  fetchDashboardStartAsync: () => dispatch(fetchDashboardStartAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
