import React, { useState, useEffect } from 'react';

// Root css
import './general-classes.styles.scss';

// Dependencies
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import { setLanguageFunction } from './redux/language/language.actions';

import firebase from './js/firebase';

// Fixed components
import Header from './components/header/header.component';
import NavbarMenu from './components/navbar-menu/navbar-menu.component';
import Footer from './components/footer/footer.component';
import HeaderAdmin from './components/header-admin/header-admin.component';
import CookieConsentCard from './components/cookie-consent/cookie-consent.component';
import LanguageSelector from './components/languageSelector/languageSelector';

// Pages
import Homepage from './pages/homepage/homepage.page';
import AboutPage from './pages/about/about.page';
import OfficialDocumentsPage from './pages/official-documents/official-documents.page';
import BecomePartnerPage from './pages/become-partner/become-partner.page';
import ContactPage from './pages/contact/contact.page';
import TermsAndConditionsPage from './pages/terms-and-conditions/terms-and-conditions.page';
import AidseaMediaPage from './pages/aidsea-media/aidsea-media.page';
import JoinPage from './pages/join-page/join-page';
import DonationPage from './pages/donation/donation.page';
import PartnersPage from './pages/partners/partners.page';
import MediaArticlePage from './pages/media-article-page/media-article.page';
import PaymentPage from './pages/payment/payment.page.jsx';

// ADMIN SECTION
import AdminLoginPage from './pages/admin-login/admin-login.page';
import AdminDashboard from './pages/admin-dashboard/admin-dashboard.component';
import AdminUsersPage from './pages/admin-users/admin-users.page';
import DonationsPage from './pages/donations-page/donations.page';
import AdminPartnersPage from './pages/partners-page/partners.page';
import AdminMembersPage from './pages/admin-members/admin-members.page';
import AccountingPage from './pages/accounting-page/accounting.page';
import AdminMediaPage from './pages/admin-media/admin-media.page';

import { FR, EN } from './js/languagesData';

const App = ({
  partners,
  history,
  currentAdmin,
  cookiesAreAccepted,
  setLanguageForApp,
  currentLanguage,
}) => {
  const languageData = currentLanguage === 'english' ? EN : FR;
  const [isMenuHidden, setIsMenuHidden] = useState(true);
  const handleOnClick = () => {
    setIsMenuHidden(!isMenuHidden);
  };
  useEffect(() => {
    setLanguageForApp(navigator.language);
  }, []);

  if (history.location.pathname.includes('/admin')) {
    return (
      <>
        <Switch className='app'>
          <Route path='/admin/members/:id'>
            <HeaderAdmin />
            {!currentAdmin ? (
              <Redirect to='/admin/login' />
            ) : (
              <AdminMembersPage />
            )}
          </Route>
          <Route path='/admin/media'>
            <HeaderAdmin />
            {!currentAdmin ? (
              <Redirect to='/admin/login' />
            ) : (
              <AdminMediaPage />
            )}
          </Route>
          <Route path='/admin/accounting/:id'>
            <HeaderAdmin />
            {!currentAdmin ? (
              <Redirect to='/admin/login' />
            ) : (
              <AccountingPage />
            )}
          </Route>
          <Route path='/admin/dashboard'>
            <HeaderAdmin />
            {!currentAdmin ? (
              <Redirect to='/admin/login' />
            ) : (
              <AdminDashboard />
            )}
          </Route>
          <Route exact path='/admin'>
            <Redirect to='/admin/dashboard' />
          </Route>
          <Route path='/admin/donations'>
            <HeaderAdmin />
            {!currentAdmin ? <Redirect to='/admin/login' /> : <DonationsPage />}
          </Route>
          <Route path='/admin/partners'>
            <HeaderAdmin />
            {!currentAdmin ? (
              <Redirect to='/admin/login' />
            ) : (
              <AdminPartnersPage />
            )}
          </Route>

          <Route path='/admin/users'>
            <HeaderAdmin />
            {!currentAdmin ? (
              <Redirect to='/admin/login' />
            ) : (
              <AdminUsersPage />
            )}
          </Route>

          <Route path='/admin/login'>
            {currentAdmin ? (
              <Redirect to='/admin/dashboard' />
            ) : (
              <AdminLoginPage />
            )}
          </Route>
        </Switch>
      </>
    );
  } else {
    return (
      <>
        {/* Header fixed on every page */}
        <Header handleClick={handleOnClick} />
        {/* Language Selector */}

        <LanguageSelector />
        {cookiesAreAccepted === null && <CookieConsentCard />}
        {/*  On click, set cookies are accepted to the value then see for cokkie managment */}
        <NavbarMenu />
        {/* App */}

        <Switch className='App'>
          {/* CLIENT SECTION */}
          <Route path='/media/:id'>
            <MediaArticlePage />
          </Route>
          <Route
            path='/partners'
            render={(props) => <PartnersPage {...props} />}
          />
          <Route
            path='/donation'
            render={(props) => <DonationPage {...props} />}
          />

          <Route
            path='/join/member'
            render={(props) => (
              <JoinPage
                {...props}
                title='membre'
                text={languageData.joinPage.member.mainText}
              />
            )}
          />
          <Route
            path='/join/honorary-member'
            render={(props) => (
              <JoinPage
                {...props}
                title="membre d'honneur"
                text={languageData.joinPage.honoraryMember.mainText}
              />
            )}
          />
          <Route
            path='/join/benefactor'
            render={(props) => (
              <JoinPage
                {...props}
                title='membre bienfaiteur'
                text={languageData.joinPage.benefactors.mainText}
              />
            )}
          />
          <Route
            path='/join/volunteer'
            render={(props) => (
              <JoinPage
                {...props}
                title='bénévole'
                text={languageData.joinPage.volunteer.mainText}
              />
            )}
          />
          <Route
            path='/become-partner'
            render={(props) => <BecomePartnerPage {...props} />}
          />

          <Route
            path='/media'
            render={(props) => <AidseaMediaPage {...props} />}
          />
          <Route
            path='/contact'
            render={(props) => <ContactPage {...props} />}
          />
          <Route
            path='/terms-and-conditions'
            render={(props) => <TermsAndConditionsPage {...props} />}
          />
          <Route path='/about' render={(props) => <AboutPage {...props} />} />
          <Route
            path='/official-documents'
            render={(props) => <OfficialDocumentsPage {...props} />}
          />
          <Route
            path='/payment'
            render={(props) => <PaymentPage {...props} />}
          />

          <Route
            path='/'
            render={(props) => <Homepage {...props} partners={partners} />}
          />
        </Switch>
        {/* Footer on every page */}
        <Footer />
      </>
    );
  }
};
const mapStateToProps = ({ partners, admin, cookies, language }) => ({
  partners: partners.partners,
  currentAdmin: admin.currentAdmin,
  cookiesAreAccepted: cookies.cookiesAreAccepted,
  currentLanguage: language.language,
});
const mapDispatchToProps = (dispatch) => ({
  setLanguageForApp: (language) => dispatch(setLanguageFunction(language)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
