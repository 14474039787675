import { createSelector } from 'reselect';

const selectLoader = (state) => state.loader;

export const selectIsLoaderVisible = createSelector(
  [selectLoader],
  (loader) => loader.isLoaderVisible
);
export const selectIsLoaderClosing = createSelector(
  [selectLoader],
  (loader) => loader.isClosing
);
export const selectIsLoaderReloading = createSelector(
  [selectLoader],
  loader => loader.isReloading
)
