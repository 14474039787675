import CookiesActionTypes from "./cookies.types";

const INITIAL_STATE = {
  cookiesAreAccepted: null,
};

const CookieReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CookiesActionTypes.SET_IS_COOKIE_ACCEPTED:
      return {
        ...state,
        cookiesAreAccepted: action.payload,
      };
    default:
      return state;
  }
};

export default CookieReducer;
