import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectPartners } from "../../redux/admin-data/admin-data.selectors";

import { arrayOfDaysForPartners } from "../../js/utils";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const AreaChartDonations = ({
  startDate,
  endDate,
  partnersData,
  country,
  email,
  donationType,
}) => {
  let formatedData = arrayOfDaysForPartners(
    startDate,
    endDate,
    partnersData,
    country
  );
  let partnersTotal = 0;
  const dataForGraph = [];
  formatedData.map((day) => {
    if (day.partner) {
      partnersTotal = parseInt(partnersTotal) + parseInt(day.partner);
    }
    dataForGraph.push({
      name: day.date,
      partenaires: partnersTotal,
    });
  });
  return (
    <ResponsiveContainer>
      <AreaChart
        data={dataForGraph}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="partenaires"
          stroke="#0db539"
          fill="#0db539"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
const mapStateToProps = createStructuredSelector({
  partnersData: selectPartners,
});
export default connect(mapStateToProps)(AreaChartDonations);
