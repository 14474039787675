import firebase from 'firebase/app';
import 'firebase/auth';

import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

// !Dev
// const firebaseConfig = {
//   apiKey: 'AIzaSyBewsLMmyZNm1u0MYei9pq7FVB5w5O02PA',
//   authDomain: 'aidsea-dev.firebaseapp.com',
//   projectId: 'aidsea-dev',
//   storageBucket: 'aidsea-dev.appspot.com',
//   messagingSenderId: '147808948212',
//   appId: '1:147808948212:web:7c879983ac03fe93edb3a1',
// };
// // ! Production
const firebaseConfig = {
  apiKey: 'AIzaSyDe64VcnYvOLBfSUDI24i1OJGU7XRilHHs',
  authDomain: 'aidsea-b19de.firebaseapp.com',
  projectId: 'aidsea-b19de',
  storageBucket: 'aidsea-b19de.appspot.com',
  messagingSenderId: '1098708071822',
  appId: '1:1098708071822:web:cfca543e1ae78735ec20df',
  measurementId: 'G-TER9F10R83',
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();

export default firebase;
