const AdminActionTypes = {
  ADMIN_LOGIN_START: "ADMIN_LOGIN_START",
  ADMIN_LOGIN_SUCCESS: "ADMIN_LOGIN_SUCCESS",
  ADMIN_LOGIN_FAILURE: "ADMIN_LOGIN_FAILURE",
  ADMIN_LOGOUT_START: "SET_ADMIN_LOGOUT_START",
  SET_ADMIN_TYPE: "SET_ADMIN_TYPE",
  FETCH_PROJECTS_START: "FETCH_PROJECTS_START",
  FETCH_PROJECTS_SUCCESS: "FETCH_PROJECTS_SUCCESS",
  DELETE_PROJECT: "DELETE_PROJECT",
  CREATE_PROJECT: "CREATE_PROJECT",
};
export default AdminActionTypes;
