import React, { useState } from 'react';
import { connect } from 'react-redux';

import './join-form.component.styles.scss';

// Redux
import {
  setIsLoaderVisible,
  setIsLoaderLoading,
  setTextInsideLoader,
  setIsLoaderSuccess,
} from '../../redux/loader/loader.actions';

// Components
import Form from 'react-bootstrap/Form';
import { GreenButton1 } from '../buttons/buttons.component';
import Feedback from 'react-bootstrap/esm/Feedback';
import {
  createMemberProfile,
  countryList,
  sendEmailOnNewMember,
} from '../../js/utils';

import reglementInterieurPDF from '../../assets/official-docs/reglement_interieur.pdf';
import { createStructuredSelector } from 'reselect';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';
import { FR, EN } from '../../js/languagesData';

const JoinForm = ({
  title,
  setIsLoaderVisible,
  setIsLoaderLoading,
  setIsLoaderSuccess,
  setTextInsideLoader,
  currentLanguage,
}) => {
  const languageData = currentLanguage === 'english' ? EN : FR;
  const [validated, setValidated] = useState(false);

  const [form, setForm] = useState({
    lastname: '',
    firstname: '',
    civility: 'monsieur',
    email: '',
    phone: '',
    dateOfBirth: '',
    address: '',
    placeOfBirth: '',
    activity: '',
    country: 'afghanistan',
    presentation: '',
    rulesOfProcedures: false,
    memberType: title,
  });

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formJsx = e.currentTarget;
    setValidated(true);

    // Check validity on bootstrap form
    if (formJsx.checkValidity() === false) return e.stopPropagation();
    // IF form is complet & checkbox is accepted, create member profile in firestore
    if (form.rulesOfProcedures) {
      setIsLoaderVisible(true);
      // Create User Profile
      const memberProfile = await createMemberProfile(form);

      if (memberProfile) {
        setIsLoaderLoading(false);
        // Partner already exists
        setTextInsideLoader(languageData.form.partnerEmailAlreadyUsed);
      } else {
        await sendEmailOnNewMember(form);

        // Greetings on successful
        setIsLoaderLoading(false);
        setIsLoaderSuccess(true);
        setTextInsideLoader(languageData.form.thanksMembers);
      }
    }
  };

  return (
    <Form
      method='POST'
      noValidate
      validated={validated}
      className='bootstrap-form'
      onSubmit={handleSubmit}
    >
      <Form.Group>
        <Form.Label>{languageData.donationPage.form.input6}</Form.Label>
        <Form.Control
          as='select'
          name='civility'
          defaultValue={form.civility}
          onChange={handleChange}
          required
        >
          <option>{languageData.donationPage.form.input601}</option>
          <option>{languageData.donationPage.form.input602}</option>
          <option>{languageData.donationPage.form.input603}</option>
        </Form.Control>
      </Form.Group>
      <Form.Group>
        <Form.Label>{languageData.donationPage.form.input7}</Form.Label>
        <Form.Control
          type='text'
          name='lastname'
          defaultValue={form.lastname}
          onChange={handleChange}
          required
        />
        <Feedback type='invalid'>{languageData.form.errors.lastname}</Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{languageData.donationPage.form.input8}</Form.Label>
        <Form.Control
          type='text'
          name='firstname'
          defaultValue={form.firstname}
          onChange={handleChange}
          required
        />
        <Feedback type='invalid'>{languageData.form.errors.firstname}</Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{languageData.form.dateOfBirth}</Form.Label>
        <Form.Control
          type='date'
          name='dateOfBirth'
          onChange={handleChange}
          required
        />
        <Feedback type='invalid'>
          {languageData.form.errors.dateOfBirth}
        </Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{languageData.form.placeOfBirth}</Form.Label>
        <Form.Control
          type='text'
          name='placeOfBirth'
          onChange={handleChange}
          required
        />
        <Feedback type='invalid'>
          {languageData.form.errors.placeOfBirth}
        </Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{languageData.donationPage.form.input12}</Form.Label>
        <Form.Control
          type='text'
          name='activity'
          onChange={handleChange}
          required
        />
        <Feedback type='invalid'></Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{languageData.form.country}</Form.Label>
        <Form.Control
          as='select'
          name='country'
          onChange={handleChange}
          required
        >
          {countryList.map((country, i) => (
            <option key={i} value={country}>
              {country}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>{languageData.form.address}</Form.Label>
        <Form.Control
          type='text'
          name='address'
          onChange={handleChange}
          required
        />
        <Feedback type='invalid'>
          {languageData.form.errors.fullMailingAddress}
        </Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>{languageData.donationPage.form.input11}</Form.Label>
        <Form.Control
          onChange={handleChange}
          required
          type='number'
          name='phone'
          placeholder='Ex: +33 000 000 000'
        />
        <Feedback type='invalid'>{languageData.form.errors.phone}</Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>{languageData.donationPage.form.input9}</Form.Label>
        <Form.Control
          type='email'
          name='email'
          onChange={handleChange}
          required
        />
        <Feedback type='invalid'>{languageData.form.errors.email}</Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{languageData.form.reasonsApplication}</Form.Label>
        <Form.Control
          as='textarea'
          name='presentation'
          rows={3}
          placeholder={languageData.form.reasonsApplicationDescription}
          onChange={handleChange}
          required
        />
        <Feedback type='invalid'>
          {languageData.form.errors.fillInField}
        </Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Check
          className='align-justify'
          type='checkbox'
          name='rulesOfProcedures'
          onChange={handleChange}
          required
          label={languageData.form.rulesOfProcedureAcknowledgment}
        />
        <a
          href={reglementInterieurPDF}
          target='_blank'
          rel='noreferrer'
          className='paddingLeft20'
        >
          {languageData.donationPage.form.input13}
        </a>
      </Form.Group>
      <GreenButton1 title='Valider' className='margin0Center' />
    </Form>
  );
};
const mapStateToProps = createStructuredSelector({
  currentLanguage: selectCurrentLanguage,
});
const mapDispatchToProps = (dispatch) => ({
  setIsLoaderVisible: (isVisible) => dispatch(setIsLoaderVisible(isVisible)),
  setIsLoaderLoading: (isLoading) => dispatch(setIsLoaderLoading(isLoading)),
  setTextInsideLoader: (text) => dispatch(setTextInsideLoader(text)),
  setIsLoaderSuccess: (isLoaderSuccess) =>
    dispatch(setIsLoaderSuccess(isLoaderSuccess)),
});
export default connect(mapStateToProps, mapDispatchToProps)(JoinForm);

// Required
