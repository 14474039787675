import React from 'react';
import { connect } from 'react-redux';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';
import { FR, EN } from '../../js/languagesData';

// Dependencies
import './carousel.component.styles.scss';
import Carousel from 'react-bootstrap/Carousel';

// Images
import slider0 from '../../assets/images/slider-0.jpg';
import slider1 from '../../assets/images/slider-1.jpg';
import slider2 from '../../assets/images/slider-2.jpg';
import slider3 from '../../assets/images/slider-3.jpg';
import slider1phone from '../../assets/images/slider-phone-1.jpg';
import slider2phone from '../../assets/images/slider-phone-2.jpg';
import slider3phone from '../../assets/images/slider-phone-3.jpg';

import { createStructuredSelector } from 'reselect';

const HeroCarousel = ({ isSmartphone, currentLanguage }) => {
  const languageData = currentLanguage === 'english' ? EN : FR;
  return (
    <Carousel className='hero-carousel'>
      {isSmartphone ? (
        <Carousel.Item interval={5000}>
          <img className='d-block w-100' src={slider0} alt='First slide' />
          <Carousel.Caption>
            <h3>{languageData.heroCarousel.phoneTitle1}</h3>
          </Carousel.Caption>
        </Carousel.Item>
      ) : (
        ''
      )}
      <Carousel.Item interval={5000}>
        <img
          className='d-block w-100'
          src={isSmartphone ? slider1phone : slider1}
          alt='First slide'
        />
        <Carousel.Caption>
          <h3>{languageData.heroCarousel.title1}</h3>
          <p className='align-justify'>
            {languageData.heroCarousel.description1}
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={5000}>
        <img
          className='d-block w-100'
          src={isSmartphone ? slider2phone : slider2}
          alt='Third slide'
        />
        <Carousel.Caption>
          <h3>{languageData.heroCarousel.title2}</h3>
          <p className='align-justify'>
            {languageData.heroCarousel.description2}
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={5000}>
        <img
          className='d-block w-100'
          src={isSmartphone ? slider3phone : slider3}
          alt='Third slide'
        />
        <Carousel.Caption>
          <h3>{languageData.heroCarousel.title3}</h3>
          <p className='align-justify'>
            {languageData.heroCarousel.description3}
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};
const mapStateToProps = createStructuredSelector({
  currentLanguage: selectCurrentLanguage,
});

export default connect(mapStateToProps)(HeroCarousel);
