import React from 'react';
import { connect } from 'react-redux';

import {
  selectAdmins,
  selectIsUserUpdateVisible,
} from '../../redux/admin-data/admin-data.selectors';
import {
  deleteUserStartAsync,
  setUserUpdateIsProfil,
  setUserUpdateIsVisible,
} from '../../redux/admin-data/admin-data.actions';

import Table from 'react-bootstrap/Table';
import { createStructuredSelector } from 'reselect';

const Table2 = ({
  admins,
  searchFieldVal,
  deleteUserStart,
  setUserUpdateIsVisible,
  isUserUpdateVisible,
  setUserUpdateIsProfil,
}) => {
  const filteredAdmins = admins.filter((admin) => {
    return (
      admin.lastname.toLowerCase().includes(searchFieldVal.toLowerCase()) ||
      admin.firstname.toLowerCase().includes(searchFieldVal.toLowerCase()) ||
      admin.email.toLowerCase().includes(searchFieldVal.toLowerCase()) ||
      admin.typeOfAdmin.toLowerCase().includes(searchFieldVal.toLowerCase())
    );
  });
  return (
    <Table striped bordered hover responsive className='admin-table'>
      <thead>
        <tr>
          <th>Nom</th>
          <th>Prénoms</th>
          <th>Type d'utilisateur</th>
          <th>Email</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {searchFieldVal === ''
          ? admins.map(({ email, lastname, firstname, typeOfAdmin }) => (
              <tr key={email}>
                <td>{lastname}</td>
                <td>{firstname}</td>
                <td>
                  {typeOfAdmin === 'super_admin' ? 'Super admin' : typeOfAdmin}
                </td>
                <td>{email}</td>
                <td>
                  <button
                    className='blue-button-1'
                    onClick={() => {
                      setUserUpdateIsVisible(!isUserUpdateVisible);
                      setUserUpdateIsProfil({
                        email,
                        lastname,
                        firstname,
                        typeOfAdmin,
                      });
                    }}
                  >
                    Modifier
                  </button>
                  <button
                    className='red-button-1'
                    onClick={() => deleteUserStart(email)}
                  >
                    Supprimer
                  </button>
                </td>
              </tr>
            ))
          : filteredAdmins.map(
              ({ email, lastname, firstname, typeOfAdmin }) => (
                <tr key={email}>
                  <td>{lastname}</td>
                  <td>{firstname}</td>
                  <td>
                    {typeOfAdmin === 'super_admin'
                      ? 'Super admin'
                      : typeOfAdmin}
                  </td>
                  <td>{email}</td>
                  <td>
                    <button
                      className='blue-button-1'
                      onClick={() => {
                        setUserUpdateIsVisible(!isUserUpdateVisible);
                        setUserUpdateIsProfil({
                          email,
                          lastname,
                          firstname,
                          typeOfAdmin,
                        });
                      }}
                    >
                      Modifier
                    </button>
                    <button
                      onClick={() => deleteUserStart(email)}
                      className='red-button-1'
                    >
                      Supprimer
                    </button>
                  </td>
                </tr>
              )
            )}
      </tbody>
    </Table>
  );
};
const mapStateToProps = createStructuredSelector({
  admins: selectAdmins,
  isUserUpdateVisible: selectIsUserUpdateVisible,
});
const mapDispatchToProps = (dispatch) => ({
  deleteUserStart: (email) => dispatch(deleteUserStartAsync(email)),
  setUserUpdateIsVisible: (isVisible) =>
    dispatch(setUserUpdateIsVisible(isVisible)),
  setUserUpdateIsProfil: (userData) =>
    dispatch(setUserUpdateIsProfil(userData)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Table2);
