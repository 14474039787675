import React from 'react';

// Whatsapp image
import whatsappImg from '../../assets/images/wtsp.png';

const HeaderGreenTitle = ({ title, text, text2, whatsappLink = false }) => {
  return (
    <div className='basic-container-50 margin40Center'>
      <p className='header-small-title fontSize24'>{title}</p>
      {whatsappLink ? (
        <div className=''>
          <a
            href='https://wa.me/+33781219829'
            className='color-black fontSize18'
          >
            {text}
          </a>
          <img
            src={whatsappImg}
            alt='Whatsapp'
            style={{ width: '40px', transform: 'translateY(-30%)' }}
          />
        </div>
      ) : (
        <p className='bottom-text fontSize18 align-justify'>{text}</p>
      )}

      {text2 ? <p className='fontSize18'>{text2}</p> : false}
    </div>
  );
};

export default HeaderGreenTitle;
