import AdminActionTypes from "./admin.types";
import firebase, { firestore } from "../../js/firebase";

import {
  setIsLoaderLoading,
  setIsLoaderVisible,
  setTextInsideLoader,
} from "../loader/loader.actions";
import AdminDataActionTypes from "../admin-data/admin-data.types";

// -------------- ACTIONS --------------- //

export const adminLoginStart = () => ({
  type: AdminActionTypes.ADMIN_LOGIN_START,
});
export const adminLogout = () => ({
  type: AdminActionTypes.ADMIN_LOGOUT_START,
});
export const adminLoginSuccess = currentAdmin => ({
  type: AdminActionTypes.ADMIN_LOGIN_SUCCESS,
  payload: currentAdmin,
});
export const adminLoginFailure = errorMessage => ({
  type: AdminActionTypes.ADMIN_LOGIN_FAILURE,
  payload: errorMessage,
});
export const setAdminType = adminType => ({
  type: AdminActionTypes.SET_ADMIN_TYPE,
  payload: adminType,
});
export const setAdminTypeFromEmail = email => {
  return async dispatch => {
    const adminDB = firebase.firestore().collection("admins");
    const adminRef = adminDB.doc(email);

    adminRef.get().then(doc => {
      const adminData = doc.data();

      dispatch(setAdminType(adminData.typeOfAdmin));
    });
  };
};

export const fetchProjectsStart = () => ({
  type: AdminActionTypes.FETCH_PROJECTS_START,
});

export const fetchProjectsSuccess = projects => ({
  type: AdminActionTypes.FETCH_PROJECTS_SUCCESS,
  payload: projects,
});
export const deleteProjectStart = () => ({
  type: AdminActionTypes.DELETE_PROJECT,
});
export const createProjectStart = () => ({
  type: AdminActionTypes.CREATE_PROJECT,
});

// -------------- ASYNC --------------- //
export const createProjectStartAsync = donationType => {
  return async dispatch => {
    dispatch(createProjectStart());
    try {
      await firestore
        .collection("projects")
        .doc(donationType)
        .set({ date: new Date() });
    } catch (err) {
      throw new Error(err);
    }
  };
};
export const deleteProjectStartAsync = project => {
  return async dispatch => {
    dispatch(deleteProjectStart());
    try {
      await firestore.collection("projects").doc(project).delete();
    } catch (err) {
      throw new Error(err);
    }
  };
};
export const adminLoginStartAsync = ({ email, password }) => {
  // Get the user and set it as a current user if exists
  return async dispatch => {
    dispatch(setIsLoaderVisible(true));
    dispatch(adminLoginStart());

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(({ user: { email } }) => {
        dispatch(adminLoginSuccess(email));
        dispatch(setIsLoaderVisible(false));
        dispatch(setAdminTypeFromEmail(email));
      })
      .catch(err => {
        dispatch(setIsLoaderLoading(false));
        dispatch(setTextInsideLoader(err.message));
      });
  };
};

export const fetchProjectsStartAsync = () => {
  return async dispatch => {
    dispatch(fetchProjectsStart());

    const projects = [];
    const projectsRef = await firestore.collection("projects").get();
    const projectsDocs = projectsRef.docs;
    projectsDocs.map(doc => projects.push(doc.id));

    dispatch(fetchProjectsSuccess(projects));
  };
};
