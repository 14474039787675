import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Feedback from 'react-bootstrap/Feedback';
import { countryList } from '../../js/utils';

import { createNewDonationStartAsync } from '../../redux/admin-data/admin-data.actions';

import { fetchProjectsStartAsync } from '../../redux/admin/admin.actions';
import { selectProjects } from '../../redux/admin/admin.selectors';

import './add-donation-form.component.styles.scss';
import { createStructuredSelector } from 'reselect';

const AddDonationForm = ({
  createNewDonation,
  projects,
  fetchTypeOfDonations,
}) => {
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    fetchTypeOfDonations();
  }, []);
  const [form, setForm] = useState({
    date: '',
    lastname: '',
    email: '',
    amount: '',
    donationType: 'don ordinaire',
    country: 'afghanistan',
    address: '',
    civility: '',
    paymentMethod: 'card',
    phone: '',
  });
  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    const formJsx = e.currentTarget;
    if (formJsx.checkValidity() === false) return e.stopPropagation();
    createNewDonation(form);
    // Create new donation in firestore
  };

  return (
    <div className='add-donation-form'>
      <div className='admin-users-add-user-container'>
        <Form
          method='POST'
          noValidate
          validated={validated}
          className='bootstrap-form'
          onSubmit={handleSubmit}
        >
          <Form.Group>
            <Form.Label>Date</Form.Label>
            <Form.Control
              type='date'
              name='date'
              onChange={handleChange}
              value={form.date}
              required
            />
            <Feedback type='invalid'>Veuillez entrer la date du don</Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Group>
              <Form.Label>Civilité</Form.Label>
              <Form.Control
                as='select'
                name='civility'
                defaultValue={form.civility}
                onChange={handleChange}
              >
                <option>Monsieur</option>
                <option>Madame</option>
                <option>Autre</option>
              </Form.Control>
            </Form.Group>
            <Form.Label>Noms</Form.Label>
            <Form.Control
              type='text'
              name='lastname'
              onChange={handleChange}
              value={form.lastname}
              required
            />
            <Feedback type='invalid'>Veuillez entrer les noms</Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Pays</Form.Label>
            <Form.Control
              as='select'
              name='country'
              onChange={handleChange}
              value={form.country}
              required
            >
              {countryList.map((country, i) => (
                <option key={i} value={country}>
                  {country}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Adresse</Form.Label>
            <Form.Control
              type='text'
              name='address'
              onChange={handleChange}
              value={form.address}
              required
            />
            <Feedback type='invalid'>Veuillez entrer l'adresse</Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Activité</Form.Label>
            <Form.Control
              type='text'
              name='activity'
              onChange={handleChange}
              value={form.activity}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type='text'
              name='email'
              onChange={handleChange}
              value={form.email}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Téléphone</Form.Label>
            <Form.Control
              type='text'
              name='phone'
              onChange={handleChange}
              value={form.phone}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Montant</Form.Label>
            <Form.Control
              type='number'
              name='amount'
              onChange={handleChange}
              value={form.amount}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Type de don</Form.Label>
            <Form.Control
              as='select'
              name='donationType'
              onChange={handleChange}
              value={form.donationType}
            >
              {projects.map((project) => (
                <option key={project}>{project}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Source</Form.Label>
            <Form.Control
              as='select'
              name='paymentMethod'
              onChange={handleChange}
              value={form.paymentMethod}
            >
              <option value='card'>Carte Bancaire</option>
              <option value='paypal'>Mobile Money</option>
              <option value='wu'>Western Union</option>
              <option value='ria'>Ria</option>
              <option value='wu'>Money Gram</option>
              <option value='wire-transfer'>Virement bancaire</option>
            </Form.Control>
          </Form.Group>

          <button className='green-button-1 marginTop10'>Enregistrer</button>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  projects: selectProjects,
});
const mapDispatchToProps = (dispatch) => ({
  createNewDonation: (form) => dispatch(createNewDonationStartAsync(form)),
  fetchTypeOfDonations: () => dispatch(fetchProjectsStartAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDonationForm);
