import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';

import './admin-members.page.styles.scss';

import TextField from '@material-ui/core/TextField';

// redux
import { selectIsLoaderVisible } from '../../redux/loader/loader.selectors';
import { selectCardIsVisible } from '../../redux/card-text/card-text.selectors';
import { fetchMembersStartAsync } from '../../redux/admin-data/admin-data.actions';
import {
  selectSimpleMembers,
  selectBenefactorMembers,
  selectHonoraryMembers,
  selectVolunteerMembers,
} from '../../redux/admin-data/admin-data.selectors';

import Table5 from '../../components/table-5/table-5.component';
import MaterialTable1 from '../../components/material-table-1/material-table-1.component';
import StateCard from '../../components/state-card/state-card.component';
import CardWithText from '../../components/card-text/card-text.component';

const AdminMembersPage = ({
  isLoaderVisible,
  isCardTextVisible,
  fetchMembersStart,
  members,
  benefactorMembers,
  volunteerMembers,
  honoraryMembers,
}) => {
  useEffect(() => {
    fetchMembersStart();
  }, []);
  let { id } = useParams();
  let title;
  let data;

  const [searchField, setSearchField] = useState('');
  switch (id) {
    case 'normal':
      title = '';
      data = members;
      break;
    case 'benefactor':
      title = 'bienfaiteurs';
      data = benefactorMembers;
      break;
    case 'honorary':
      title = "d'honneur";
      data = honoraryMembers;
      break;
    case 'volunteer':
      title = 'bénévoles';
      data = volunteerMembers;
      break;
    default:
      return (title = '');
  }

  return (
    <div className='admin-members-table-container'>
      <h3>Les Membres {title}</h3>
      {isLoaderVisible && <StateCard />}
      {isCardTextVisible && <CardWithText />}
      <div className='admin-members-table-container'>
        <MaterialTable1 data={data} title='Membres' />
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  isLoaderVisible: selectIsLoaderVisible,
  isCardTextVisible: selectCardIsVisible,
  members: selectSimpleMembers,
  benefactorMembers: selectBenefactorMembers,
  honoraryMembers: selectHonoraryMembers,
  volunteerMembers: selectVolunteerMembers,
});
const mapDispatchToProps = (dispatch) => ({
  fetchMembersStart: () => dispatch(fetchMembersStartAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminMembersPage);
