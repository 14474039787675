import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import './admin-users.page.styles.scss';

import Table2 from '../../components/table-2/table-2.component';

import TextField from '@material-ui/core/TextField';
import Feedback from 'react-bootstrap/esm/Feedback';
import StateCard from '../../components/state-card/state-card.component';
import { selectIsLoaderVisible } from '../../redux/loader/loader.selectors';
import { setIsLoaderVisible } from '../../redux/loader/loader.actions';
import AdminUpdateUser from '../../components/admin-update-user/admin-update-user.component';

import { selectIsUserUpdateVisible } from '../../redux/admin-data/admin-data.selectors';

// Form
import Form from 'react-bootstrap/Form';

// Redux
import {
  fetchAdminsStartAsync,
  createNewUserStartAsync,
} from '../../redux/admin-data/admin-data.actions';

const AdminUsersPage = ({
  fetchAdminsStart,
  selectAdmins,
  createNewUser,
  isLoaderVisible,
  userUpdateIsVisible,
  setIsLoaderVisible,
}) => {
  const [searchField, setSearchField] = useState('');
  const [validated, setValidated] = useState(false);
  const [form, setForm] = useState({
    lastname: '',
    firstname: '',
    typeOfUser: 'admin',
    email: '',
    password: '',
  });
  const [addUserIsHidden, setAddUserIsHidden] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    setValidated(true);

    const formJsx = e.currentTarget;
    if (formJsx.checkValidity() === false) return e.stopPropagation();
    setAddUserIsHidden(true);
    createNewUser(form);

    setForm({
      lastname: '',
      firstname: '',
      typeOfUser: 'admin',
      email: '',
      password: '',
    });
  };
  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    const getData = async () => {
      await fetchAdminsStart();
      setIsLoaderVisible(false);
    };
    getData();
  }, []);
  return (
    <div className='admin-users-page-container'>
      <h3>Utilisateurs</h3>
      {isLoaderVisible && <StateCard />}
      <div className='admin-users-table-container'>
        <div className='admin-users-buttons-container'>
          <button
            className='green-button-1 marginTop30 marginBottom30'
            onClick={() => setAddUserIsHidden(!addUserIsHidden)}
          >
            {addUserIsHidden ? 'Ajouter un utilisateur' : 'Fermer'}
          </button>

          <TextField
            label='Recherche'
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
          />
        </div>
        {!addUserIsHidden ? (
          <div className='admin-users-add-user-container'>
            <Form
              method='POST'
              noValidate
              validated={validated}
              className='bootstrap-form'
              onSubmit={handleSubmit}
            >
              <Form.Group>
                <Form.Label>Nom</Form.Label>
                <Form.Control
                  type='text'
                  name='lastname'
                  onChange={handleChange}
                  value={form.lastname}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Prénoms</Form.Label>
                <Form.Control
                  type='text'
                  name='firstname'
                  onChange={handleChange}
                  value={form.firstname}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Type d'utilisateur</Form.Label>
                <Form.Control
                  as='select'
                  name='typeOfUser'
                  onChange={handleChange}
                  value={form.typeOfUser}
                >
                  <option value='admin'>Admin</option>
                  <option value='super_admin'>Super Admin</option>
                  <option value='accounting'>Comptabilité</option>
                  <option value='media'>Media</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type='email'
                  name='email'
                  onChange={handleChange}
                  value={form.email}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Mot de passe</Form.Label>
                <Form.Control
                  type='password'
                  name='password'
                  onChange={handleChange}
                  value={form.password}
                />
              </Form.Group>
              <button className='green-button-1 marginTop10'>
                Enregistrer
              </button>
            </Form>
          </div>
        ) : (
          ''
        )}
        {userUpdateIsVisible && <AdminUpdateUser />}
        <Table2 searchFieldVal={searchField} />
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  isLoaderVisible: selectIsLoaderVisible,
  userUpdateIsVisible: selectIsUserUpdateVisible,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAdminsStart: () => dispatch(fetchAdminsStartAsync()),
  createNewUser: (form) => dispatch(createNewUserStartAsync(form)),
  setIsLoaderVisible: (isVisible) => dispatch(setIsLoaderVisible(isVisible)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersPage);
