import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectFilteredDonations,
  selectFilteredExpenses,
} from '../../redux/admin-data/admin-data.selectors';

import {
  formatDateFromSlashesToInt,
  arrayOfDaysWithFilter,
} from '../../js/utils';

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const AreaChartDonations = ({
  donations,
  expenses,
  startDate,
  endDate,
  country,
  email,
  donationType,
  activity,
  name,
  phone,
}) => {
  let donationTotal = 0;
  let expenseTotal = 0;
  const donationsFormatedData = [];
  const expensesFormatedData = [];
  let formatedData = arrayOfDaysWithFilter(
    donations,
    expenses,
    startDate,
    endDate,
    country,
    email,
    donationType,
    activity,
    name,
    phone
  );

  const dataForGraph = [];

  formatedData.map(day => {
    if (day.donationAmount) {
      donationTotal = parseInt(donationTotal) + parseInt(day.donationAmount);
    }
    dataForGraph.push({
      name: day.date,
      donations: donationTotal,
    });
  });

  return (
    <ResponsiveContainer>
      <AreaChart
        data={dataForGraph}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        <Area
          type='monotone'
          dataKey='donations'
          stroke='#0db539'
          fill='#0db539'
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
const mapStateToProps = createStructuredSelector({
  donations: selectFilteredDonations,
  expenses: selectFilteredExpenses,
});
export default connect(mapStateToProps)(AreaChartDonations);
