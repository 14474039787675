import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {FR, EN} from '../../js/languagesData';


import './hero-top.component.styles.scss';
import heroAbout from '../../assets/images/hero-about.png';
import { createStructuredSelector } from 'reselect';
import { selectCurrentLanguage } from '../../redux/language/language.selectors';

const HeroTop = ({
  title,
  img,
  isPartnerOrJoin,
  isDonationPage,
  isDocumentsPage,
  currentLanguage
}) => {
  const languageData = currentLanguage === 'english' ? EN : FR;
  const [isTabletOrSmaller, setIsTabletOrSmaller] = useState(false);
  const setScreen = () => {
    if (window.screen.width < 990) {
      setIsTabletOrSmaller(true);
    } else {
      setIsTabletOrSmaller(false);
    }
  };
  useEffect(() => {
    setScreen();
  }, []);
  window.onresize = () => {
    setScreen();
  };
  return (
    <div
      className='hero-top-image-container'
      style={{
        backgroundImage: `url(${
          isTabletOrSmaller && !isDonationPage && !isDocumentsPage
            ? heroAbout
            : img
        })`,
      }}
    >
      <div className='hero-top-image-filter' />

      <div className='hero-top-text-container'>
        {isPartnerOrJoin && !isTabletOrSmaller ? (
          <marquee className='marquee-hero'>
          {languageData.general.industrializeAfrica}
          </marquee>
        ) : (
          ''
        )}
        <div className='hero-top-divider'></div>
        <h1> {title} </h1>
        <div className='hero-top-divider'></div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  currentLanguage: selectCurrentLanguage
})

export default connect(mapStateToProps)(HeroTop);
