import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { searchAmountOfDonationByActivity } from '../../js/utils';

import Form from 'react-bootstrap/Form';
import './donations.page.styles.scss';

// Data
import { selectDonations } from '../../redux/admin-data/admin-data.selectors';
import { selectIsLoaderVisible } from '../../redux/loader/loader.selectors';
import { fetchCategoryStartAsync } from '../../redux/admin-data/admin-data.actions';
import { setIsLoaderVisible } from '../../redux/loader/loader.actions';

// Components
import StateCard from '../../components/state-card/state-card.component';
import MaterialTable1 from '../../components/material-table-1/material-table-1.component';
import AddDonationForm from '../../components/add-donation-form/add-donation-form.component';
import AreaChartDonations from '../../components/area-chart-2/area-chart-2.component';
import TableDonationTypes from '../../components/table-donation-types/table-donation-types.component';

import { fetchProjectsStartAsync } from '../../redux/admin/admin.actions';
import { selectProjects } from '../../redux/admin/admin.selectors';
import { generateAllDonationsReceipts } from '../../js/utils';

const DonationsPage = ({
  isLoaderVisible,
  donations,
  fetchDonations,
  setIsLoaderVisible,
  fetchDonationsType,
  projects,
}) => {
  const [form, setForm] = useState({
    country: '',
    email: '',
    name: '',
    startDate: '',
    endDate: '',
    donationType: '',
    activity: '',
    phone: '',
  });

  const [isGraphVisible, setIsGraphVisible] = useState(false);
  const [isAddDonationVisible, setIsAddDonationVisible] = useState(false);
  const [isDonationTypesVisible, setIsDonationTypesVisible] = useState(false);
  const [amountOfSearchByActivity, setAmountOfSearchByActivity] =
    useState(null);

  const handleChange = e => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setForm({ ...form, [name]: value });
  };
  useEffect(() => {
    async function getDonations() {
      await fetchDonations();
      setIsLoaderVisible(false);
    }
    getDonations();
    fetchDonationsType();

    // ! Download all receipts ! DEV FUNC
    // generateAllDonationsReceipts();
  }, []);
  const {
    startDate,
    endDate,
    country,
    email,
    donationType,
    activity,
    name,
    phone,
  } = form;

  return (
    <div className='admin-users-page-container'>
      <h3>Les Dons</h3>
      {isLoaderVisible && <StateCard />}
      <div className='admin-users-table-container'>
        <div className='admin-users-buttons-container'>
          <button
            onClick={() => setIsAddDonationVisible(!isAddDonationVisible)}
            className='green-button-1 marginTop30 marginBottom30'
          >
            Ajouter un don
          </button>
          <button
            onClick={() => setIsDonationTypesVisible(!isDonationTypesVisible)}
            className='green-button-1 marginTop30 marginBottom30'
          >
            Voir les projets
          </button>
          <button
            onClick={() => setIsGraphVisible(!isGraphVisible)}
            className='green-button-1 marginTop30 marginBottom30'
          >
            {`${isGraphVisible ? 'Fermer' : 'Voir'} le graphique`}
          </button>

          {isAddDonationVisible && <AddDonationForm />}
        </div>
        {isGraphVisible && (
          <div className='admin-dashboard-chart'>
            <div className='admin-donations-graph'>
              <div className='admin-donations-inputs'>
                <div className='admin-donations-input-container'>
                  <label>Début</label>
                  <input type='date' name='startDate' onChange={handleChange} />
                </div>
                <div className='admin-donations-input-container'>
                  <label>Fin</label>
                  <input type='date' name='endDate' onChange={handleChange} />
                </div>
              </div>
              <div className='admin-donations-inputs'>
                <div className='admin-donations-input-container'>
                  <label>Pays</label>
                  <input name='country' onChange={handleChange} />
                </div>
                <div className='admin-donations-input-container'>
                  <label>Email</label>
                  <input name='email' onChange={handleChange} />
                </div>
                <div className='admin-donations-input-container'>
                  <label>Noms</label>
                  <input name='name' onChange={handleChange} />
                </div>

                <div className='admin-donations-input-container'>
                  <label>Téléphone</label>
                  <input name='phone' onChange={handleChange} />
                </div>
                <div className='admin-donations-input-container'>
                  <label>Type de don</label>
                  <Form.Control
                    className='bootstrap-form-select-currency-amount'
                    as='select'
                    name='donationType'
                    onChange={handleChange}
                    defaultValue={form.donationAmount}
                  >
                    {projects.map(project => (
                      <option key={project}>{project}</option>
                    ))}
                  </Form.Control>
                </div>
                <div className='admin-donations-input-container'>
                  <label>Activité</label>
                  <input
                    name='activity'
                    onChange={async e => {
                      handleChange(e);
                      const res = await searchAmountOfDonationByActivity(
                        e.target.value
                      );
                      setAmountOfSearchByActivity(res);
                    }}
                  />
                </div>
                {amountOfSearchByActivity !== null &&
                  amountOfSearchByActivity !== '' && (
                    <h4>{amountOfSearchByActivity}</h4>
                  )}
              </div>
            </div>
            <AreaChartDonations
              startDate={startDate}
              endDate={endDate}
              country={country}
              email={email}
              donationType={donationType}
              activity={activity}
              name={name}
              phone={phone}
            />
          </div>
        )}
        {isDonationTypesVisible && <TableDonationTypes />}

        <MaterialTable1 data={donations} title='Dons' />
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  isLoaderVisible: selectIsLoaderVisible,
  donations: selectDonations,
  projects: selectProjects,
});
const mapDispatchToProps = dispatch => ({
  fetchDonations: () => dispatch(fetchCategoryStartAsync('donations')),
  setIsLoaderVisible: isVisible => dispatch(setIsLoaderVisible(isVisible)),
  fetchDonationsType: () => dispatch(fetchProjectsStartAsync()),
});
export default connect(mapStateToProps, mapDispatchToProps)(DonationsPage);
